import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateAccountComponent } from './layouts/enterprice/activate-account/activate-account.component';
import { EnterpriceComponent } from './layouts/enterprice/enterprice.component';
import { FailedPaimentComponent } from './layouts/enterprice/failed-paiment/failed-paiment.component';
import { ResetPasswordComponent } from './layouts/enterprice/reset_password/reset_password.component';
import { SubscriptionComponent } from './layouts/enterprice/subscription/subscription.component';
import { SuccessPaimentComponent } from './layouts/enterprice/success-paiment/success-paiment.component';
import {GenererPDFComponent} from "./generer-pdf/generer-pdf.component";
import { ApgSuccessComponent } from './layouts/enterprice/apg-success/apg-success.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'enterprice',
    pathMatch: 'full',
  },
  {
    path: 'pages',
    loadChildren: () => import('./layouts/layouts.module').then( m => m.LayoutsModule)
  },
  {
    path: 'enterprice',
    component: EnterpriceComponent
  },
  {
    path: 'devis/:type',
    component: SubscriptionComponent
  },
  {
    path: 'success-payment',
    component: SuccessPaimentComponent
  },
  {
    path: 'activate-account',
    component: ActivateAccountComponent
  },
  {
    path: 'apg-paiement',
    component: ApgSuccessComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'failed-payment',
    component: FailedPaimentComponent
  },{
    path: 'generer-pdf/:id',
    component: GenererPDFComponent
  },{
    path: 'generer-pdfSante/:idSante',
    component: GenererPDFComponent
  },{
    path: 'generer-pdfAuto/:idAuto',
    component: GenererPDFComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled',

})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
