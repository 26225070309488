import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import {Location} from "@angular/common";

@Component({
  selector: 'app-follow-disclaimer',
  templateUrl: './follow-disclaimer.component.html',
  styleUrls: ['./follow-disclaimer.component.scss']
})
export class FollowDisclaimerComponent implements OnInit {
  contract: any;
  sinistreForm = new FormGroup({
        numeroPolice: new FormControl(null, [Validators.required]),
  });

  constructor(private subscriptionService: SubscriptionService, private location: Location) { }

  ngOnInit(): void {
  }

  getData(){
   this.subscriptionService.checkSinistre(this.sinistreForm.value).subscribe((resp) => {
      this.contract = resp['data'];
   });
  }


}
