import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-success-paiment',
  templateUrl: './success-paiment.component.html',
  styleUrls: ['./success-paiment.component.scss']
})
export class SuccessPaimentComponent implements OnInit {

  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
   
  }


}
