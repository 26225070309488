import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { AngularTiltModule } from 'angular-tilt';
import 'hammerjs';
import 'mousetrap';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxMasonryModule } from 'ngx-masonry';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPayPalModule } from 'ngx-paypal';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SharedModule } from '../shared/shared.module';
import { enterpriceAboutComponent } from './enterprice/enterprice-about/enterprice-about.component';
import { enterpriceDownloadComponent } from './enterprice/enterprice-download/enterprice-download.component';
import { enterpriceFooterComponent } from './enterprice/enterprice-footer/enterprice-footer.component';
import { enterpriceHeadertopComponent } from './enterprice/enterprice-header-top/enterprice-header-top.component';
import { enterpriceHeaderComponent } from './enterprice/enterprice-header/enterprice-header.component';
import { enterpriceNavComponent } from './enterprice/enterprice-nav/enterprice-nav.component';
// import { enterpriceNav2Component } from './enterprice/enterprice-nav-2/enterprice-nav-2.component';
import { enterpricePricingComponent } from './enterprice/enterprice-pricing/enterprice-pricing.component';
import { enterpriceScreenshotsComponent } from './enterprice/enterprice-screenshots/enterprice-screenshots.component';
import { enterpriceServicesComponent } from './enterprice/enterprice-services/enterprice-services.component';
import { enterpriceSubscribeComponent } from './enterprice/enterprice-subscribe/enterprice-subscribe.component';
import { enterpriceVideoComponent } from './enterprice/enterprice-video/enterprice-video.component';
//Enterprice Layouts
import { EnterpriceComponent } from './enterprice/enterprice.component';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { SubscriptionComponent } from './enterprice/subscription/subscription.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SuccessPaimentComponent } from './enterprice/success-paiment/success-paiment.component';
import{ActivateAccountComponent} from './enterprice/activate-account/activate-account.component'
import { FailedPaimentComponent } from './enterprice/failed-paiment/failed-paiment.component';
import { DisclaimerComponent } from './enterprice/disclaimer/disclaimer.component';
import { FollowDisclaimerComponent } from './enterprice/follow-disclaimer/follow-disclaimer.component';
import { EnterpriceFooter2Component } from './enterprice/enterprice-footer2/enterprice-footer2.component';
import { AutomobileComponent } from './enterprice/subscription/automobile/automobile.component';
import { MRHComponent } from './enterprice/subscription/mrh/mrh.component';
import { SanteComponent } from './enterprice/subscription/sante/sante.component';
import { VieComponent } from './enterprice/subscription/vie/vie.component';
// import {enterpriceNav2Component} from "./enterprice/enterprice-nav-2/enterprice-nav-2.component";
import { from } from 'rxjs';
import { ResetPasswordComponent } from './enterprice/reset_password/reset_password.component';
import { VoyageComponent } from './enterprice/subscription/voyage/voyage.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ApgSuccessComponent } from './enterprice/apg-success/apg-success.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
};

// @ts-ignore
@NgModule({
  declarations: [
    EnterpriceComponent,
    enterpriceHeaderComponent,
    enterpriceNavComponent,
    // enterpriceNav2Component,
    enterpriceServicesComponent,
    enterpriceAboutComponent,
    enterpriceDownloadComponent,
    enterpriceScreenshotsComponent,
    enterpricePricingComponent,
    enterpriceVideoComponent,
    enterpriceSubscribeComponent,
    enterpriceFooterComponent,
    EnterpriceFooter2Component,
    SubscriptionComponent,
    FailedPaimentComponent,
    SuccessPaimentComponent,
    ResetPasswordComponent,
    ActivateAccountComponent,
    ApgSuccessComponent,
    DisclaimerComponent,
    FollowDisclaimerComponent,
    AutomobileComponent,
    VoyageComponent,
    MRHComponent,
    SanteComponent,
    enterpriceHeadertopComponent,
    SanteComponent,
    VieComponent,
  ],

  imports: [
    CommonModule,
    NgxFilesizeModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule.forRoot(),
    SharedModule,
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    MasonryGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxMasonryModule,
    NgbPopoverModule,
    ArchwizardModule,
    NgSelectModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(options)
  ],

  exports: [],

  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})

export class LayoutsModule { }
