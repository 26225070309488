import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {ActivatedRoute,Router} from "@angular/router";
import { AccountService } from 'src/app/shared/service/account.service';
import { from } from 'rxjs';
@Component({
  selector: 'activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
  // styleUrls: ['./demo.css','./style.css']
})
export class ActivateAccountComponent implements OnInit {
  parameterValue: any;
 
  uid: any;
  route: any;

  constructor(private subscriptionService: SubscriptionService,private accountService:AccountService,private _activatedRoute: ActivatedRoute,private _router:Router) { }

  ngOnInit(): void {
 
     //@ts-ignore
     this.route=''+this._activatedRoute.queryParams.value.type;
     //@ts-ignore
     this.uid=this._activatedRoute.queryParams.value.id;
     
  }
  aller()
  { 
    // console.log(this.route)
    this.accountService.activate(this.uid).subscribe((response)=>{
         
    },(error)=>{

    })
    // console.log(this.route)
    this._router.navigate(['devis/'+this.route]);
  }

}
