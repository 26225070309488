import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
//import { Storage } from '@ionic/storage';
import { catchError, retry, mergeMap, finalize } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { SubscriptionService } from './subscription.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  passpeello: any;

  constructor(private utilsService: UtilsService,private router: Router,  private subscriptionService: SubscriptionService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.utilsService.show();
    console.log('request.url :',request.url);
    if (request.url.endsWith("/login") ||  request.url.endsWith("/get-devis-by-type-produit") || request.url.includes(environment.apg.initPayment) || request.url.includes(environment.apgUrl) || request.url.endsWith("/type-produit") || request.url.endsWith("/souscripteurs") || request.url.endsWith("/garantie") || request.url.includes("/souscripteurs/activate-account") || request.url.includes("/users/request-reset-password") || request.url.includes("/users/reset-password") || request.url.includes("/categorie-vehicule") || request.url.includes("/moyens-de-paiement") || request.url.includes("/vehicle-model/get-make") || request.url.includes("/vehicle-model/get-model-by-make")  || request.url.includes("/produits") || request.url.includes("/durees") || request.url.includes('type-garantie/list-garanties') || request.url.includes("/garantie") || request.url.includes("/assureurs") || (request.url.includes("/souscriptions") && request.method =='GET')|| request.url.includes("/parametrage-mrh/get-price") ) {
      if (request.url.includes(environment.apg.initPayment)) {
      } else {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
        }
      });
    }
    } else if (request.url.includes(environment.assuranceApiUrl)) {
      
    }
    else {
      console.log('waly :',request.url);
      
      let val = JSON.parse(localStorage.getItem('microFinance'));
      let token = ""
      if (val)
        token = val.id_token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        console.log('response.status :',response.status);
        
        if (response.status === 401) {
          // this.setRefreshToken();
        }
        return throwError(response);
      }
      ));
  }

  setRefreshToken() {
    // let val = JSON.parse(localStorage.getItem('microFinance'));
    // let tokenRefresh = val.refresh_token;
    // let token = val.id_token;
    // console.log('without token');
    // if (token) {
    //   console.log('token');
    //   this.subscriptionService.refreshToken(tokenRefresh).subscribe(data => {
    //     console.log('resp w:', data);
    //   },err=>{
    //     console.log('err w:', err);
    //   });
    // }
    this.utilsService.showToastSuccess("Votre session a expiré", "Veuillez vous reconnecter");
    localStorage.removeItem('microFinance');
    this.router.navigate(['enterprice']);
  }
}
