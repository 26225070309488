<!-- download section Start-->

<!-- Start Projects Area -->
<section class="projects-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>OFFRES</h2>
            <h2>NOS SERVICES INNOVANTS</h2>
            <p>à découvrir</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a [routerLink]="['/devis/5']" routerLinkActive="router-link-active" >
                            <img class="imgService" src="assets/img/projects/image1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a [routerLink]="['/devis/5']">
                                ASSURANCE AUTOMOBILE
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a [routerLink]="['/devis/8']">
                            <img class="imgService" src="assets/img/projects/image4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a [routerLink]="['/devis/8']">
                                ASSURANCE VOYAGE
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a [routerLink]="['/devis/6']">
                            <img class="imgService" src="assets/img/projects/image2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a [routerLink]="['/devis/6']">
                                ASSURANCE HABITATION
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a [routerLink]="['/devis/7']">
                            <img class="imgService" src="assets/img/projects/image3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a [routerLink]="['/devis/7']">
                                ASSURANCE SANTE
                            </a>
                        </p>
                    </div>
                </div>
            </div>

          <!--  <div class="col-lg-4 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a href="/devis/8">
                            <img src="assets/img/projects/image4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a href="/devis/8">
                                ASSURANCE VOYAGE
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a href="/devis/10">
                            <img src="assets/img/projects/image5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a href="/devis/10">
                                ASSURANCE VIE
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="projects-item">
                    <div class="image">
                        <a href="#">
                            <img src="assets/img/projects/image6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <p>
                            <a href="#">
                                ASSURANCE RETRAITE
                            </a>
                        </p>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>
<!-- End Projects Area -->

<!-- download section end-->
