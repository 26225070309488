<app-enterprice-nav></app-enterprice-nav>
<!-- <app-enterprice-header></app-enterprice-header> -->
<!-- <div class="loader-content">
  <i class="fa fa-spinner fa-pulse"></i>
</div> -->
<div class="amsa-form-page">
  <form [formGroup]="sinistreForm">
      <div class="form-container">
          <div class="row no-margin second-title mt-4">
              <h2 class="mb-4 mx-auto">Déclaration de sinistre</h2>
          </div>
          <div class="row mt-3 mb-2">
            <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                  <div class="form-group">
                      <label for="email">Numéro de la police</label>
                      <input type="text" class="form-control" formControlName="numeroPolice">
                  </div>
            </div>
              <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                  <div class="form-group">
                      <label for="nom">Lieu</label>
                      <input type="text" class="form-control" formControlName="lieu">
                  </div>
              </div>
               <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                  <div class="form-group">
                      <label for="numero">Date</label>
                      <input type="text" class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                           (dateSelect)="onDateSelect($event)" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
                  </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div class="form-group">
                    <label>Description</label>
                    <textarea rows="2" class="form-control-textarea" formControlName="description"></textarea>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="alert.type != ''">
              <div class="mx-auto alert alert-{{alert?.type}}">
                  <p>{{alert?.message}}</p>
              </div>
            </div>

            <div class="row">
              <button type="button" class="btn btn-success mx-auto" [disabled]="sinistreForm.invalid" [swal]="confirmSinistre">Enregistrer</button>
            </div>
      </div>
</form>
</div>
<swal
  #confirmSinistre
  title="Confirmation"
  text="Voulez-vous vraiment soumettre votre déclaration ?"
  icon="question"
  [showCancelButton]="true"
  [focusCancel]="true"
  [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'"
  (confirm)="saveData()">
</swal>
<app-enterprice-footer></app-enterprice-footer>
