import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { Simulation, SubscriptionService } from '../../../../shared/service/subscription.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../shared/service/utils.service';
import { apiUrls } from '../../../../shared/api-urls';
import * as CryptoJS from 'crypto-js';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval } from "rxjs";
import * as jspdf from 'jspdf';
@Component({
    selector: 'assurance-vie',
    templateUrl: './vie.component.html',
    styleUrls: ['./vie.component.scss']
})
export class VieComponent implements OnInit {
    @ViewChild('htmlData') mondevis: ElementRef;
    beneficiaryChoosen: boolean = false;
    paymentMethod: string;
    beneficiaries = [];
    formules = [];
    durees = [];
    avalaibleDuration = [];
    paymentMethods = [];
    sendingRequest: boolean = false;
    assure: number = 1;
    type: string = 'capital';
    resultType: String;
    phoneMask: string = '00 000 00 00';
    cinMask: string = '0 000 0000 00000';
    dateTime = Math.floor(Date.now() / 1000);
    key = '676AC4CC226201685E89ADB0C9B4510D';
    paymentForm = {
        timestamp: this.dateTime,
        returnUrl: environment.baseApiUrl + 'contrats/save-contrat',
        auth: '',
        amount: '',
        requestId: this.dateTime,
        // terminalNumber: 160820000101,
        terminalNumber: 423820000101,
        transactionCurrencyCode: 'XOF',
        customerLastName: '',
        customerFirstName: '',
        customerAddress: '',
        customerCountry: '',
        customerEmail: '',
        customerPhone: '',
        customerState: '',
        customerCity: '',
        customerZipCode: '',
        ville: '',
        montantSaisie: '',
    }
    today: any = {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        day: new Date().getDate()
      };
    emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    //capitalGaranti: number
    //     dureeContrat: number
    //     age: number
    //     primeAnnuelle: number
    //     produit: string

    souscriptionForm = new FormGroup({
        duree: new FormControl(null, [Validators.required]),
        formule: new FormControl(null, [Validators.required]),
        canal: new FormControl(1, [Validators.required]),
        moyenDePaiement: new FormControl(1, [Validators.required]),
        assureur: new FormControl(null, [Validators.required]),
        produit: new FormControl(null, [Validators.required]),
        montant: new FormControl(null),
        montantResult: new FormControl(),
        simulation: new FormGroup({
            duree: new FormControl(1, [Validators.required]),
            age: new FormControl(null, [Validators.required]),
            produit: new FormControl(),
            montant: new FormControl(null, [Validators.required]),
            montantResultprime: new FormControl(null),
            montantResultcapital: new FormControl(null)
        }),
        souscripteur: new FormGroup({
            prenom: new FormControl(null, [Validators.required]),
            nom: new FormControl(null, [Validators.required]),
            genre: new FormControl(null),
            email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)]),
            dateNaissance: new FormControl(null),
            telephone: new FormControl(null, [Validators.required]),
            numeroCni: new FormControl(null)
        }),
        assure: new FormGroup({
            prenom: new FormControl(null, [Validators.required]),
            nom: new FormControl(null, [Validators.required]),
            dateNaissance: new FormControl(null, [Validators.required]),
            numeroTelephone: new FormControl(null, [Validators.required]),
            numeroCni: new FormControl(null, [Validators.required]),
            adresse: new FormControl(null, [Validators.required]),
            lateralite: new FormControl(null, [Validators.required]),
            genre: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)])
        }),
        beneficiaire: new FormGroup({
            prenom: new FormControl(null, [Validators.required]),
            nom: new FormControl(null, [Validators.required]),
            dateNaissance: new FormControl(null, [Validators.required]),
            numeroTelephone: new FormControl(null, [Validators.required]),
            numeroCni: new FormControl(null, [Validators.required]),
            genre: new FormControl(null, [Validators.required]),
            adresse: new FormControl(null, [Validators.required])
        }),
    });
    products: any;
    closeResult = '';
    devisType: string;
    assureurs: any = [];
    _idAssurance: string;
    _typeAssurance: string;
    @ViewChild('wizard', { static: false }) wizard: WizardComponent;
    vieProduits: any[] = [];
    simulationVie: any;
    initPaymentForm = {
        requestId: 0,
        numeroSouscription: '',
        status: '',
        montant: '',
    }
    date: string;
    constructor(private subscriptionService: SubscriptionService,
        private location: Location,
        private route: ActivatedRoute,
        public router: Router,
        private utilsService: UtilsService, private modalService: NgbModal) {
            this.newIndentifiant(this.route.snapshot.params.type);

    }

    get idAssurance() {
        return this._idAssurance;
    }

    @Input()
    set idAssurance(id) {
        this._idAssurance = id;
        this.fireFunctions();
    }

    get typeAssurance() {
        return this._typeAssurance;
    }

    @Input()
    set typeAssurance(type: string) {
        this._typeAssurance = type;
    }

    get souscripteur() {
        return this.souscriptionForm.get('souscripteur')
    }

    fireFunctions() {
        this.getPaymentMethods();
        this.getProducts();
        this.getVieProducts();
        this.getAssureur();
        //this.paymentForm.returnUrl = this.location['_platformLocation'].location.host + '/success-payment';
        this.paymentForm.returnUrl = environment.baseApiUrl + 'contrats/save-contrat';
    }

    ngOnInit(): void {
        this.fireFunctions();
    }

    newIndentifiant(id) {
        this.subscriptionService.changeIndentifiant(id)
      }
    getAssureur() {
        this.subscriptionService.reqPost(apiUrls.assureurByProduit, { 'typeProduit': this.idAssurance }).subscribe((resp) => {
            this.assureurs = resp['data'];
        });
    }

    getVieProducts() {
        this.subscriptionService.vieProducts().subscribe((response) => {
            this.vieProduits = response['data'];
            console.log('vieproduits',this.vieProduits);
        }, (error: HttpErrorResponse) => {

        });
    }

    setRequiredField() {
        this.souscripteur.get('numeroCni').setValidators(Validators.required);
        this.souscripteur.get('dateNaissance').setValidators(Validators.required);
    }

    saveData() {
        this.sendingRequest = true;
        let data = {
            ...this.souscriptionForm.value,
        };
        if (this.assure == 1) {
            data.assure = data.souscripteur;
        }


        this.subscriptionService.saveSouscription(data).subscribe((resp) => {
            this.sendingRequest = false;
            if (resp['responseCode'] == 200) {
                let type = '&meansType=CARTE_OTHER';
                if (this.souscriptionForm.get('simulation').get('montantResultcapital') == null) {
                    this.paymentForm.amount = this.souscriptionForm.get('simulation').get('montantResultcapital').value
                } else {
                    this.paymentForm.amount = this.souscriptionForm.get('simulation').get('montant').value
                }
                if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
                let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.timestamp + 'XOF' + this.key;
                this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
                // this.paymentForm.customerLastName = this.souscriptionForm.get('assure').get('nom').value
                // this.paymentForm.customerFirstName = this.souscriptionForm.get('assure').get('prenom').value
                // this.paymentForm.customerAddress =this.souscriptionForm.get('assure').get('adresse').value
                // this.paymentForm.customerEmail =this.souscriptionForm.get('assure').get('email').value
                // this.paymentForm.customerPhone =this.souscriptionForm.get('assure').get('numeroTelephone').value
                this.paymentForm.customerFirstName = this.souscriptionForm.get('souscripteur').get('prenom').value;
                this.paymentForm.customerLastName = this.souscriptionForm.get('souscripteur').get('nom').value;
                this.paymentForm.customerPhone = this.souscriptionForm.get('souscripteur').get('telephone').value;
                this.paymentForm.customerCountry = 'SENEGAL';
                this.paymentForm.customerCity = 'DAKAR';
                this.paymentForm.customerState = 'DAKAR';
                this.paymentForm.customerZipCode = '25455';
                this.paymentForm.customerAddress = 'DAKAR';
                this.paymentForm.customerEmail = this.souscriptionForm.get('souscripteur').get('email').value;
                // console.log(this.paymentForm);
                this.initPaymentForm.montant = this.paymentForm.amount;
                this.initPaymentForm.requestId = this.paymentForm.requestId
                this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
                this.initPaymentForm.status = "PENDING"
                this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
                    if (rep['responseCode'] != 200) {
                        this.utilsService.showToastError(resp['message'], "Echec");
                    }
                });
                this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
                    if (respApg['code'] == '0000') {
                        this.utilsService.showToastSuccess('Votre souscription a été enregistréé avec succès. Vous allez être redirigé vers la page de paiement', 'Souscription réussie')
                        setTimeout(() => {
                            window.open(respApg['paymentUrl'] + type, '_blank');
                        }, 3000);
                        this.checkSubscription(this.initPaymentForm.numeroSouscription)
                    } else {
                        this.utilsService.showToastError(respApg['message'], 'Echec');

                    }
                });
            } else {
                this.utilsService.showToastError(resp['message'], 'Echec');
            }
        }, error => {
            let message = 'Echec souscription. Veuillez vérifier vos informations et recommencer SVP';
            if (error && error.error) {
                message = error.error.message;
            }
            this.utilsService.showToastError(message, 'Echec');

            this.sendingRequest = false;
        });
    }

    onDateSelect(event, groupName, controlName) {
        this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
    }

    // getFormules(){
    //   this.sendingRequest = true;
    //   this.subscriptionService.getFormules().subscribe((resp) => {
    //     this.allFormula = resp['data'];
    //     this.sendingRequest = false;
    //   }, error => {
    //     this.sendingRequest = false
    //   });
    // }

    // getDuration(){
    //   this.subscriptionService.getDuration().subscribe((resp) =>{
    //     this.durees  = resp['data'];
    //   });
    // }

    getPaymentMethods() {
        this.subscriptionService.getPaymentMethods().subscribe((resp) => {
            this.paymentMethods = resp['data'];
        });
    }

    getProducts() {
        this.subscriptionService.getProducts().subscribe((resp) => {
            this.products = resp['data'];
            let typeAss = this.products.find((p) => p.id == this.idAssurance);
            this.devisType = typeAss ? typeAss.code.toLowerCase().split(' ').splice(-1)[0] : 'automobile';
        })
    }

    selectPayment(pay) {
        this.souscriptionForm.get('moyenDePaiement').setValue(pay.code == this.souscriptionForm.get('moyenDePaiement').value ? null : pay.code);
        this.paymentMethod = pay.nom.toLowerCase();
    }

    selectFormule(ass, pack) {
        // alert('ok')
        this.souscriptionForm.get('simulation').get('produit').setValue(pack.code);
        // this.paymentForm.amount = pack.prix;
        this.wizard.goToNextStep();
    }

    simuleVie() {
        let produit = this.souscriptionForm.get('simulation').get('produit').value;
        let duree = this.souscriptionForm.get('simulation').get('duree').value;
        let age: Date = this.souscriptionForm.get('simulation').get('age').value;
        let montant = this.souscriptionForm.get('simulation').get('montant').value;
        let type = this.type;
        let simulation: Simulation = new Simulation(this.today.year - age.getFullYear(),
            this.getMontant(montant, 'capital') ? Number(this.getMontant(montant, 'capital')) : null,
            duree + 0,
            this.getMontant(montant, 'prime') ? Number(this.getMontant(montant, 'prime')) : null,
            produit + '',
        )
        //this.age = age;
        //         this.capitalGaranti = capitalGaranti;
        //         this.primeAnnuelle = primeAnnuelle;
        //         this.produit = produit;
        //         this.dureeContrat = dureeContrat;
        this.subscriptionService.vieSimulation(simulation).subscribe((response) => {
            this.simulationVie = response['data']
            this.souscriptionForm.get('simulation').get('montantResult' + type).setValue(type == 'prime' ? this.simulationVie.capitalGaranti : this.simulationVie.primeAnnuelle)
        })
        console.log(this.simulationVie);
    }

    getMontant(montant, type) {
        if (this.type == type)
            return montant
        return null
    }

    enterStep(event) {
        if (this.assure == 1) {
            this.souscriptionForm.get('assure').patchValue(this.souscriptionForm.get('souscripteur').value);
            this.souscriptionForm.get('assure').get('dateNaissance').setValue(this.souscriptionForm.get('souscripteur').get('dateNaissance').value);
            if (this.souscriptionForm.get('assure').get('dateNaissance').value) {
                let day=this.souscriptionForm.get('assure').get('dateNaissance').value.getDate();
                let month=this.souscriptionForm.get('assure').get('dateNaissance').value.getMonth()+1;
                let year=this.souscriptionForm.get('assure').get('dateNaissance').value.getFullYear();
                this.date= year+'-'+month+'-'+day;  
            }
            
            this.souscriptionForm.get('assure').get('numeroTelephone').setValue(this.souscriptionForm.get('souscripteur').get('telephone').value);
        }
    }

    setAssure(n) {
        this.assure = n;
    }

    setType(type: string) {
        this.type = type;
    }

    getType() {
        switch (this.type) {
            case 'capital':
                this.resultType = 'de la prime'
                return 'du capital'
            default:
                this.resultType = 'du capital'
                return 'de la prime'
        }
    }

    checkSubscription(numberSouscription) {
        const source = interval(5000)
        const check = source.subscribe(x => {
            fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
                .then(response => {
                    response.json()
                        .then(data => {
                            if (data['responseCode'] == 200) {
                                this.router.navigateByUrl("success-payment");
                                check.unsubscribe();
                            }
                        });
                });
        });
    }
    public openPDF() {
        // @ts-ignore
        let doc = new jsPDF();
        document.body.style.zoom = "30%";
        var options = {
            pagesplit: true
        };
        doc.addHTML(this.mondevis.nativeElement, options, function () {
            doc.save('Devis.pdf');
        });
        document.body.style.zoom = "100%";
    }
}

