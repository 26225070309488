<div (scroll)="onScroll($event)">
    <app-enterprice-header></app-enterprice-header>
    <app-enterprice-nav></app-enterprice-nav>
    <app-enterprice-services></app-enterprice-services>

    <app-enterprice-download></app-enterprice-download>
    <!-- <app-enterprice-screenshots></app-enterprice-screenshots> -->
    <app-enterprice-pricing></app-enterprice-pricing>
    <!--<app-enterprice-pricing></app-enterprice-pricing>
  <app-enterprice-about></app-enterprice-about>
  <app-enterprice-subscribe></app-enterprice-subscribe>-->
    <app-enterprice-footer2></app-enterprice-footer2>
    <app-enterprice-footer></app-enterprice-footer>
</div>