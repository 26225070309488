<div class="navbar-area" (scroll)="onScroll($event)" class="{{scrollClass}}">
    <div class="main-navbar fixed-nav-top">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" href="index.html">
                    <img src="../../../../assets/images/insurtech/logo-01-01.png" alt="logo" class="logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i class="fa fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <!-- <li class="nav-item">
                            <a class="nav-link service" (click)="ouvrir1()">
                                Qui sommes Nous?
                                <i class="fa fa-angle-down" *ngIf="media"></i>
                                <i class="fa fa-plus" *ngIf="!media"></i>
                            </a>
                            <ul class="dropdown-menu daa" *ngIf="media">
                                <li class="nav-item">
                                    <a href="prez.html" class="nav-link">
                                        Insurtech
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a href="valeurs.html" class="nav-link">
                                        Valeurs
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="mission.html" class="nav-link">
                                        Missions
                                    </a>
                                </li>


                                <li class="nav-item">
                                    <a href="equipe.html" class="nav-link">
                                        Equipe
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Actu & Infos
                                    </a>
                                </li>
                            </ul>
                            <ul class=" daa2" *ngIf="hidden1">
                                <li class="nav-item">
                                    <a href="prez.html" class="nav-link">
                                        Optima
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a href="valeurs.html" class="nav-link">
                                        Valeurs
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="mission.html" class="nav-link">
                                        Missions
                                    </a>
                                </li>


                                <li class="nav-item">
                                    <a href="equipe.html" class="nav-link">
                                        Equipe
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Actu & Infos
                                    </a>
                                </li>
                            </ul>
                        </li> -->

                       <!-- <li class="nav-item changeColor">
                            <a class="nav-link">
                                Envoyer de l'argent
                            </a>

                        </li>
-->
                        <li class="nav-item changeColor" *ngFor="let childrenItem of products">
                            <a class="nav-link service1"  (click)="newIndentifiant(childrenItem?.id)" routerLink="{{childrenItem.path}}" *ngIf="childrenItem.type === 'link' && identifiant === childrenItem.id">
                                {{childrenItem.title}}
                                <!-- <i class="fa fa-angle-down" *ngIf="media"></i>
                                <i class="fa fa-plus" *ngIf="!media"></i> -->
                            </a>
                            <a class="nav-link service" (click)="newIndentifiant(childrenItem?.id)" routerLink="{{childrenItem.path}}" *ngIf="childrenItem.type === 'link' && identifiant !== childrenItem.id">
                                {{childrenItem.title}}
                                <!-- <i class="fa fa-angle-down" *ngIf="media"></i>
                                <i class="fa fa-plus" *ngIf="!media"></i> -->
                            </a>
                            <!-- <li  class="nav-item c-nav-item">
                                <a routerLink="{{childrenItem.path}}" *ngIf="childrenItem.type === 'link'">
                                    {{childrenItem.title}}</a>
                            </li> -->
                            <!-- <ul class="dropdown-menu static" *ngIf="media">
                                <li *ngFor="let childrenItem of products" class="nav-item c-nav-item">
                                    <a routerLink="{{childrenItem.path}}" *ngIf="childrenItem.type === 'link'">
                                        {{childrenItem.title}}</a>
                                </li>
                            </ul>
                            <ul class="nav-item dynamique" *ngIf="hidden2">
                                <li *ngFor="let childrenItem of products">
                                    <a routerLink="{{childrenItem ?.path}}" *ngIf="childrenItem .type === 'link'"
                                        class="nav-link">
                                        <i class="icon-{{childrenItem .icon}}"></i>{{childrenItem.title}}</a>
                                </li>
                            </ul> -->
                        </li>

                        <!--<li class="nav-item changeColor" >
                            <a class="nav-link ">
                               Change de devices
                            </a>
                            &lt;!&ndash; <ul class="dropdown-menu Autres" *ngIf="media">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Compte courant
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Compte d'épargne
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Tontine
                                    </a>
                                </li>


                            </ul>
                            <ul class="nav-item droitel" *ngIf="hidden">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Compte courant
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Compte d'épargne
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Tontine
                                    </a>
                                </li>


                            </ul> &ndash;&gt;
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link ">
                               Fidélité
                            </a>
                        </li>
-->
                        
                    </ul>
                    <div *ngIf="!utilisateur && !utilisateurs" class="others-options">
                        <a href="#" class="default-btn">
                            Télécharger l’application
                            <span></span>
                        </a>
                    </div>

                    <div *ngIf="utilisateur">
                        <span class="log-ini mt-3 ml-5" *ngIf="utilisateur">{{utilisateur?.infos?.firstName}}
                            {{utilisateur?.infos?.lastName}}</span>
                        <a target="_blank" placement="bottom" ngbPopover="Accéder à mon espace personnel"
                            triggers="mouseenter:mouseleave" href="https://bo-insurtech.suntelecoms.com/" class="log-in user">
                            <i class="fa fa-user  ml-2 "></i>
                        </a>
                        <span class="log-out user" placement="bottom" ngbPopover="Déconnexion"
                            triggers="mouseenter:mouseleave" [swal]="confirmDeconnexion"><i
                                class="fa fa-power-off  ml-2 "></i> </span>
                    </div>
                    <div *ngIf="utilisateurs">
                        <span class="log-ini mt-3 ml-5" *ngIf="utilisateurs">{{utilisateurs?.firstName}}
                            {{utilisateurs?.lastName}}</span>

                        <a target="_blank" placement="bottom" ngbPopover="Accéder à mon espace personnel"
                            triggers="mouseenter:mouseleave" href="http://ma.suntelecoms.com/" class="log-in user"
                            *ngIf="utilisateurs">
                            <i class="fa fa-user  ml-2 "></i>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<ng-template #contentResult let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Déclarer sinistre</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="declarersinistreForm" class="auto">
            <div class="form-container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <i class="fa fa-shield text-white"> <span class="text-red">*</span></i>
                                </div>
                                <input type="text" formControlName="numeroPolice" name="numeroPolice"
                                    (keypress)="numberOnly($event)" appBlockCopyPaste class="form-control"
                                    placeholder="Police d'assurance">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                </div>
                                <input type="text" class="form-control" [maxDate]="today"
                                    [minDate]="{year: 1900, month: 1, day: 1}" name="date"
                                    (dateSelect)="onDateSelect($event,'date')" ngbDatepicker #datebenef="ngbDatepicker"
                                    (click)="datebenef.toggle()" placeholder="Date du sinistre">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <i class="fa fa-map-marker text-white"> <span class="text-red">*</span></i>
                                </div>
                                <input type="text" class="form-control" formControlName="lieu"
                                    placeholder="Lieu du sinistre" name="lieu">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        <div class="form-group">


                            <textarea class="form-control" name="description" formControlName="description" cols="30"
                                rows="10" placeholder="Decriver le sinistre en quelques lignes"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
            <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
        </form>
    </div>
    <!-- <div class="modal-footer">
      
    </div> -->

</ng-template>
<swal #confirmDeconnexion title="Confirmation" text="Voulez-vous vraiment proceder à la déconnexion ?" icon="question"
    [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'Confirmer'" [cancelButtonText]="'ANNULER'"
    (confirm)="deconnexion()">
</swal>
