<app-enterprice-header></app-enterprice-header>
<app-enterprice-nav></app-enterprice-nav>
<div class="amsa-form-page" >
  <div class="container-gauche bg-dark" *ngIf="devisType == 'retraite' ">
    <div class="mask">
    </div>
    <h2 class="text-center main-title"><i>Devis assurance voyage</i></h2>
    <!-- <img  src="../../../../../assets/images/car-d.png" alt="" > -->
    <div class="decoration-droite bg-dark"></div>
    <div class="row container-row justify-content-left">
      <div class="col-12 droite">
        <form [formGroup]="souscriptionForm" *ngIf="devisType == 'retraite' || devisType == 'voyage'">
          <aw-wizard  class="stepper-container pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
            <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Initialisation">
              <div class="form-container">
                <!-- <div class="row assureur" *ngFor="let ass of assureurs; " placement="left"
                   ngbPopover="{{pack?.formule?.description}}" triggers="mouseenter:mouseleave" popoverTitle="{{ass?.assureur?.nom}}">
                  <div class="assureur-content w-100">
                      <div class="assureur-title" [class.selected]="souscriptionForm.get('assureur').value == ass.id">
                        {{ass?.assureur?.nom}}
                      </div>
                      <div class="forfait-content row">
                        <div class="col-lg-4 col-md-4 col-xs-6 col-sm-6"  *ngFor="let pack of ass?.assureur?.devis">
                          <div class="devis-content" >
                            <div class="title-pack">
                              Pack:<b>{{pack?.formule?.nom}}</b>
                            </div>
                            <div class="img-section">
                              <img class="w-100" src="assets/images/app_landing1/1-1.png" alt="">
                            </div>
                            <div class="pack-content text-left">
                                Prix: <b>{{pack?.prix}} / {{pack?.duree?.unite}}</b>
                            </div>
                            <hr>
                            <div class="pack-description text-left">
                              <h6>Avantages</h6>
                              <p>>Rapatriement en cas d’accident, de maladie ou de décès et envoi d’un médecin sur place.</p> 
                              <p>> Prise en charge des frais médicaux, de recherche et de secours à l’étranger.</p> 
                              <p>> Visite d’un proche en cas d’hospitalisation.</p>
                            </div>
                            <div class="buttons">
                                <button type="button" class="devis-btn btn btn-large" name="button" (click)="selectFormule(ass, pack)">Choisir</button>
                            </div>
                          </div>

                        </div>
                      </div>
                  </div>
                </div> -->
                <div class="container" >
                  <ul class="nav nav-tabs" role="tablist" *ngFor="let ass of assureurs">
                    <li class="nav-item" style="background:hsl(218, 60%, 32%)">
                      <a class="nav-link active" data-toggle="tab" href="{{ass?.assureur?.nom}}">{{ass?.assureur?.nom}}</a>
                    </li>
                  </ul>
                  <div class="tab-content" *ngFor="let ass of assureurs">
                    <div id="{{ass?.assureur?.nom}}" class="container tab-pane active"><br>
                      <!-- <h3>{{ass?.assureur?.nom}}</h3> -->
                      <div class="row pack-row" >
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col" *ngFor="let pack of ass?.assureur?.devis">
                     
                          <div class="box-part text-center" (click)="selectFormule(ass, pack)" placement="right"
                          ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave" popoverTitle="{{ass?.assureur?.nom}}">
                              
                              <!-- <i class="fa fa-twitter fa-3x" aria-hidden="true"></i> -->
                              <img class="heart" src="assets/images/voyage.png" alt="">   
                            <div class="title">
                              <h4 style="color: #2e8746 !important;">{{pack?.formule?.nom}}</h4>
                            </div>
                                        
                            <div class="text">
                              <h6 >Avantages</h6>
                              <p>Tout les frais medicaux sont prix en charge jusqu'à 500000</p>
                              <h6 >Réseaux de Couverture</h6>
                              <p>Hopital dalal diam, Croix rouge, Le DANTEC</p>
                              <!-- <span>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span> -->
                            </div>
                                        
                            <b>{{pack?.prix}} / {{pack?.duree?.unite}}</b>
                                        
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('duree').invalid"  awNextStep>Suivant <i class="fa fa-angle-double-right"></i></button> -->
            </aw-wizard-step>
            <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Voyage"> 
              <div class="form-container" formGroupName="voyage">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner la date de début!" triggers="mouseenter:mouseleave" popoverTitle="Date debut">
                      <div class="input-group-append">
                      <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                      </div>
                       <input type="text" class="form-control" [minDate]="today" [maxDate]="{year: 2100, month: 1, day: 1}"
                       (dateSelect)="onDateSelect2($event, 'voyage','dateDebut')" ngbDatepicker #debut="ngbDatepicker" (click)="debut.toggle()" placeholder="Date début">
                    <!-- <input type="number" class="form-control" formControlName="duree" placeholder="Duree en jours"> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner la date de fin!" triggers="mouseenter:mouseleave" popoverTitle="Date de fin">
                      <div class="input-group-append">
                      <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                      </div>
                       <input type="text" class="form-control" [minDate]="today" [maxDate]="{year: 2900, month: 1, day: 1}"
                       (dateSelect)="onDateSelect3($event, 'voyage','dateFin')" ngbDatepicker #fin="ngbDatepicker" (click)="fin.toggle()" placeholder="Date fin">
                    <!-- <input type="number" class="form-control" formControlName="duree" placeholder="Duree en jours"> -->
                    </div>
                    <span class="text-danger" *ngIf="invaliddate">La date est incohérente</span>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner l'agence de voyage!" triggers="mouseenter:mouseleave" popoverTitle="Agence de voyage">
                      <div class="input-group-append">
                      <i class="fa fa-plane text-white"> <span class="text-red">*</span></i>
                      </div>
                   
                    <input type="text" class="form-control" formControlName="agence_voyage" placeholder="agence de voyage">
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group"  placement="left" ngbPopover="Pays dans lequel se trouve le voyageur" triggers="mouseenter:mouseleave" popoverTitle="Provenance">
                      <div class="input-group-append">
                          <i class="fa fa-map-marker text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" disabled value="Sénégal" formControlName="provenance">
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group"  placement="left" ngbPopover="Veuillez selectionner le destination!" triggers="mouseenter:mouseleave" popoverTitle="Destination">
                      <div class="input-group-append">
                      <i class="fa fa-map-marker text-white"> <span class="text-red">*</span></i>
                      </div>
                   
                    <select class="form-control"  formControlName="destination">
                      <option [value]="null"  disabled selected>Destination</option>
                      <option  *ngFor="let item of pays[0]?.items" [value]="item?.name">{{item?.name}}</option>
                    </select>
                    </div>
                  </div>
                  
                </div>
                <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
                <button type="button" class="btn btn-success btn-next pull-right" [disabled]="souscriptionForm.get('voyage').invalid" (click)="openModalResult()">Suivant <i class="fa fa-angle-double-right"></i></button>
              </div>
            </aw-wizard-step> 
            <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Souscripteur">
              <div class="form-container" formGroupName="souscripteur">
                <!-- <div class="row no-margin second-title">
                  <h2 class="mb-4 mx-auto">Souscripteur</h2>
                </div> -->
                <div class="row assurance-for justify-content-center">
                  <!-- <h6 class="col-">Je  souscris </h6> -->
                  <div class="radio-toolbar col-12">
                      <input type="radio" id="moi"  name="radioFruit" value="1" checked>
                      <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                      <input type="radio" id="tiers" name="radioFruit" value="2">
                      <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le prénom!" triggers="mouseenter:mouseleave" popoverTitle="Prénom">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Prénom <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le nom!" triggers="mouseenter:mouseleave" popoverTitle="Nom">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le numéro de téléphone!" triggers="mouseenter:mouseleave" popoverTitle="Téléphone">
                        <div class="input-group-append">
                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="numero">Numéro téléphone <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask" formControlName="telephone" placeholder="Numéro téléphone">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le numéro de carte d'identité!" triggers="mouseenter:mouseleave" popoverTitle="Carte d'identité">
                        <div class="input-group-append">
                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Numéro CNI <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control"  [mask]="cinMask" formControlName="numeroCni" placeholder="Numéro Carte d'identité">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner l'email!" triggers="mouseenter:mouseleave" popoverTitle="Email">
                        <div class="input-group-append">
                        <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Email <span class="text-red">*</span></label> -->
                      <input type="email" class="form-control" formControlName="email" placeholder="Email">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner la date de naissance!" triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                        <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Date de naissance <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect($event, 'souscripteur','dateNaissance')" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" placeholder="Date de naissance">
                      </div>
                      <!-- <label for="email"><span class="text-success">>></span>Date de naissance</label> -->
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <div class="form-group">
                  <label for="email">Sexe</label>
                  <select class="form-control"  formControlName="genre">
                  <option value="">Sélectionner une option</option>
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
              </div>
            </div> -->
                </div>
              </div>
              <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
              <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('souscripteur').invalid" awNextStep>Suivant <i class="fa fa-angle-double-right"></i></button>
              <!-- <button type="button" class="btn btn-success btn-next pull-right"  awNextStep>Suivant <i class="fa fa-angle-double-right"></i></button> -->
            </aw-wizard-step>
            <aw-wizard-step [navigationSymbol]="{ symbol: '3' }"  (stepEnter)="enterStep($event)" stepTitle="Assuré">
              <div class="form-container" formGroupName="assure">
                <!-- <div class="row no-margin second-title">
                  <h2 class="mb-4 mx-auto">Informations assuré</h2>
                </div> -->
                <div class="row no-margin">
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le prénom!" triggers="mouseenter:mouseleave" popoverTitle="Prénom">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Prénom <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le nom!" triggers="mouseenter:mouseleave" popoverTitle="nom">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                        <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le numéro!" triggers="mouseenter:mouseleave" popoverTitle="Numéro">
                        <div class="input-group-append">
                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="numero">Numéro téléphone <span class="text-red">*</span></label> -->
                        <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone" placeholder="Numéro téléphone">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le numéro de passeport!" triggers="mouseenter:mouseleave" popoverTitle="Passeport">
                        <div class="input-group-append">
                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Numéro CNI <span class="text-red">*</span></label> -->
                        <input type="text" class="form-control"    formControlName="passeport" placeholder="Numéro passeport">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner le mail!" triggers="mouseenter:mouseleave" popoverTitle="Email">
                        <div class="input-group-append">
                        <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Email <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control"  formControlName="email" placeholder="Email">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez selectionner la latéralité!" triggers="mouseenter:mouseleave" popoverTitle="Latéralité">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Latéralité <span class="text-red">*</span></label> -->
                      <select class="form-control" name="lateralite" formControlName="lateralite">
                        <option [value]="null"  selected disabled>Latéralité</option>
                        <option value="droitier">Droitier</option>
                        <option value="gaucher">Gaucher</option>
                      </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez renseigner la date de naissance!" triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                        <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Date de naissance <span class="text-red">*</span></label> -->
                      <input type="text" class="form-control"  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect($event, 'assure','dateNaissance')" ngbDatepicker #dateAss="ngbDatepicker" (click)="dateAss.toggle()" placeholder="Date de naissance" value="{{date}}">
                      </div>
                      <!-- <label for="email"><span class="text-success">>></span>Date de naissance </label> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group"  placement="left" ngbPopover="Veuillez selectionner le genre!" triggers="mouseenter:mouseleave" popoverTitle="Genre">
                        <div class="input-group-append">
                          <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Sexe <span class="text-red">*</span></label> -->
                        <select class="form-control"  formControlName="genre">
                          <option [value]="null"  disabled selected>SEXE</option>
                          <!-- <option value="">Sélectionner une option</option> -->
                          <option value="M">Masculin</option>
                          <option value="F">Féminin</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
              <!-- <button type="button" class="btn btn-success btn-next pull-right"   awNextStep>Suivant</button> -->
              <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('assure').invalid" awNextStep>Suivant</button>
            </aw-wizard-step>
            
           

            <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Devis"> -->
              <!-- <div class="cotainer devis-container">
                <div class="row row-devis">
                  <div class="col-12">
                    <div class="row row-title-devis align-items-center">
                      <div class="col-6 img-logo">
                        <img src="../../../../assets/images/logo6.png" alt="">
                      </div>
                      <div class="col-6 ">
                        <div class="row Informations">
                          <div class="col-12"><p>Tel: +221 33 860 60 67</p> </div>
                          <div class="col-12"><p>Email: info@optima.com</p> </div>
                        </div>
                      </div>
                    </div>
                    <div class="row row-subtitle-devis ">
                      <div class="col-3 justify-content-center bg-primary"> -->
                         <!-- <div class="row align-items-center">
                            <div class="col-12 devis-text">
                              Devis
                            </div>
                          </div> -->
                          <!-- <p class="devis-text">Devis</p>
                      </div>
                      <div class="col-9">
                          <div class="row assure">
                              <div class="col-12 assure-title">
                                Assuré
                              </div>
                              <div class="col-12 assure-text">
                                <div class="row align-items-center ">
                                  <div class="col-8"><p>Prénom</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('prenom').value}}</div>
                                  <div class="col-8"><p>Nom</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('nom').value}}</div>
                                  <div class="col-8"><p>DateNaissance</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('dateNaissance').value | date: 'dd/MM/yyyy'}}</div>
                                  <div class="col-8"><p>Numéro de téléphone</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('numeroTelephone').value}}</div>
                                  <div class="col-8"><p>Passeport</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('passeport').value}}</div>
                                  <div class="col-8"><p>Latéralité</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('lateralite').value}}</div>
                                  <div class="col-8"><p>Genre</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('genre').value}}</div>
                                  <div class="col-8"><p>Email</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('email').value}}</div>
                                  <div class="col-8"><p>Provenance</p> </div><div class="col-4">{{souscriptionForm.get('assure').get('provenance').value}}</div>
                                </div>
                              </div>
                          </div>
                          <div class="row assure">
                            <div class="col-12 assure-title">
                              Voyage
                            </div>
                            <div class="col-12 assure-text">
                              <div class="row align-items-center ">
                                <div class="col-8"><p>Durée</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('duree').value}} jour(s)</div>
                                <div class="col-8"><p>Destination</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('destination').value}}</div>
                                <div class="col-8"><p>Agence de voyage</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('agence_voyage').value}}</div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
              <button type="button" class="btn btn-success btn-next pull-right"  [disabled]="sendingRequest"  awNextStep>Suivant</button> -->
            <!-- </aw-wizard-step> -->
            <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Bénéficiaire">
              <div class="no-margin row">
              </div>
              <div class="row beneficiary-section form-container mt-4" formGroupName="beneficiaire"  *ngIf="beneficiaryChoosen">
                <!-- <div class="col-12">
                  <h4>Informations sur le bénéficiaire</h4>
                </div> -->
                <div class="row no-margin mt-4">
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Prénom</label> -->
                      <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="nom">Nom</label> -->
                      <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="numero">Numéro téléphone</label> -->
                      <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone" placeholder="Numéro téléphone">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Numéro CNI</label> -->
                      <input type="text" class="form-control"  [mask]="cinMask"  formControlName="numeroCni" placeholder="Numéro CNI">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="review">Adresse</label> -->
                      <input type="text" class="form-control"  formControlName="adresse" placeholder="Adresse">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Date de naissance</label> -->
                      <input type="text" class="form-control" [maxDate]="today"  [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker #datebenef="ngbDatepicker" (click)="datebenef.toggle()" placeholder="Date de naissance">
                      </div>
                      <!-- <label for="email"><span class="text-success">>></span>Date de naissance</label> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                        </div>
                      <!-- <label for="email">Sexe</label> -->
                      <select class="form-control"  formControlName="genre">
                        <option value="Sexe" disabled selected>Sexe</option>
                        <option value="">Sélectionner une option</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3 mb-4 justify-content-center" *ngIf="apiResponse?.title != ''">
                <div class="mx-auto alert alert-{{apiResponse?.type}}" >
                  <h4>{{apiResponse?.title}}</h4>
                  <p>{{apiResponse?.text}}</p>
                </div>
              </div>
             <br>
              <div class="row mb-4 justify-content-center">
                <!-- <h2 class="col-12">Choisir un moyen de paiement</h2> -->
                <br><br>
                <div class="badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                    <div class="badge" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
                      {{pay?.nom}}
                    </div>
                </div>
              </div>

              <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
              <button type="button" class="btn btn-success btn-next pull-right" [swal]="confirmSubscription" [disabled]="sendingRequest"  awNextStep> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
              <!-- <button type="button" class="btn btn-success btn-next pull-right"  [disabled]="sendingRequest"  awNextStep>Suivant</button> -->
            </aw-wizard-step>
          </aw-wizard>
        </form>
      </div>
    </div>
    <!-- <div class="souf"></div> -->
  </div>

  <assurance-automobile *ngIf="devisType == 'automobile'"></assurance-automobile>
  <assurance-mrh *ngIf="devisType == 'mrh'"></assurance-mrh>
  <assurance-sante *ngIf="devisType == 'sante'" [idAssurance]="idAssurance"></assurance-sante>
  <assurance-vie *ngIf="devisType == 'vie'" [idAssurance]="idAssurance"></assurance-vie>
  <assurance-voyage *ngIf="devisType == 'voyage'" ></assurance-voyage>
  <div *ngIf="devisType==''" class="spinner">
    <p><i class="fa fa-spinner fa-pulse c-spinner"></i></p>
  <p class="text-spinner"> Chargement en cours ...</p>
  </div>
  <!-- <div class="secure "></div>
  <div class="text-center text">Souscrivez vous en toute securité sur optima</div> -->
</div>
<ng-template #contentResult let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Devis</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="htmlData" >
        <div class="col-12"  >
          <div class="row row-title-devis">
            <div class="col-6 img-logo">
              <img src="../../../../assets/images/logo6.png" alt="">
            </div>
            <div class="col-6 ">
              <div class="row Informations">
                <div class="col-12"><p>Tel: +221 33 860 60 67</p> </div>
                <div class="col-12"><p>Email: info@optima.com</p> </div>
              </div>
            </div>
          </div>
          <div class="row row-subtitle-devis ">
            <div class="col-3 justify-content-center bg-primary">
                <p class="devis-text">Devis</p>
            </div>
            <div class="col-9">
                <div class="row assure">
                  <div class="col-12 assure-title">
                    Voyage
                  </div>
                  <div class="col-12 assure-text">
                    <div class="row align-items-center ">
                      <div class="col-8"><p>Durée</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('duree').value}} jour(s)</div>
                      <div class="col-8"><p>Destination</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('destination').value}}</div>
                        <div class="col-8"><p>Provenance</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('provenance').value}}</div>
                        <div class="col-8"><p>Agence de voyage</p> </div><div class="col-4">{{souscriptionForm.get('voyage').get('agence_voyage').value}}</div>
                    </div>
                  </div>
                </div>
                <div class="row assure">
                  <div class="col-12 assure-title">
                    Tarifs
                  </div>
                  <div class="col-12 assure-text">
                    <div class="row align-items-center ">
                      <div class="col-8"><p>PN</p> </div><div class="col-4">{{pn | number : '1.0-0': fr}} FCFA</div>
                      <div class="col-8"><p>Coût</p> </div><div class="col-4">{{cout | number : '1.0-0': fr}} FCFA</div>
                      <div class="col-8"><p>TE</p> </div><div class="col-4">{{te | number : '1.0-0': fr}} FCFA</div>
                      <div class="col-8"><p>TTC</p> </div><div class="col-4">{{price | number : '1.0-0': fr}} FCFA</div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
    </div>
    <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep><i class="fa fa-chevron-left"></i></button>
    <button type="button" class="btn btn-success btn-next pull-right"  [disabled]="sendingRequest"  awNextStep>Suivant</button> -->

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-primary" (click)="openPDF()">Telecharger</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
    <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
  </div>

</ng-template>
<swal
    #confirmSubscription
    title="Confirmation"
    text="Voulez-vous vraiment proceder à la souscription ?"
    icon="question"
    [showCancelButton]="true"
    [focusCancel]="false"
    [confirmButtonText]="'OUI, BIEN SURE'"
    [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<app-enterprice-footer2></app-enterprice-footer2>
<app-enterprice-footer></app-enterprice-footer>
