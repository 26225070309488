
<div class="app1">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
                <div class="center-content">
                    <div>
                        <h6 class="copyright-text text-white">Copyright © {{currentDate}} | SUN TELECOMS</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row align-items-center justify-content-center">
    <div class="col-lg-6">
        <ul class="top-list">
            <li>
                <i class="flaticon-clock"></i>
               03 janvier 2021
            </li>
            <li>
                <i class="flaticon-phone-call"></i>
                <a href="tel:821-456-241"> Tel: +221 33 860 60 67</a>
            </li>
            <li>
                <i class="flaticon-paper-plane"></i>
                <a href="#">contact@suntelecom.com</a>
            </li>
        </ul>
    </div>

    <div class="col-lg-6">
        <ul class="top-social">
            <li>
                <a href="#" class="facebook">
                    <i class="fab fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a href="#" class="twitter">
                    <i class="fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a href="#" class="pinterest">
                    <i class="fab fa-pinterest-p"></i>
                </a>
            </li>
            <li>
                <a href="#" class="instagram">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            <li>
                <a href="https://account.optima.world" class="log-in">
                    Compte particulier
                   
                </a>
            </li>
            <li>
                <a href="https://pms.optima.world/login_optima" class="log-in">
                    Compte Business
                   
                </a>
            </li>
            
        </ul>
    </div>
</div>
