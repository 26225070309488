import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IconPickerModule } from 'ngx-icon-picker';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule } from './layouts/layouts.module';
import { SharedModule } from './shared/shared.module';
import { SubscriptionService } from './shared/service/subscription.service';
import { Interceptor } from './shared/service/interceptor';
import { UtilsService } from './shared/service/utils.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {Moment}  from 'moment';
import { DatePipe } from '@angular/common'
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
import { NgbDateParserFormatter, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectPays } from './shared/service/select_pays.services';
import { NgbDateFRParserFormatter } from './shared/service/date_format';
import { GenererPDFComponent } from './generer-pdf/generer-pdf.component';

@NgModule({
  declarations: [
    AppComponent,
    GenererPDFComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutsModule,
    HttpClientModule,
    CarouselModule,
    ScrollToModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    IconPickerModule,
    NgbPopoverModule,
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    SubscriptionService,DatePipe,UtilsService,SelectPays,
    {
      provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
    },
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}
  ],exports:[NgbPopoverModule],

  bootstrap: [AppComponent]
})
export class AppModule { }
