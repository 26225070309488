<app-enterprice-header></app-enterprice-header>
<app-enterprice-nav></app-enterprice-nav>
<!-- <app-enterprice-header></app-enterprice-header> -->
  <div class="container-gauche">
    <div class="success-payment-section mt-4">
      <div class="row">
        <div class="col-12">
            <i class="fa fa-times fa-3x"></i>
        </div>
        <div class="col-12">
          <p class="mt-4 mb-4"><b>Une erreur est survenue !</b></p>
          <p class="mb-5">Votre paiement n'a pas été effectué.  Veuillez recommencer l'opération svp!</p>
        </div>
      </div>
    </div>
  </div>

<app-enterprice-footer2></app-enterprice-footer2>
<app-enterprice-footer></app-enterprice-footer>