<!-- services section Start-->
<!-- <section class="services" id="service">
  <div class="container">
      <div class="row justify-center">
          <div class="col-12">
              <div class="title">
                  <div class="main-title">
                      <h2>{{name}} Votre solution idéale</h2>
                  </div>
                  <div class="sub-title">
                      <p class="para">Retrouvez toutes les offres d'assurance sur notre Market Place
                  </div>
              </div>
          </div>
      </div>
      <div class="row no-margin">
        <div class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">
                <div class="swiper-slide"  *ngFor="let elm of insurances">
                  <div class="service-item" >
                      <div class="img-block">
                          <img [src]="elm.img" alt="" class="service-img img-fluid">
                      </div>
                      <div class="service-feature">
                          <h4 class="feature-text">{{elm.title}}</h4>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</section> -->
<!-- services section end-->
    <!-- Start Main Banner Area -->
    <div class="main-banner bg-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">

                        <div class="section-title">

                            <h1>Insurtech Globale Assurance Votre solution idéale</h1>

                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Main Banner Area -->

    <!-- Start About Area -->

    <!-- End About Area -->

    <!-- Start Services Area -->

    <!-- End Services Area -->

    <!-- Start Doctor Area -->

    <!-- End Doctor Area -->

    <!-- Start Goal Area -->
    <section class="goal-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="goal-image">
                        <img src="assets/img/goal.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="goal-content-item">
                        <div class="item-content">
                            <span>SERVICES</span>
                            <h3>INSURTECH GLOBALE ASSURANCE</h3>
                            <p>Se protéger ainsi que ses proches partout, tout le temps et de manière souple</p>
                            <p>– Ouvrir un compte</p>
                            <p>– S’assurer et assurer ses proches </p>
                            <p>– payer avec le mode de paiement de son choix</p>
<!--
                            <ul class="list">
                                <li>
                                    IARD
                                </li>
                                <li>
                                    VIE
                                </li>
                                <li>
                                    AUTRE
                                </li>

                            </ul>-->
                            <div class="goal-btn">
                                <a href="" (click)="redirection('https://bo-insurtech.suntelecoms.com/')" class="default-btn popup-youtube">
                                    Mon compte
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Goal Area -->
