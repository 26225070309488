import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnterpriceComponent } from './enterprice/enterprice.component';
import { DisclaimerComponent } from './enterprice/disclaimer/disclaimer.component';
import { FollowDisclaimerComponent } from './enterprice/follow-disclaimer/follow-disclaimer.component';


const routes: Routes = [
  {
    path: '',
    component: EnterpriceComponent,
    data: {
      title: "Micro Assurance"
    }
  },
  {
    path: 'auto_presentation',
    component: EnterpriceComponent,
    data: {
      title: "Micro Assurance auto"
    }
  }
  ,
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
    data: {
      title: "Micro Assurance"
    }
  },
  {
    path: 'follow-disclaimer',
    component: FollowDisclaimerComponent,
    data: {
      title: "Micro Assurance"
    }
  }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
