import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-header-top',
  templateUrl: './enterprice-header-top.component.html',
  styleUrls: ['./enterprice-header-top.component.scss']
})
export class enterpriceHeadertopComponent implements OnInit {
  currentDate: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
