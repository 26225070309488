<div class="container-gauche bg-dark" *ngIf="login">
  <div class="mask">
  </div>
  <h2 class="text-center main-title"><i>Devis Assurance Santé</i></h2>
  <div class="decoration-droite bg-dark"></div>

</div>
<div class="row pack-row justify-content-center mt-3" *ngIf="false">
  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col">
    <div class="box-part c-box-part p-0">
      <div (click)="selectFormule(assureurs[0], assureurs[0]?.assureur?.devis[0])" placement="top"
        ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave"
        popoverTitle="{{assureurs[0]?.assureur?.nom}}">
        <img class="hear" src="../../../../../assets/simulation.jfif" alt="">
        <div class="c-title">
          <h4 style="color: #2e8746 !important;" class="text-center title-form">PACK ASSURANCE
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div class="text c-text1">
            <h6 (click)="getPrestation(assureurs[0]?.assureur?.devis[0]?.formule?.description,'avantage')">Les avantages</h6>
          </div>
          <div class="text c-text">
            <h6 (click)="getPrestation(assureurs[0]?.assureur?.devis[0]?.produit?.prestations,'prestation')">Les prestations</h6>
          </div>
    
          <div class="text c-text2">
            <h6 (click)="getPrestataire(assureurs[0]?.assureur?.devis[0]?.produit?.reseauCouverture)">Les prestataires</h6>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <p>Des packs vous seront proposés afin que vous </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col">
    <div class="box-part c-box-part p-0">
      <div (click)="selectFormule(assureurs[0], assureurs[0]?.assureur?.devis[0])" placement="top"
        ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave"
        popoverTitle="{{assureurs[0]?.assureur?.nom}}">
        <img class="hear" src="../../../../../assets/simulation.jfif" alt="">
        <div class="c-title">
          <h4 style="color: #2e8746 !important;" class="text-center title-form">SIMULATION
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div class="text c-text1">
            <h6 (click)="getPrestation(assureurs[0]?.assureur?.devis[0]?.formule?.description,'avantage')">Les avantages</h6>
          </div>
          <div class="text c-text">
            <h6 (click)="getPrestation(assureurs[0]?.assureur?.devis[0]?.produit?.prestations,'prestation')">Les prestations</h6>
          </div>
    
          <div class="text c-text2">
            <h6 (click)="getPrestataire(assureurs[0]?.assureur?.devis[0]?.produit?.reseauCouverture)">Les prestataires</h6>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <p>Des packs vous seront proposés afin que vous </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row container-row justify-content-center" *ngIf="login">
  <div class="col-12 droite">

    <form [formGroup]="souscriptionForm">
      <aw-wizard class="stepper-container pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'"
        [disableNavigationBar]="false">
        <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Initialisation">
          <div class="form-container">
            <div class="container">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" style="background:hsl(218, 60%, 32%)" *ngFor="let ass of assureurs">
                  <a class="nav-link" data-toggle="tab" (click)="getCurrentAssure(ass?.assureur?.id)" href="{{ass?.assureur?.nom}}" *ngIf="!choose">{{ass?.assureur?.nom}}</a>
                </li>
                <li class="nav-item" style="background:hsl(218, 60%, 32%)">
                  <a class="nav-link"  href="" *ngIf="choose" >SIMULATEUR</a>
                </li>
              </ul>
              <div class="form-container" formGroupName="AxASimulateur" *ngIf="choose">
                <div class="row info-perso">
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" >
                    <div class="form" placement="top" ngbPopover="Type de formule"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-users form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <select id="cars" placeholder=" " class="form__select" 
                        formControlName="typeFormule" (change)="selectFormuleSimul($event)" >
                        <option value="" selected disabled>Type de formule</option>
                        <option [value]="formuleSante[0].id">{{formuleSante[0].nom}}</option>
                        <option [value]="formuleSante[1].id" [selected]="true">{{formuleSante[1].nom}}</option>
                      </select>
                      <label for="cars" class="form__label">Type de formule <span class="text-red"> *</span></label>              
                    </div>
                    <div class="error  text-red"
                         *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('typeFormule').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" >
                    <div class="form" placement="top" ngbPopover="Formule"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-user form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <select id="cars" placeholder=" " class="form__select" formControlName="formule" >
                          <option value="" selected disabled>Formule</option>
                          <option [value]="tf.id" *ngFor="let tf of typeFormuleAssure; let i=index;">{{tf.nom}}</option>
                      </select>
                      <label for="cars" class="form__label">Formule<span class="text-red"> *</span></label>              
                    </div>
                    <div class="error  text-red"
                         *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('formule').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!solo">
                    <div class="form" placement="top" ngbPopover="Age famille"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-user form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <select id="cars" placeholder=" " class="form__select" formControlName="isOld" >
                        <option value="" selected disabled>Famille âgée de</option>
                        <option value="1" >plus de 59 ans</option>
                        <option value="0">moins de 59 ans</option>
                      </select>
                      <label for="cars" class="form__label"
                       *ngIf="souscriptionForm.get('AxASimulateur').get('isOld').valid">Famille âgée de<span class="text-red"> *</span></label>              
                    </div>
                    <div class="error  text-red"
                         *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('isOld').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="solo">
                    <div class="form" ngbPopover="Date de naissance" triggers="mouseenter:mouseleave">
                      <i class="fa fa-calendar form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <input type="text" id="age" class="form__input" autocomplete="off" placeholder=" "
                      [maxDate]="today" [minDate]="{year: 1950, month: 1, day: 1}"
                          (dateSelect)="onDateSelect($event, 'AxASimulateur','age')" [value]="" ngbDatepicker #dateAss="ngbDatepicker"
                          (click)="dateAss.toggle()"/>
                      <label for="age" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red"
                      *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('age').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"  *ngIf="!solo">
                    <div class="form" ngbPopover="Nombre d'adultes" triggers="mouseenter:mouseleave">
                      <i class="fa fa-user form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <input type="number" min="0" id="nombreAdulte" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreAdulte"/>
                      <label for="nombreAdulte" class="form__label">Nombre d'adultes<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red"
                      *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('nombreAdulte').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!solo">
                    <div class="form" ngbPopover="Nombre d'enfants" triggers="mouseenter:mouseleave">
                      <i class="fa fa-user form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <input type="number" min="0" id="nombreEnfant" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreEnfant"/>
                      <label for="nombreEnfant" class="form__label">Nombre d'enfants<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red"
                      *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('nombreEnfant').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                  <div class="row container-row justify-content-center mt-0 mb-0">
                    <button type="button" class="btn btn-success btn-next simulateurSuiv"
                      (click)="validerInitial()"> Suivant 
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="!choose">
                <div class="tab-content" *ngFor="let ass of assureurs">
                  <div id="{{ass?.assureur?.nom}}"   class="container tab-pane active"><br>
                    <div class="row pack-row justify-content-center">
                      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col"
                        *ngFor="let pack of ass?.assureur?.devis">
  
                        <div class="box-part c-box-part" *ngIf="currentAssure === ass?.assureur?.id">

                          <div (click)="selectFormule(ass, pack)" placement="top"
                            ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave"
                            popoverTitle="{{ass?.assureur?.nom}}">
                            <img class="heart" [src]="pack?.formule?.image" alt="">
                            <div class="c-title">
                              <h4 style="color: #2e8746 !important;" class="text-center title-form">{{pack?.formule?.nom}}
                              </h4>
                            </div>
                            <b class="prix">{{pack?.prix | number : '1.0-0':'fr' }} FCFA / {{pack?.duree?.unite}}</b>
                            <br> <b class="prxi"> Pendant &nbsp; {{pack?.duree?.displayMe}}</b>
                          </div>
                          <div class="text c-text1">
                            <h6 (click)="getPrestation(pack?.formule?.description,'avantage')">Les avantages</h6>
                          </div>
                          <div class="text c-text">
                            <h6 (click)="getPrestation(pack?.produit?.prestations,'prestation')">Les prestations</h6>
                          </div>
  
                          <div class="text c-text2">
                            <h6 (click)="getPrestataire(pack?.produit?.reseauCouverture)">Les prestataires</h6>
  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </aw-wizard-step>
        
        <!-- choose && !solo-->
        <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assurés" *ngIf="choose" >
          <div class="container" *ngIf="!solo">
            <div class="col-12">
              <div class="row no-margin">
                <div class="col-12">
                  <div class="formulaire c-formulaire">
                    <h4 class="text-center">Veuillez télécharger et remplir la fiche maladie svp!
                      <a href=".././../../../../assets/doc/Questionnaire INDIVIDUELLE ACCIDENTS.docm"
                        download><i class="fa fa-download"></i></a>
                    </h4>
                    <p class="mt-4" [hidden]="attachments?.length"></p>
                    <div>
                      <div [hidden]="attachments?.length" class="text-center">Charger le formulaire<input type="file" accept=".PDF,.pdf,.doc,.DOC,.docm,.DOCM,.docx,.DOCX" name="attachments"
                          id="attachment_file" [disabled]="attachments.length !== 0" (change)="addAttachment($event)" /></div>
                      <div [hidden]="!attachments?.length">
                        <label for="field_attachments"></label>
                        <dl *ngFor="let attachment of attachments; let i = index" class="row justify-content-center c-fileName"
                          [ngClass]="{ 'bg-light text-muted': attachment.processing }">
                          <dt class="col-md-7 col-sm-9">
                            <span class="c-name" *ngIf="!attachment.id">{{ attachment.originalFilename }}</span>
                            <span class="badge badge-pill badge-light">{{attachment.sizeInBytes | filesize}}</span>
                          </dt>
                          <dt class="col-md-3 col-sm-1">
                            <button type="button" class="btn btn-sm c-btn-danger pull-right" *ngIf="!attachment.processing"
                              (click)="attachments.splice(i, 1)">
                              <i class="fa fa-times"></i></button>
                            <span *ngIf="attachment.processing">
                              <fa-icon icon="spinner"></fa-icon>Reading file &hellip;
                            </span>
                          </dt>
                        </dl>
                      </div>
                    </div>
                    <p class="text-danger  mt-2 text-center " *ngIf="noatt">Veuillez charger le formulaire de la fiche médicale svp!</p><br>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
                <div class="table-wrapper">
                    <div class="table-title">
                        <div class="row">
                            <div class="col-sm-8"><h2>Liste des assurés</h2></div>
                            <div class="col-sm-4">
                                <button type="button" class="btn btn-info add-new c-add-new" (click)="conjChoosen()" [disabled]="conjoits.length !== 0 && checkAdulte === nombreAdulte && checkEnfant === nombreEnfant">
                                  <i class="fa fa-plus"></i> Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr class="c-head">
                                <th>Numéro</th>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Type</th>
                                <th>Naissance</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of conjoits; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                <td>{{i+1}}</td>
                                <td>{{c.prenom}}</td>
                                <td>{{c.nom}}</td>
                                <td>{{c.type}}</td>
                                <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                <td>
                                    <a class="add" title="Add" data-toggle="tooltip"><i class="material-icons"></i></a>
                                    <a class="edit" title="Edit" data-toggle="tooltip" (click)="edit(c,i)"><i class="fa fa-pencil"></i></a>
                                    <a class="delete" title="Delete" data-toggle="tooltip" (click)="deleteSimulateurAssurer(c,i)"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>    
                        </tbody>
                      </table>
                      <p class="text-center" *ngIf="conjoits.length === 0">la liste des assurés est vide</p>
                </div>
            </div>
          </div>  
          <!--  -->
          <div class="row beneficiary-section form-container " formGroupName="assure" *ngIf="solo">
            <div class="col-12">
              <div class="row no-margin">
                <div class="col-12">
                  <div class="formulaire c-formulaire">
                    <h4 class="text-center">Veuillez télécharger et remplir la fiche maladie svp!
                      <a href=".././../../../../assets/doc/Questionnaire INDIVIDUELLE ACCIDENTS.docm"
                        download><i class="fa fa-download"></i></a>
                    </h4>
                    <p class="mt-4" [hidden]="attachments?.length"></p>
                    <div>
                      <div [hidden]="attachments?.length" class="text-center">Charger le formulaire<input type="file" accept=".PDF,.pdf,.doc,.DOC,.docm,.DOCM,.docx,.DOCX" name="attachments"
                          id="attachment_file" [disabled]="attachments.length !== 0" (change)="addAttachment($event)" /></div>
                      <div [hidden]="!attachments?.length">
                        <label for="field_attachments"></label>
                        <dl *ngFor="let attachment of attachments; let i = index" class="row c-fileName justify-content-center"
                          [ngClass]="{ 'bg-light text-muted': attachment.processing }">
                          <dt class="col-md-7 col-sm-9">
                            <span class="c-name" *ngIf="!attachment.id">{{ attachment.originalFilename }}</span>
                            <span class="badge badge-pill badge-light">{{attachment.sizeInBytes | filesize}}</span>
                          </dt>
                          <dt class="col-md-3 col-sm-1">
                            <button type="button" class="btn btn-sm btn-dange c-btn-danger pull-right" *ngIf="!attachment.processing"
                              (click)="attachments.splice(i, 1)">
                              <i class="fa fa-times"></i></button>
                            <span *ngIf="attachment.processing">
                              <fa-icon icon="spinner"></fa-icon>Reading file &hellip;
                            </span>
                          </dt>
                        </dl>
                      </div>
                    </div>
                    <p class="text-danger  mt-2 text-center" *ngIf="noatt">Veuillez charger le formulaire de la fiche médicale svp!</p><br>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row no-margin">
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" placement="top" ngbPopover="Type"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" >
                      <option selected disabled>Type</option>
                      <option value="1">Pour moi-même</option>
                      <option value="2">Pour un tiers</option>
                    </select>
                    <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" placement="top" ngbPopover="Sexe"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-intersex form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                      <option value="" selected disabled>Sexe</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="prenomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                    <label for="prenomAssure" class="form__label">Prénom<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                    *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                   <i class="fa fa-user form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="nomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                   <label for="nomAssure" class="form__label">Nom<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                   <i class="fa fa-phone form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="numeroTelephoneAssure" class="form__input" autocomplete="off" placeholder=" "
                   prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                   <label for="numeroTelephoneAssure" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                   <i class="fa fa-envelope form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                   <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                   Ce champ est obligatoire ou invalide
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                   triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                     <i class="fa fa-calendar form__icon"></i>
                     <div class="form__separator">
                     </div>
                     <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" "
                     [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                       (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                       #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()" 
                       [value]="souscriptionForm.get('assure').get('dateNaissance').value | date: 'dd/MM/yyyy'"/>
                     <label for="dateNaissance" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                   <i class="fa fa-vcard form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                   <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
  
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                   <i class="fa fa-briefcase form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                   <label for="secteuractivite" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
               <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                 <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                   <i class="fa fa-home form__icon"></i>
                   <div class="form__separator">
                   </div>
                   <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                   <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                 </div>
                 <div class="error  text-red"
                   *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                   Ce champ est obligatoire
                 </div>
               </div>
             </div>
            </div>
          </div>
          <!-- contentResultsign -->
          <ng-template #addAssures let-modal>
              <form [formGroup]="souscriptionForm">
                <div class="container px-4 py-2 mx-auto">
                  <div class="card card0 c-card">
                    <div class="row beneficiary-section form-container " formGroupName="assure" *ngIf="conjoits.length === 0">
                     <div class="row no-margin">
                      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                        <div class="row no-margin">
                          <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                          </div>
                          <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                              class="fa fa-times text-white times-ass c-times-ass"></i></a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" placement="top" ngbPopover="Type"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-user form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" >
                        <option selected disabled>Type</option>
                        <option value="1">Pour moi-même</option>
                        <option value="2">Pour un tiers</option>
                      </select>
                      <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" placement="top" ngbPopover="Sexe"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-intersex form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                        <option value="" selected disabled>Sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                    </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                          <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                          <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                          <i class="fa fa-phone form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" "
                          prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                          <label for="numeroTelephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                          <i class="fa fa-envelope form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                          <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                          Ce champ est obligatoire ou invalide
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                          triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                            <i class="fa fa-calendar form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" "
                            [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                              (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                              #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()" />
                            <label for="dateNaissance" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                          <i class="fa fa-vcard form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                          <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
        
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                          <i class="fa fa-briefcase form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                          <label for="secteuractivite" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                          <i class="fa fa-home form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                          <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint('assure')"
                              *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                      <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)"
                              *ngIf="updateConj"><i class="fa fa-pencil"></i> Modifier</button>
                    </div>
                    </div>
                    <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="conjoits.length > 0">
                     <div class="row no-margin">
                      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                        <div class="row no-margin">
                          <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                          </div>
                          <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                              class="fa fa-times text-white times-ass c-times-ass"></i></a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" placement="top" ngbPopover="Type"
                          triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <select id="cars" placeholder=" " class="form__select" 
                          (change)="setAssure($event)" formControlName="type">
                          <option selected>Type</option>
                          <option value="enfant" *ngIf="checkEnfant < nombreEnfant">Enfant</option>
                          <option value="adulte" *ngIf="checkAdulte < nombreAdulte">Adulte</option>
                          </select>
                          <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('beneficiaire').get('type').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" placement="top" ngbPopover="Sexe"
                          triggers="mouseenter:mouseleave">
                          <i class="fa fa-intersex form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                            <option value="" selected disabled>Sexe</option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                          </select>
                          <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                          <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="nomBenef" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                          <label for="nomBenef" class="form__label">Nom<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                            *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                          <i class="fa fa-home form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                          <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                          triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                            <i class="fa fa-calendar form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                            [value]="souscriptionForm.get('beneficiaire').get('dateNaissance').value | date:'dd/MM/yyyy'" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                                  (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                                  #datebenef="ngbDatepicker" (click)="datebenef.toggle()" />
                            <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                        </div>
                        <div class="error  text-red"
                            *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                          <i class="fa fa-phone form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask"
                          formControlName="numeroTelephone"/>
                          <label for="numeroTelephone" class="form__label">Numéro de téléphone<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid  && champsObligatoire == false">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                          <i class="fa fa-envelope form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                          <label for="email" class="form__label">Email<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                        </div>
                        <div class="error  text-red"
                          *ngIf="invalid && souscriptionForm.get('beneficiaire').get('email').invalid && champsObligatoire == false">
                          Ce champ est obligatoire ou invalide
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                          <i class="fa fa-user form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                          <label for="profession" class="form__label">Profession<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                        </div>
                        <div class="error  text-red"
                            *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid  && champsObligatoire == false">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" 
                          triggers="mouseenter:mouseleave">
                          <i class="fa fa-briefcase form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                          <label for="secteuractivite" class="form__label">Secteur activité<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                        </div>
                        <div class="error  text-red"
                            *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid  && champsObligatoire == false ">
                          Ce champ est obligatoire
                        </div>
                      </div>
                      <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint('beneficiaire')"
                              *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                      <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)"
                              *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
                    </div>
                    </div>
                  </div>
                </div>
              </form>
          </ng-template>
          <div class="row beneficiary-section form-container " formGroupName="beneficiaire"
               *ngIf="false">
            <div class="row no-margin">
              <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                <a (click)="conjointChoosen = false" class="text-right"><i
                        class="fa fa-times text-danger times-ass c-times-ass"></i></a>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                  <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                  <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                  <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Sexe"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-intersex form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                    <option value="" selected disabled>Sexe</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Veuillez renseigner le date de naissance" 
                triggers="mouseenter:mouseleave">
                  <i class="fa fa-calendar form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                           (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                           #datebenef="ngbDatepicker" (click)="datebenef.toggle()" />
                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" "
                    prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                  <label for="numeroTelephone" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                  <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" 
                triggers="mouseenter:mouseleave">
                  <i class="fa fa-briefcase form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                  <label for="secteuractivite" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint()"
                      *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
              <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)"
                      *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
            </div>

          </div>

          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()" [disabled]="getDevisLoader"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" 
              (click)="validerSiulateuradulte()" [disabled]="getDevisLoader">
              <i class="fa fa-spinner fa-pulse" *ngIf="getDevisLoader"></i>
              Suivant 
              <i class="fa fa-angle-double-right"></i>
            </button>
            </div>
          </div>
        </aw-wizard-step>
        <!-- *ngIf="choose" -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur" *ngIf="false">
          <div class="container">
            <h6 class="titleAssureur" *ngIf="listAssureurs.length !== 0">Veuillez choisir votre assureur pour obtenir votre devis</h6>
            <h6 class="titleAssureur" *ngIf="listAssureurs.length === 0" class="text-center">Désolé y'a pas d'assureur disponible pour votre choix !!!</h6>
            <div class="row justify-content-center">
              <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12" *ngFor="let res of listAssureurs" (click)="selectAssureur(res.id,res)">
                <div id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave"
                  popoverTitle="{{res.assureur.code}}">
                  <div [class]="idAssureur ===  res.assureur.id ? 'card rounded selectAssureur c-selectAssureur' : 'card rounded c-selectAssureur'">
                    <div class="card-image">
                      <img class="img-fluid " [src]="res.img" alt="{{res.assureur.nom}}" height="177px" />
                    </div>
                    <div class="c-card-ass my-name text-center">
                      <h6 class="nom">{{res.assureur.nom}}</h6>
                    </div>
                    <div class="c-card-ass my-price text-center">
                      <h6 class="nom1">Durée:&nbsp; <span *ngIf="res.assureur.parametrageSante[0].primeNette"> 1&nbsp;an</span> <span *ngIf="!res.assureur.parametrageSante[0].primeTtc" class="fa fa-spinner fa-pulse"> </span></h6>
                    </div>
                    <div class="c-card-ass my-price text-center mb-3">
                      <h6 class="nom1">Prix:&nbsp; <span *ngIf="res.assureur.parametrageSante[0].primeNette"> {{res.assureur.parametrageSante[0].primeTtc |number:'1.0-0':'fr'}} &nbsp;
                          FCFA</span> <span *ngIf="!res.assureur.parametrageSante[0].primeTtc" class="fa fa-spinner fa-pulse"> </span></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()" *ngIf="!devisend"><i
                class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" 
              (click)="validerSiulateur1()">Suivant <i class="fa fa-angle-double-right"></i></button>
            </div>
          </div>
        </aw-wizard-step>
        <!-- *ngIf="choose" -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assuré" *ngIf="false">
          <div class="form-container" formGroupName="assure">
            <div class="row no-margin">
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Type"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " (change)="setAssure($event)">
                    <option value="" selected disabled>Type</option>
                    <option value="1">Pour moi-même</option>
                    <option value="2">Pour un tiers</option>
                  </select>
                  <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Sexe"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-intersex form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                    <option value="" selected disabled>Sexe</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                  <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                  <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                  <i class="fa fa-phone form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                  prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                  <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                  <i class="fa fa-envelope form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                  <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                  Ce champ est obligatoire ou invalide
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                triggers="mouseenter:mouseleave">
                  <i class="fa fa-calendar form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                      #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()" />
                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                  <i class="fa fa-vcard form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                  <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                  <i class="fa fa-briefcase form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                  <label for="email" class="form__label">Secteur activité<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                  <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest "
                (click)="validerassure()">Suivant</button>
            </div>
          </div>

        </aw-wizard-step>
        <!-- choose && !solo -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Enfants" *ngIf="false">
            <h6 class="col-12 text-center text-primary mb-2">Famille
              {{this.souscriptionForm.get('assure').get('nom').value}}</h6>
            <div class="col-12">
              <table class="table">
                <thead>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Actions</th>
                  <th *ngIf="choix"><a (click)="beneficiaryChoosen = true"><i class="fa fa-plus text-primary"></i></a>
                  </th>
                </thead>
                <tbody>
                  <tr class="table-active" *ngFor="let ben of beneficiares; let index = index">
                    <td>{{ben.nom}}</td>
                    <td>{{ben.prenom}}</td>
                    <td *ngIf="index != 0"><a (click)="delete(index)"><i class="fa fa-times text-danger"></i></a></td>
                    <td *ngIf="index == 0">-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          <div class="container-lg" *ngIf="!enfantChoosen">
            <div class="table-responsive">
                <div class="table-wrapper">
                    <div class="table-title">
                        <div class="row">
                            <div class="col-sm-8"><h2>Liste enfants</h2></div>
                            <div class="col-sm-4">
                                <button type="button" class="btn btn-info add-new" (click)="enfChoosen()" [disabled]="enfants.length === souscriptionForm.get('AxASimulateur').get('nombreEnfant').value && !enfantSupplementaire">
                                  <i class="fa fa-plus"></i> Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr class="c-head">
                                <th>Numéro</th>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Naissance</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let enf of enfants; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                <td>{{i+1}}</td>
                                <td>{{enf.prenom}}</td>
                                <td>{{enf.nom}}</td>
                                <td>{{enf.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                <td>
                                    <a class="add" title="Add" data-toggle="tooltip"><i class="material-icons"></i></a>
                                    <a class="edit" title="Edit" data-toggle="tooltip" (click)="editEnfant(enf,i)"><i class="fa fa-pencil"></i></a>
                                    <a class="delete" title="Delete" data-toggle="tooltip" (click)="deleteSimulateurAssurer('enfant',i)"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>    
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
          <div class="row beneficiary-section form-container " formGroupName="beneficiaireEnfantAxa"
               *ngIf="enfantChoosen">
            <div class="row no-margin ">
              <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                <a (click)="enfantChoosen = false" class="text-right"><i
                        class="fa fa-times text-danger times-ass c-times-ass"></i></a>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                  <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('prenom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                  <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('nom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Veuillez renseigner le date de naissance" 
                triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                  <i class="fa fa-calendar form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                           (dateSelect)="onDateSelect($event, 'beneficiaireEnfantAxa','dateNaissance')" ngbDatepicker
                           #datebenef="ngbDatepicker" (click)="datebenef.toggle()"/>
                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('dateNaissance').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Sexe"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-intersex form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                    <option value="" selected disabled>Sexe</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                  <i class="fa fa-mobile form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                  prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                  <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('numeroTelephone').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                  <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('adresse').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                  <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('profession').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité"
                 triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                  <i class="fa fa-briefcase form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                  <label for="email" class="form__label">Secteur d'activité<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('secteuractivite').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <button type="button" class="btn  btn-large c-btnAjout" (click)="addEnfantSimul()"
                      *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
              <button type="button" class="btn  btn-large c-btnAjout" (click)="updateEnfantSimul()"
                      *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-right c-roundbtn"
                [disabled]="sendingRequest || souscriptionForm.get('assure').invalid" (click)="devisSimulateur()"> <i
                  class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Voir devis</button>
            </div>
          </div>
        </aw-wizard-step>
       <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Paiement" *ngIf="choose">
        
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
              <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
              <div class="badge" >
                Domicile
              </div>
              &lt;!&ndash; <img width="135px" src="assets/images/domicile.jpg" alt=""> &ndash;&gt;
            </div>
            <div class="mt-5 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
              <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
              <div class="badge" >
                Point relais
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <div class="form mt-5" placement="top" ngbPopover="Point de relais"
              triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <select id="mySelect" placeholder=" " class="form__select" 
              formControlName="pointRelaisControl">
              <option value="" disabled selected="selected">Sélectionner un point de relais</option>
              <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>              
            </div>
            <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
            <div class="form" ngbPopover="AdresseLivraison"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" "/>
              <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && souscriptionForm.get('adresseLivraison').invalid">
              Ce champ est obligatoire
            </div>
          </div>


          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
            <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
              <div class="badge">
                {{pay?.nom}}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
            </div>
          </div>
        </aw-wizard-step>-->
        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Paiement" *ngIf="choose">
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
              <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
              <div class="badge" >
                Domicile
              </div>
              <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
            </div>
            <div class="mt-5 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
              <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
              <div class="badge" >
                Point relais
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <!-- <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                </div>
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div> -->
            <div class="form mt-5" placement="top" ngbPopover="Point de relais"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <select id="mySelect" placeholder=" " class="form__select"
                      formControlName="pointRelaisControl">
                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>
            </div>
            <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
            <div class="form" ngbPopover="AdresseLivraison"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" "/>
              <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && souscriptionForm.get('adresseLivraison').invalid">
              Ce champ est obligatoire
            </div>
          </div>


          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
            <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
              <div class="badge">
                {{pay?.nom}}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                      class="fa fa-chevron-right"></i></button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Récapitulatif" *ngIf="choose">
          <div class="recap-form-container">

            <div class="info-devis">
              <div class="proposition">
                <div class="pro-title"> Proposition Sante</div>
                <div class="sub-title">(Cette proposition est valable pour une année )
                </div>
              </div>
              <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    Références du souscripteur
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('prenom').value}}
                        {{souscriptionForm.get('assure').get('nom').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('email').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('adresse').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('profession').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span>Références Proposition</span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
        
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>
        
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                      </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type formule</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{dataSimulateur.typeFormule}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Formule</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{dataSimulateur.formule}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an(s)
                      </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                        Minuit
                      </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="!solo">Conjoint(s)</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="!solo"> {{conjoits.length}}</div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="!solo">Enfant(s)</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="!solo"> {{enfants.length}}</div>
        
                      <!-- <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                  <div class="title-div">
                    <span> Identification du produit </span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{dataSimulateur.formule}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type formule</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{dataSimulateur.typeFormule}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Service</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{dataSimulateur.service}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{dataSimulateur.age}} an(s)
                          </div>
        
                        </div>
                      </div>
        
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Cout piéce</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{dataSimulateur.coutPiece}} Fcfa
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Taxe</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.taxe}} Fcfa
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime Nette</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.primeNette}} Fcfa
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime TTC</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.primeTtc}} Fcfa
                          </div>
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="proposition2">
                <div class="pro-title"> Avantages </div>
              </div> -->
              <!-- <div class="row c-row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                  <div class="title-div">
                    <span> Description </span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.avantages">
                        {{item.nom}}
                      </div>
        
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
                de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
              </div>
              <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span> Nom </span>
                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
                      triggers="mouseenter:mouseleave" (click)="goTo(0)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                        {{item.nom}}
                      </div>
        
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                  <div class="title-div">
                    Plafond (FCFA)
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                        {{item.plafond |number:'1.0-0':'fr'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                  <div class="title-div">
                    Fréquence
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                        {{item.frequence}}
                      </div>
        
        
                    </div>
                  </div>
                </div>
              </div>
        
              <div class="disposition">
                <div class="title-disp">
                  Dispositions complémentaires
                </div>
                <div class="desc-disp">
                  La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                  disposition de
                  l'article 13
                  <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                    générales
                    jointes, les conditions particulières ainsi que les closes en annexe.</div>
                  <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                    disposition des conditions générales (CG) qui leurs sont contraires.</div>
                  <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                    visas de
                    l'assureur et du souscripteur. </div>
                </div>
              </div>
            </div>
            <div class="footer-devis row">
              <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div class="fist-line">
                  <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                  conseil
                  d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                    number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                  <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                  code
                  des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                </div>
                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                  &nbsp;<b>{{currentAssureur.email}}</b> </div>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                <img class="footer-img" [src]="currentAssureur.img" alt="">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscriptionSimulation"
                [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Souscripteur" *ngIf="!choose">
          <div class="form-container" formGroupName="nombreAssure">
            <!-- <div class="row no-margin second-title">
          <h2 class="mb-4 mx-auto">Souscripteur</h2>
        </div> -->
            <div class="row info-perso">
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Nombre d'enfants" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="number" class="form-control" formControlName="nombreEnfant" placeholder="Nombre d'enfants">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Nombre d'enfants" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="number" min="0" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreEnfant"/>
                  <label for="email" class="form__label">Nombre d'enfants<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('nombreAssure').get('nombreEnfant').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Nombre d'adulte"
                       triggers="mouseenter:mouseleave" >
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="number" class="form-control" formControlName="nombreAdulte" placeholder="Nombre d'adulte">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Nombre d'adulte" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="number" min="0" id="nombreAdulte" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreAdulte"/>
                  <label for="nombreAdulte" class="form__label">Nombre d'adulte<span class="text-red"> *</span></label>
                </div>
                <div class="error  text-red"
                     *ngIf="invalid && souscriptionForm.get('nombreAssure').get('nombreAdulte').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest"
                (click)="validersous()">Suivant <i class="fa fa-angle-double-right"></i></button>
            </div>
          </div>

          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('souscripteur').invalid" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right"  awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Delais" *ngIf="!choose">
          <div class="form-container" formGroupName="contrat">

            <div class="row info-perso container justify-content-center">
              <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Date de début du contrat"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-calendar text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" [minDate]="today"
                      (dateSelect)="onDateSelect($event, 'contrat','debut')" ngbDatepicker #dateAss="ngbDatepicker"
                      (click)="dateAss.toggle()" placeholder="Date début contrat" name="debut">

                  </div>
                </div> -->
                <div class="form" ngbPopover="Date de début du contrat" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                  [minDate]="today" (dateSelect)="onDateSelect($event, 'contrat','debut')" ngbDatepicker #dateAss="ngbDatepicker"
                      (click)="dateAss.toggle()"/>
                  <label for="email" class="form__label">Date début contrat<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('contrat').get('debut').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest "
                (click)="validercont()">Suivant <i class="fa fa-angle-double-right"></i></button>
            </div>
          </div>
        </aw-wizard-step>
        <!--  -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré" *ngIf="false">
          <div class="form-container" formGroupName="assure">
            <div class="row assurance-for justify-content-right" *ngIf="true">
              <div class="radio-toolbar col-12">
                <input type="radio" id="moi" name="radioFruit" value="1" checked>
                <label for="moi" class="c-moi-meme" #forMe (click)="setAssure(1)">Pour moi-même</label>
                <input type="radio" id="tiers" name="radioFruit" value="2">
                <label for="tiers" class="c-tiers" (click)="setAssure(2)">Pour un tiers</label>
              </div>
            </div>
            <div class="row no-margin">
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <select class="form-control" (change)="setAssure($event)">
                      <option value="" selected disabled>Type</option>
                      <option value="1">Pour moi-même</option>
                      <option value="2">Pour un tiers</option>
                    </select>
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Type"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" >
                    <option value="" selected disabled>Type</option>
                    <option value="1">Pour moi-même</option>
                    <option value="2">Pour un tiers</option>
                  </select>
                  <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                  <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                  <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-phone text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                      formControlName="numeroTelephone" placeholder="Numéro téléphone">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                  <i class="fa fa-phone form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                  prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                  <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-envelope text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                  <i class="fa fa-envelope form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                  <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                  Ce champ est obligatoire ou invalide
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                    triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                    <div class="input-group-append">
                      <i class="fa fa-calendar text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                      #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()" placeholder="Date de naissance" >

                  </div>
                </div> -->
                <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                  <i class="fa fa-calendar form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                      #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()"/>
                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa  fa-vcard text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                  <i class="fa fa-vcard form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                  <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-briefcase text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="secteuractivite"
                      placeholder="Secteur activité">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                  <i class="fa fa-briefcase form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                  <label for="email" class="form__label">Secteur activité<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <select class="form-control" formControlName="genre" placeholder="Sexe">
                      <option value="" selected disabled>Sexe</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Sexe"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                    <option value="" selected disabled>Sexe</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                  <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                </div>
                <div class="error  text-red"
                  *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest "
                (click)="validerassure()">Suivant</button>
            </div>
          </div>

          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('souscripteur').invalid" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right"  awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré" *ngIf="!choose">
          <div class="" *ngIf="!showDevice">
            <div *ngIf="!beneficiaryChoosen" class="formulaire">
              <h4 class="text-center" *ngIf="!noatt">Veuillez télécharger et remplir la fiche maladie svp!
                <a href=".././../../../../assets/doc/Fiche_medicale.docx"
                  download><i class="fa fa-download"></i></a>
              </h4>
              <p class="mt-4"></p>
              <div>
                <div class="text-center">Charger le formulaire<input type="file" accept=".PDF,.pdf,.doc,.DOC,.docx,.DOCX" name="attachments"
                    id="attachment_file" (change)="addAttachment($event)" /></div>
                <div [hidden]="!attachments?.length">
                  <label for="field_attachments"></label>
                  <dl *ngFor="let attachment of attachments; let i = index" class="row"
                    [ngClass]="{ 'bg-light text-muted': attachment.processing }">
                    <dt class="col-md-8 col-sm-10">
                      <span *ngIf="!attachment.id">{{ attachment.originalFilename }}</span>
                      <span class="badge badge-pill badge-light">{{attachment.sizeInBytes | filesize}}</span>
                    </dt>
                    <dt class="col-md-4 col-sm-2">
                      <button type="button" class="btn btn-sm btn-danger pull-right" *ngIf="!attachment.processing"
                        (click)="attachments.splice(i, 1)">
                        <i class="fa fa-times"></i></button>
                      <span *ngIf="attachment.processing">
                        <fa-icon icon="spinner"></fa-icon>Reading file &hellip;
                      </span>
                    </dt>
                  </dl>
                </div>
              </div>
              <p class="text-danger" *ngIf="noatt">Veuillez charger le formulaire de la fiche médicale svp!</p><br>
            </div>
            <div class="row" *ngIf="!beneficiaryChoosen">
              <!-- <h4 class="text-center" *ngIf="!noatt">Veuillez télécharger et remplir la fiche maladie svp!</h4> -->
              <div class="col-12 justify-content-right form-group label-position" *ngIf="!choix">
                <!-- <div class="row">
                  <label for="moi" class="col-md-4 form-label label-position"><b>Ajouter Assuré ?</b></label>
                  <div class="col-4">
                    <button class="btn btn-primary" [class.active]="beneficiaryChoosen" (click)="benChoosen()"><i class="fa fa-plus"></i></button>
                  </div>
                </div> -->
              </div>
  
              <!-- <h6 class="col-12 text-center text-primary mb-2">Famille
                {{this.souscriptionForm.get('assure').get('nom').value}}</h6> -->
              <div class="col-12">
                <!-- <table class="table">
                  <thead>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Actions</th>
                    <th *ngIf="choix"><a (click)="beneficiaryChoosen = true"><i class="fa fa-plus text-primary"></i></a>
                    </th>
                  </thead>
                  <tbody>
                    <tr class="table-active" *ngFor="let ben of beneficiares; let index = index">
                      <td>{{ben.nom}}</td>
                      <td>{{ben.prenom}}</td>
                      <td *ngIf="index != 0"><a (click)="delete(index)"><i class="fa fa-times text-danger"></i></a></td>
                      <td *ngIf="index == 0">-</td>
                    </tr>
                  </tbody>
                </table> -->
                <div class="container-lg">
                  <div class="table-responsive">
                      <div class="table-wrapper">
                          <div class="table-title">
                              <div class="row">
                                  <div class="col-sm-8"><h2>Liste des assurés</h2></div>
                                  <div class="col-sm-4">
                                      <button type="button" class="btn btn-info add-new c-add-new" (click)="conjChoosen()" [disabled]="beneficiares.length !== 0 && checkAdulte === nombreAdulte && checkEnfant === nombreEnfant">
                                        <i class="fa fa-plus"></i> Ajouter
                                      </button>
                                  </div>
                              </div>
                          </div>
                          <table class="table table-bordered">
                              <thead>
                                  <tr class="c-head">
                                      <th>Numéro</th>
                                      <th>Prénom</th>
                                      <th>Nom</th>
                                      <th>Type</th>
                                      <th>Naissance</th>
                                      <th>Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let c of beneficiares; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                      <td>{{i+1}}</td>
                                      <td>{{c.prenom}}</td>
                                      <td>{{c.nom}}</td>
                                      <td>{{c.type}}</td>
                                      <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                      <td>
                                          <a class="add" title="Add" data-toggle="tooltip"><i class="material-icons"></i></a>
                                          <a class="edit" title="Edit" data-toggle="tooltip" (click)="edit(c,i)"><i class="fa fa-pencil"></i></a>
                                          <a class="delete" title="Delete" data-toggle="tooltip" (click)="deletePackAssurer(c,i)"><i class="fa fa-trash-o"></i></a>
                                      </td>
                                  </tr>    
                              </tbody>
                            </table>
                            <p class="text-center" *ngIf="beneficiares.length === 0">la liste des assurés est vide</p>
                      </div>
                  </div>
                </div>         
                <!-- contentResultsign -->
                <ng-template #addAssures let-modal>
                    <form [formGroup]="souscriptionForm">
                      <div class="container-md px-4 py-2 mx-auto">
                        <div class="card card0 c-card">
                          <div class="row beneficiary-section form-container " formGroupName="assure" *ngIf="beneficiares.length === 0">
                           <div class="row no-margin">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                              <div class="row no-margin">
                                <div class="col-10 p-0">
                                  <h6 class="text-left text-white c-cardTitle">Ajouter assurés</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                  <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Type" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <select class="form-control" (change)="setAssure($event)">
                                    <option selected disabled>Type</option>
                                    <option value="1">Pour moi-même</option>
                                    <option value="2">Pour un tiers</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="form" placement="top" ngbPopover="Type"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" >
                                  <option selected disabled>Type</option>
                                  <option value="1">Pour moi-même</option>
                                  <option value="2">Pour un tiers</option>
                                </select>
                                <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                              </div>
                              <!-- <div class="error  text-red"
                                *ngIf="souscriptionForm.get('assure').get('genre').invalid">
                                Ce champ est obligatoire
                              </div> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <select class="form-control" formControlName="genre">
                                    <option value="" selected disabled>Sexe</option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="form" placement="top" ngbPopover="Sexe"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-intersex form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                                  <option value="" selected disabled>Sexe</option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                                </select>
                                <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="souscriptionForm.get('assure').get('genre').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                                <label for="emai" class="form__label">Prénom<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                                <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                                  triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-phone text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                                    formControlName="numeroTelephone" placeholder="Numéro téléphone">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask"
                                formControlName="numeroTelephone"/>
                                <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-envelope text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                                <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                Ce champ est obligatoire ou invalide
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                                  triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                  <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" [value]="dateNaissance" class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                                    (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                                    #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()" placeholder="Date de naissance" >
              
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                                triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                  <i class="fa fa-calendar form__icon"></i>
                                  <div class="form__separator">
                                  </div>
                                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                                    (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                                    #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()"/>
                                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                                </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa  fa-vcard text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                <i class="fa fa-vcard form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                                <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
              
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-briefcase text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="secteuractivite"
                                    placeholder="Secteur activité">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                <i class="fa fa-briefcase form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                                <label for="email" class="form__label">Secteur d'activité<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                                <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('assure')"
                                    *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)"
                                    *ngIf="updateConj"><i class="fa fa-pencil"></i> Modifier</button>
                          </div>
                          </div>
                          <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="beneficiares.length > 0">
                           <div class="row no-margin">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                              <div class="row no-margin">
                                <div class="col-10 p-0">
                                  <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                  <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="assuré" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <select class="form-control" (change)="setAssure($event)" formControlName="type">
                                    <option selected>Type</option>
                                    <option value="enfant" *ngIf="checkEnfant < nombreEnfant">Enfant</option>
                                    <option value="adulte" *ngIf="checkAdulte < nombreAdulte">Adulte</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="form" placement="top" ngbPopover="Enfant ou adulte"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-intersex form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" 
                                (change)="setAssure($event)" formControlName="type" >
                                  <option selected>Type</option>
                                  <option value="enfant" *ngIf="checkEnfant < nombreEnfant">Enfant</option>
                                  <option value="adulte" *ngIf="checkAdulte < nombreAdulte">Adulte</option>
                                </select>
                                <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('beneficiaire').get('type').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <select class="form-control" formControlName="genre">
                                    <option value="" selected disabled>Sexe</option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="form" placement="top" ngbPopover="Sexe"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-intersex form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                                  <option value="" selected disabled>Sexe</option>
                                  <option value="M">Masculin</option>
                                  <option value="F">Féminin</option>
                                </select>
                                <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                                <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                                <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                                <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Veuillez renseigner le date de naissance"
                                    triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                                  <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text"  class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                                        (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                                        #datebenef="ngbDatepicker" (click)="datebenef.toggle()" placeholder="Date de naissance">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                                triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                  <i class="fa fa-calendar form__icon"></i>
                                  <div class="form__separator">
                                  </div>
                                  <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " 
                                  [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                                        (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                                        #datebenef="ngbDatepicker" (click)="datebenef.toggle()"/>
                                  <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                              </div>
                              <div class="error  text-red"
                                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                                  triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-phone text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                                    formControlName="numeroTelephone" placeholder="Numéro téléphone">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                                prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone"/>
                                <label for="email" class="form__label">Numéro de téléphone<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid && champsObligatoire == false">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-envelope text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                                <label for="email" class="form__label">Email<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                              </div>
                              <div class="error  text-red"
                                *ngIf="invalid && souscriptionForm.get('beneficiaire').get('email').invalid && champsObligatoire == false">
                                Ce champ est obligatoire ou invalide
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                  <div class="input-group-append">
                                    <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                                <label for="email" class="form__label">Profession<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                              </div>
                              <div class="error  text-red"
                                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid && champsObligatoire == false">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                              <!-- <div class="form-group">
                                <div class="input-group" placement="top" ngbPopover="Veuillez renseigner votre secteur d'activité"
                                    triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                  <div class="input-group-append">
                                    <i class="fa fa-briefcase text-white"> <span class="text-red"> *</span></i>
                                  </div>
                                  <input type="text" class="form-control" formControlName="secteuractivite"
                                        placeholder="Secteur activité">
      
                                </div>
                              </div> -->
                              <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité"
                               triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                <i class="fa fa-briefcase form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                                <label for="secteuractivite" class="form__label">Secteur d'activité<span class="text-red" *ngIf="champsObligatoire == false"> *</span></label>
                              </div>
                              <div class="error  text-red"
                                  *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid && champsObligatoire == false">
                                Ce champ est obligatoire
                              </div>
                            </div>
                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('beneficiaire')"
                                    *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)"
                                    *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </form>
                </ng-template>
              </div>
            </div>
  
            <div class="row beneficiary-section form-container " formGroupName="beneficiaire"
                 *ngIf="beneficiaryChoosen">
              <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                  <a (click)="beneficiaryChoosen = false" class="text-right"><i
                          class="fa fa-times text-danger times-ass"></i></a>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top"
                         ngbPopover="Veuillez selectionner la relation entre vous et le beneficiaire"
                         triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <select class="form-control" formControlName="type" (change)="checkType($event)">
                        <option value="" selected disabled>Relation</option>
                        <option [value]="r" *ngFor="let r of tabRelation">{{r}}</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="form" placement="top" ngbPopover="Veuillez selectionner la relation entre vous et le beneficiaire"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-intersex  form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" placeholder=" " class="form__select" 
                    formControlName="type" (change)="checkType($event)">
                      <option value="" selected disabled>Relation</option>
                      <option [value]="r" *ngFor="let r of tabRelation">{{r}}</option>
                    </select>
                    <label for="cars" class="form__label">Relation<span class="text-red"> *</span></label>              
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                    <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                    <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                         triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-mobile text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                             formControlName="numeroTelephone" placeholder="Numéro téléphone">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask"
                    formControlName="numeroTelephone"/>
                    <label for="numeroTelephone" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
  
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                    <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Veuillez renseigner le adresse"
                         triggers="mouseenter:mouseleave" popoverTitle="Adresse">
                      <div class="input-group-append">
                        <i class="fa fa-house text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                    <i class="fa fa-house form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                    <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Veuillez renseigner le date de naissance"
                         triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                      <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" [value]="dateNaissance" class="form-control" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                             (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                             #datebenef="ngbDatepicker" (click)="datebenef.toggle()" placeholder="Date de naissance">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Veuillez renseigner date de naissance" 
                    triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                      <i class="fa fa-calendar form__icon"></i>
                      <div class="form__separator">
                      </div>
                      <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" "
                      [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}"
                             (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker
                             #datebenef="ngbDatepicker" (click)="datebenef.toggle()" />
                      <label for="dateNaissance" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Veuillez selectionner  le sexe"
                         triggers="mouseenter:mouseleave" popoverTitle="Sexe">
                      <div class="input-group-append">
                        <i class="fa fa-intersex text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <select class="form-control" formControlName="genre" placeholder="Sexe">
                        <option value="" selected disabled>Sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="form" placement="top" ngbPopover="Veuillez selectionner  le sexe"
                    triggers="mouseenter:mouseleave" popoverTitle="Sexe">
                    <i class="fa fa-intersex form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" placeholder=" " class="form__select" formControlName="genre" >
                      <option value="" selected disabled>Sexe</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Veuillez renseigner votre secteur d'activité"
                         triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                      <div class="input-group-append">
                        <i class="fa fa-briefcase text-white"> <span class="text-red"> *</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="secteuractivite"
                             placeholder="Secteur activité">
  
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" 
                  triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                    <i class="fa fa-briefcase form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                    <label for="secteuractivite" class="form__label">Secteur d'activité<span class="text-red"> *</span></label>
                  </div>
                  <div class="error  text-red"
                       *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <button type="button" class="btn btn-primary btn-large btn-primary  col-4 m-auto" (click)="addbenef()"
                        *ngIf="beneficiaryChoosen"><i class="fa fa-plus"></i> Ajouter</button>
              </div>
  
            </div>
          </div>
          <!--  -->
          <div class="" *ngIf="showDevice">
            <div class="recap-form-container">
  
              <div class="mesImage">
                <div class="imgLogo">
                  <img [src]="currentAssureur.img" alt="">
                </div>
              </div>
              <div class="info-devis">
                <div class="proposition">
                  <div class="pro-title"> Proposition Sante Adja</div>
                  <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      Références du souscripteur
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('prenom').value}}
                          {{souscriptionForm.get('assure').get('nom').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('email').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('adresse').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('profession').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      <span>Références Proposition</span>
  
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave"
                        (click)="goTo(3)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
  
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>
  
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('prenom').value}}
                          {{souscriptionForm.get('assure').get('nom').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('assure').get('adresse').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{souscriptionForm.get('contrat').get('debut').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>
  
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                          Minuit
                        </div>
  
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.dureeDisplay}}</div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte(s)</div>
                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{nombreAdulte}}</div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant(s)</div>
                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{nombreEnfant}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                      <span> Identification du produit </span>
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave"
                        (click)="goTo(0)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{currentPack.formule}}
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Produit</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{currentPack.produit}}
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{age}} an(s)
                            </div>
  
                          </div>
                        </div>
  
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prix</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{currentPack.prix |number:'1.0-0':'fr'}}
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{currentPack.duree}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="proposition2">
                  <div class="pro-title"> Avantages</div>
                </div>
                <div class="row c-row">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                      <span> Description </span>
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave"
                        (click)="goTo(0)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.avantages">
                          {{item.nom}}
                        </div>
  
                      </div>
                    </div>
                  </div>
                </div>
                <div class="description-ass">
                  Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
                  de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                </div>
                <div class="row c-row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      <span> Nom </span>
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave"
                        (click)="goTo(0)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                          {{item.nom}}
                        </div>
  
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                      Plafond (FCFA)
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                          *ngFor="let item of currentPack.prestations">
                          {{item.plafond |number:'1.0-0':'fr'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                      Fréquence
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                          *ngFor="let item of currentPack.prestations">
                          {{item.frequence}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="disposition">
                  <div class="title-disp">
                    Dispositions complémentaires
                  </div>
                  <div class="desc-disp">
                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                    disposition de
                    l'article 13
                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                      générales
                      jointes, les conditions particulières ainsi que les closes en annexe.</div>
                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                      disposition des conditions générales (CG) qui leurs sont contraires.</div>
                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                      visas de
                      l'assureur et du souscripteur. </div>
                  </div>
                </div>
              </div>
              <div class="footer-devis row">
                <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                  <div class="fist-line">
                    <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                    conseil
                    d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                      number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                    <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                    code
                    des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                  </div>
                  <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                    &nbsp;<b>{{currentAssureur.email}}</b> </div>
                </div>
                <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                  <img class="footer-img" [src]="currentAssureur.img" alt="">
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retourDevice()"><i
                    class="fa fa-chevron-left"></i> Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" (click)="devisNextStep()"
                  > <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Suivant</button>
              </div>
            </div>
          </div>
          <!--  -->
        

          <div class="row justify-content-center" *ngIf="!showDevice">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-right c-roundbtn"
                [disabled]="beneficiares.length === 0 || checkAdulte !== nombreAdulte || checkEnfant !== nombreEnfant" (click)="showDevice = true"> <i
                  class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Voir devis</button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->

          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Paiement" *ngIf="false">
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
              <div class="badge" >
                Domicile
              </div>
              <img width="135px" src="assets/images/domicile.jpg" alt="">
            </div>
            <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
              <div class="badge" >
                Point relais
              </div>
              <img width="135px" src="assets/images/relais.jfif" alt="">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                </div>
                <!-- <label for="email">Marque <span class="text-red">*</span></label> -->
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div>
            <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>


          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
            <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
              <div class="badge" >
                {{pay?.nom}}
              </div>
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

        </aw-wizard-step>

   <!--     <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="!choose">
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
              <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
              <div class="badge" >
                Domicile
              </div>
              &lt;!&ndash; <img width="135px" src="assets/images/domicile.jpg" alt=""> &ndash;&gt;
            </div>
            <div class="mt-5 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
            <div class="mt-5 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
              <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
              <div class="badge" >
                Point relais
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            &lt;!&ndash; <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                </div>
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div> &ndash;&gt;
            <div class="form mt-5" placement="top" ngbPopover="Point de relais"
              triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <select id="mySelect" placeholder=" " class="form__select" 
              formControlName="pointRelaisControl">
              <option value="" disabled selected="selected">Sélectionner un point de relais</option>
              <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>              
            </div>
            <div class="error  text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
            <div class="form" ngbPopover="AdresseLivraison"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" "/>
              <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && souscriptionForm.get('adresseLivraison').invalid">
              Ce champ est obligatoire
            </div>
          </div>

          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
            <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
              <div class="badge">
                {{pay?.nom}}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
            </div>
          </div>
          &lt;!&ndash; <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> &ndash;&gt;
          &lt;!&ndash; <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> &ndash;&gt;
          </div>
        </aw-wizard-step>-->
        <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="!choose">
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
              <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
              <div class="badge" >
                Domicile
              </div>
              <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
            </div>
            <div class="mt-5 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
              <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
              <div class="badge" >
                Point relais
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <!-- <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                </div>
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div> -->
            <div class="form mt-5" placement="top" ngbPopover="Point de relais"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <select id="mySelect" placeholder=" " class="form__select"
                      formControlName="pointRelaisControl">
                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>
            </div>
            <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
            <div class="form" ngbPopover="AdresseLivraison"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" "/>
              <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && souscriptionForm.get('adresseLivraison').invalid">
              Ce champ est obligatoire
            </div>
          </div>

          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
            <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
              <div class="badge">
                {{pay?.nom}}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                      class="fa fa-chevron-right"></i></button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

        </aw-wizard-step>




        <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Récapitulatif" *ngIf="!choose">
          <div class="recap-form-container">

            <div class="mesImage">
              <div class="imgLogo">
                <img [src]="currentAssureur.img" alt="">
              </div>
            </div>
            <div class="info-devis">
              <div class="proposition">
                <div class="pro-title"> Proposition Sante</div>
                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                </div>
              </div>
              <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    Références du souscripteur
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('prenom').value}}
                        {{souscriptionForm.get('assure').get('nom').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('email').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('adresse').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('profession').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span>Références Proposition</span>

                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave"
                      (click)="goTo(3)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                      </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('prenom').value}}
                        {{souscriptionForm.get('assure').get('nom').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('assure').get('adresse').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{souscriptionForm.get('contrat').get('debut').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                        Minuit
                      </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.dureeDisplay}}</div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                          <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte</div>
                          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{checkAdulte}}</div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                          <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant</div>
                          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{checkEnfant}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                  <div class="title-div">
                    <span> Identification du produit </span>
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave"
                      (click)="goTo(0)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{currentPack.formule}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Produit</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{currentPack.produit}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{age}} an(s)
                          </div>

                        </div>
                      </div>

                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prix</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{currentPack.prix |number:'1.0-0':'fr'}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Durée</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{currentPack.duree}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="proposition2">
                <div class="pro-title"> Avantages</div>
              </div>
              <div class="row c-row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                  <div class="title-div">
                    <span> Description </span>
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave"
                      (click)="goTo(0)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.avantages">
                        {{item.nom}}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
                de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
              </div>
              <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span> Nom </span>
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave"
                      (click)="goTo(0)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                        {{item.nom}}
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                  <div class="title-div">
                    Plafond (FCFA)
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                        *ngFor="let item of currentPack.prestations">
                        {{item.plafond |number:'1.0-0':'fr'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                  <div class="title-div">
                    Fréquence
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                        *ngFor="let item of currentPack.prestations">
                        {{item.frequence}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="disposition">
                <div class="title-disp">
                  Dispositions complémentaires
                </div>
                <div class="desc-disp">
                  La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                  disposition de
                  l'article 13
                  <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                    générales
                    jointes, les conditions particulières ainsi que les closes en annexe.</div>
                  <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                    disposition des conditions générales (CG) qui leurs sont contraires.</div>
                  <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                    visas de
                    l'assureur et du souscripteur. </div>
                </div>
              </div>
            </div>
            <div class="footer-devis row">
              <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div class="fist-line">
                  <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                  conseil
                  d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                    number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                  <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                  code
                  des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                </div>
                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                  &nbsp;<b>{{currentAssureur.email}}</b> </div>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                <img class="footer-img" [src]="currentAssureur.img" alt="">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscription"
                [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </form>
  </div>
</div>
<div class="amsa-form-page">
</div>
<div *ngIf="!login">
  <section class="section1 ">
    <div class="row justify-content-center pres">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 photo-auto container">
        <h3 class="text-left title-ass" style="color: #204481;" @fade> Assurance santé</h3>
        <p class="mt-5 description" style="color: black;" @fade>
          Elle couvre la prise en charge des frais médicaux pharmaceutiques et d’hospitalisation à la suite d’une
          maladie.
          La dite maladie étant définie comme une altération de l’état de santé dûment constatée par une autorité
          médicale.
        </p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div class="row pack-row justify-content-center mt-3">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pack-col" @slideInOut (click)="choixmrh('pack')">
            <div class="box-part c-box-part p-0 c-boxPart2">
              <div (click)="selectFormule(assureurs[0], assureurs[0]?.assureur?.devis[0])" placement="top"
                ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave"
                popoverTitle="{{assureurs[0]?.assureur?.nom}}">
                <!-- <img class="heart" [src]="assureurs[0]?.assureur?.devis[0]?.formule?.image" alt=""> -->
                <img class="hear c-imageV" src="../../../../../assets/pack.jfif" alt="">
                <div class="c-title">
                  <h4 style="color: #2e8746 !important;" class="text-center title-form">PACK ASSURANCE
                  </h4>
                </div>
                </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 c-description">
                  <p>Des packs vous seront proposés</p>
                </div>
                <i class="fa fa-arrow-circle-right c-roww" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pack-col" @slideInOut (click)="choixmrh('simulation')">
            <div class="box-part c-box-part p-0 c-boxPart2">
              <div (click)="selectFormule(assureurs[0], assureurs[0]?.assureur?.devis[0])" placement="top"
                ngbPopover="Cliquer pour simuler" triggers="mouseenter:mouseleave">
                <img class="hear c-imageV" src="../../../../../assets/simulation.jfif" alt="">
                <div class="c-title">
                  <h4 style="color: #2e8746 !important;" class="text-center title-form">SIMULATION
                  </h4>
                </div>
               </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 c-description">
                  <p>Pour simuler un pack assurance</p>
                </div>
                <i class="fa fa-arrow-circle-right c-roww" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<ng-template #simulateurDevis let-modal>
  <div class="modal-body p-5 bg-white download" #santeDownload id="santeDownload">
    <div class="row">
      <div class="col-10 mesImage">
        <div class="imgLogo">
          <img [src]="listAssureurs[0].img" alt="">
        </div>
      </div>
      <div class="col-lg-2 text-right">
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Proposition Sante Gaye</div>
        <div class="sub-title">(Cette proposition est valable pour une année )
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Références du souscripteur
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('prenom').value}}
                {{souscriptionForm.get('assure').get('nom').value}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('email').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('adresse').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('profession').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span>Références Proposition</span>
          </div>
          <div class="contenu-div">
            <div class="row">

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type formule</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{dataSimulateur.typeFormule}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Formule</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{dataSimulateur.formule}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an(s)
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                Minuit
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adulte(s)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{checkAdulte}}</div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Enfant(s)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{checkEnfant}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Identification du produit </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{dataSimulateur.formule}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{dataSimulateur.typeFormule}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Service</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{dataSimulateur.service}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{dataSimulateur.age}} an(s)
                  </div>

                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Cout piéce</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{dataSimulateur.coutPiece | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Taxe</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.taxe | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime Nette</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.primeNette | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime TTC</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.primeTtc | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
        de <b> {{dataSimulateur.primeTtc | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Majoration De Prix </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" 
              *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                Personne agée
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="dataSimulateur.enfantSupp">
                Enfant(s) Supplementaire
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="dataSimulateur.adulteSupp">
                Adulte(s) Supplementaire
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Nombres
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" 
              *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                {{dataSimulateur.ageMajor}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" *ngIf="dataSimulateur.enfantSupp > 0">
                {{dataSimulateur.enfantSupp}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" *ngIf="dataSimulateur.adulteSupp > 0">
                {{dataSimulateur.adulteSupp}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Plafond (FCFA)
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" 
              *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                {{dataSimulateur?.majoration | number:'1.0-0':'fr'}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" *ngIf="dataSimulateur.enfantSupp > 0">
                {{dataSimulateur?.enfantSuppValTTc | number:'1.0-0':'fr'}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" *ngIf="dataSimulateur.adulteSupp > 0">
                {{dataSimulateur?.adulteSuppValTTc | number:'1.0-0':'fr'}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
          disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
            générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
            visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
          conseil
          d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
          <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
          code
          des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentAssureur.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="assureurSelected.img" alt="">
      </div>
    </div>

  </div>
  <div>
    <div class="row">
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" (click)="modal.close('close')"><i
            class="fa fa-chevron-left"></i></button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()" placement="top"
          ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave" popoverTitle="Devis">Télécharger <i
            class="fa fa-download"></i></button>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')" (click)="validerSiulateur1()">
          Souscrire <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentResultass let-modal>
  <div class="modal-body p-5 bg-white download" #santeDownload id="santeDownload">

    <div class="mesImage">
      <div class="imgLogo">
        <img [src]="currentAssureur.img" alt="">
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Proposition Sante</div>
        <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Références du souscripteur
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('prenom').value}}
                {{souscriptionForm.get('assure').get('nom').value}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('email').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('adresse').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('profession').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span>Références Proposition</span>
          </div>
          <div class="contenu-div">
            <div class="row">

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.firstName}}
                {{user.infos.lastName}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{souscriptionForm.get('contrat').get('debut').value |date:'dd/MM/yyyy'}}
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                Minuit
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.dureeDisplay}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                  <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte</div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{conjoint}}</div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                  <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant</div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{enfant}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Identification du produit </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentPack.formule}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Produit</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentPack.produit}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{ currentPack.age }} an(s)
                  </div>

                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prix</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentPack.prix |number:'1.0-0':'fr'}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Durée</div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.dureeDisplay}}
                  </div>
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="proposition2">
        <div class="pro-title"> Avantages </div>
      </div>
      <div class="row c-row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Description </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.avantages">
                {{item.nom}}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
        de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Nom </span>
            <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
              triggers="mouseenter:mouseleave" (click)="goTo(0)"></span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                {{item.nom}}
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Plafond (FCFA)
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                {{item.plafond |number:'1.0-0':'fr'}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Fréquence
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                {{item.frequence}}
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
          disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
            générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
            visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
          conseil
          d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
          <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
          code
          des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentAssureur.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentAssureur.img" alt="">
      </div>
    </div>

  </div>
  <div>
    <div class="row">
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left" (click)="modal.close('close')"><i
            class="fa fa-chevron-left"></i></button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-primary btn-tel" (click)="captureScreen()" placement="top"
          ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave" popoverTitle="Devis">Télécharger <i
            class="fa fa-download"></i></button>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-success btn-next c-btn-next pull-right" (click)="modal.close('subscribe')">
          Souscrire <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentResult let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Récapitulatif simulation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="elm-resul">
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Prime Auto
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.prime_auto | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Prime Habitation
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.prime_habitation | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Taxe
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.taxe | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          FGA
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.fga | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Cout police
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.cout_police | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Bonus
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.bonus | number}}</b>
        </div>
      </div>
      <div class="row row-res">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Prime RC
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.rcrti | number}}</b>
        </div>
      </div>
      <div class="row row-res last-line">
        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 title-res">
          Prime Totale
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 value">
          <b>{{result?.prime_total | number}}</b>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close')">Fermer</button>
    <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
  </div>
</ng-template>
<ng-template #contentResultlogin let-modal>
  <div class="modal-body login">
    <form [formGroup]="loginform">
      <div class="container px-4 py-5 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3">
                    <h3 class="monCompte"> Mon compte!</h3>
                    <p class="compteText"> Connectez vous à votre compte SVP.</p>
                  </div>
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="username" class="form__input" autocomplete="off" placeholder=" " formControlName="username"/>
                    <label for="username" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                 </div>
                 <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis
                  </div>
                  
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fas fa-lock form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password"/>
                    <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                    <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>           
                    <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>           
                  </div>  
                  <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis
                  </div>
                  <div class="forgetMdp">
                    <div class="resterConnecter"> 
                      <input type="checkbox">
                      <span> Restez connecter</span>
                    </div>
                    <div class="updateMdp">Mot de passe oublié?</div>
                  </div>
                  <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                  <div> <button class="seConnecter"
                      (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Connexion en cours
                    </p>
                  </div>
                </div>
              </div>
              <div class="bottom text-center mb-3">
                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2"
                    (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #contentResultsign let-modal>
  <div class="modal-body login">
    <form [formGroup]="inscriptionForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-2">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                  <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                  <div class="form-group"> <label class="form-control-label text-muted">Nom</label> <input type="text"
                      id="nom" placeholder="" class="form-control" formControlName="nom">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                      Le champs nom est requis</div>
                  </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Prénom </label> <input
                      type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                      Le champs prénom est requis</div>
                  </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                      type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                      Le champs email est requis</div>
                    <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est
                      incorrect</div>
                  </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone</label> <input
                      type="text" id="tel" placeholder="" class="form-control" formControlName="tel" prefix="+221 "
                      [mask]="phoneMask">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                      Le champs téléphone est requis</div>
                  </div>
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Inscription en cours
                    </p>
                  </div>
                </div>
              </div>
              <div class="bottom text-center mb-1">
                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2"
                    (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #contentResultforgot let-modal>
  <div class="modal-body login">
    <form [formGroup]="forgotForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                      type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                    <div class="text-red ml-3"
                      *ngIf="forgotForm.get('email')?.errors?.required && (forgotForm.get('email')?.touched || forgotForm.get('email')?.dirty)">
                      Le champs email est requis</div>
                    <div class="text-red ml-3" *ngIf="forgotForm.get('email')?.errors?.pattern">Le champs email est
                      incorrect</div>

                  </div>
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Réinitialisation en cours
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #prestation let-modal>
  <div class="modal-header c-modal-header">
    <h5 class="modal-title" id="modal-basic-title"> {{currentTitle}} </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body c-modal-body">
    <table class="table table-stripped c-pres-table" *ngIf="currentTitle=='Liste des prestations'">
      <thead>
        <tr>
          <th>Nature</th>
          <th>Taux de couverture</th>
          <th>Plafond(FCFA)</th>
          <th>Durée</th>
        </tr>
      </thead>
      <tbody *ngIf="prestations.length>0">
        <tr *ngFor="let item of prestations">
          <td> {{item.nom}} </td>
          <td> {{item.taux}} </td>
          <td> {{item.plafond | number:"1.0-0":"fr"}} </td>
          <td> {{item.frequence}} </td>
        </tr>
      </tbody>
      <div class="vide" *ngIf="prestations.length==0">Aucun prestataire pour ce pack</div>
    </table>
    <table class="table table-stripped c-pres-table" *ngIf="currentTitle=='Liste des avantages'">
      <thead>
        <tr>
          <th>Libellé</th>
        </tr>
      </thead>
      <tbody *ngIf="prestations.length>0">
        <tr *ngFor="let item of prestations">
          <td> {{item.nom}} </td>
        </tr>
      </tbody>
      <div class="vide" *ngIf="prestations.length==0">Aucun prestataire pour ce pack</div>
    </table>
  </div>
</ng-template>
<ng-template #prestataire let-modal>
  <div class="modal-header c-modal-header">
    <h4 class="modal-title" id="modal-basic-title">Liste des prestataires</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body c-modal-body">
    <table class="table table-stripped c-pres-table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Téléphone</th>
          <th>Adresse</th>
          <th>Lieu</th>
          <th>Spécialité</th>
        </tr>
      </thead>
      <tbody *ngIf="prestataires.length>0">
        <tr *ngFor="let item of prestataires">
          <td> {{item.nom}} </td>
          <td> {{item.telephone }} </td>
          <td> {{item.localite}} </td>
          <td> {{item.adresse}} </td>
          <td> {{item.specialite}} </td>
        </tr>
      </tbody>
      <div class="vide" *ngIf="prestataires.length==0">Aucun prestataire pour ce pack</div>
    </table>

  </div>
</ng-template>
<swal #confirmSubscription title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question"
  [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>
<swal #confirmSubscriptionSimulation title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question"
  [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveDataSimulation()">
</swal>
