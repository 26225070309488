
<!-- copyright section Start-->

<div class="copyright-area">
    <div class="container">
        <p>
            <i class="fa fa-copyright"></i> 
            <a href="https://www.sun-telecoms.com/#/home" target="_blank">2022 SUN-TELECOMS . Tous droits réservés</a>
        </p>
    </div>
</div>
<!-- copyright section end-->
<app-tap-to-top></app-tap-to-top>
