import { Component, OnInit } from "@angular/core";
import { NavService, Menu } from "../../../service/nav.service";
import { SubscriptionService } from "src/app/shared/service/subscription.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = "home";
  public active: boolean = false;
  public activeChildItem: string = "";
  public overlay: boolean = false;
  products: any = [];

  constructor(
    public navServices: NavService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.getProducts();
    });
  }

  getProducts() {
    this.subscriptionService.getProducts().subscribe((resp) => {
      this.products = resp["data"];
      console.log("products", this.products);
      this.menuItems.map((item) => {
        if (item.title == "Devis") {
          item.children = this.products.filter((p) => {
            p["path"] = "/devis/" + p.id;
            p["title"] = p.code;
            p["type"] = "link";
            return p;
          });
        }
        return item;
      });
      console.log("menuItems", this.menuItems);
    });
  }

  toggleSidebar() {
    this.openSide = !this.openSide;
  }

  closeOverlay() {
    this.openSide = false;
  }

  //For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = "";
    } else {
      this.activeItem = menuItem;
    }
  }

  isActive(item) {
    return this.activeItem === item;
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = "";
    } else {
      this.activeChildItem = subMenu;
    }
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu;
  }
}
