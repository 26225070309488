
<!-- videos section Start-->

<section class="app1 videos bg-theme">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="center-content">
                    <div class="video w-100">
                        <a class="button center-content">
                            <img src="assets/images/mobile-app.png"  alt="video" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-7 col-sm-12 counters video-description">
                <h3 class="m-b-30 text-white">Avec notre application mobile</h3>
                <div class="m-b-35">
                    <p class="p-light">Facilitez le suivi de vos souscription</p>

                    <p class="p-light">Vos déclarations de sinistressont pris en compte dans les plus bref délais.</p>

                    <p class="p-light">Renouveller facilement vos contrats</p>
                </div>
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a class="icon-btn">
                                <i class="fa fa-android center-content shadow" aria-hidden="true"></i>
                                <h6 class="text-center text-white">android</h6>
                            </a>
                        </li>
                        <li class="borders-right p-0"></li>
                        <li>
                            <a class="icon-btn">
                                <i class="fa fa-apple center-content shadow" aria-hidden="true"></i>
                                <h6 class="text-center text-white">ios</h6>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- videos section end-->
