import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {Location} from "@angular/common";

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  sinistreForm = new FormGroup({
        numeroPolice: new FormControl(null, [Validators.required]),
        date: new FormControl(null, [Validators.required]),
        lieu: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
  });
  today: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getDay()
  };
  alert = {
    type: '',
    message: ''
  };
  constructor(private subscriptionService: SubscriptionService, private location: Location) { }

  ngOnInit(): void {
  }

  saveData(){
    this.subscriptionService.registerSinistre(this.sinistreForm.value).subscribe((resp) =>{
        if(resp['responseCode'] == 200){
           this.alert = {
             type: 'success',
             message: 'Votre déclaration a été bien prise en compte.'
           };
           this.sinistreForm.reset();
           setTimeout(() => {
             this.alert.type = "";
           }, 3000);
        }else{
          this.alert = {
            type: 'danger',
            message: 'Votre déclaration n\'a été prise en compte. Veuillez réessayer'
          };
          setTimeout(() => {
            this.alert.type = "";
          }, 3000);
        }
    }, error => {
      this.alert = {
        type: 'danger',
        message: 'Votre déclaration n\'a été prise en compte. Veuillez réessayer'
      };
      setTimeout(() => {
        this.alert.type = "";
      }, 3000);
    });
  }

  onDateSelect(event){
    this.sinistreForm.get('date').setValue(new Date(event.year+'/'+event.month+'/'+event.day));
  }


}
