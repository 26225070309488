
<div class="container-gauche bg-dark">
    <div class="mask">
    </div>
    <h2 class="text-center main-title"><i>Devis Assurance vie</i></h2>
    <!-- <img  src="../../../../../assets/images/car-d.png" alt="" > -->
    <div class="decoration-droite bg-dark"></div>
    <div class="row container-row justify-content-center">
      <div class="col-12 droite">
        <form [formGroup]="souscriptionForm">
            <aw-wizard class="stepper-container pb-5" #wizard [navBarLocation]="'top'"
                       [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Packs">
                    <div class="form-container">
                        <!-- <div *ngFor="let ass of vieProduits" class="row assureur">
                            <div class="assureur-content w-100">
                                <div class="assureur-title"
                                     [class.selected]="souscriptionForm.get('assureur').value == ass?.id">
                                    {{ass?.nom}}
                                </div>
                                <div class="forfait-content row justify-content-center">
                                    <div *ngFor="let pack of ass?.produits" class="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                                        <div class="devis-content" *ngIf="pack.nom=='Prévoyance Etudes'">
                                            <div class="title-pack">
                                                <b>{{pack.nom}}</b>
                                            </div>
                                            <div class="img-section">
                                                <img class="w-100" src="assets/images/app_landing1/1-1.png" alt="">
                                            </div>
                                            <div class="pack-content text-left">
                                                 Prix: <b>12 000/ mois</b>
                                             </div>
                                            <hr>
                                            <div class="pack-description text-left">
                                                <h6>Couvertures</h6>
                                                <p>La Responsabilité Civile de l’établissement ;
                                                    Les risques majeurs auxquels l’étudiant est exposé durant sa la vie estudiantine et son séjour dans l’établissement : cours, stages en entreprise, activités sportives, sorties, etc.</p>
                                                <h6>Avantages</h6>
                                                <p>Décès accidentel : versement d’un capital de 300.000 FCFA à sa famille ;
                                                    Invalidité définitive : versement à l’assuré d’un capital maximum de 500.000 FCFA ;
                                                    Hospitalisation : suite à une maladie grave ou un accident (jusqu’à 300.000 FCFA)</p>
                                            </div>
                                            <div class="buttons">
                                                <button type="button" class="devis-btn btn btn-sm" name="button"
                                                        (click)="selectFormule(ass, pack)">Choisir
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="container" >
                            <ul class="nav nav-tabs" role="tablist" *ngFor="let ass of vieProduits">
                              <li class="nav-item" style="background:hsl(218, 60%, 32%)">
                                <a class="nav-link active" data-toggle="tab" href="{{ass?.assureur?.nom}}">{{ass?.nom}}</a>
                              </li>
                            </ul>
                            <div class="tab-content" *ngFor="let ass of vieProduits">
                              <div id="{{ass?.nom}}" class="container tab-pane active"><br>
                                <!-- <h3>{{ass?.assureur?.nom}}</h3> -->
                                <div class="row pack-row" >
                                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col" *ngFor="let pack of ass?.produits">
                               
                                    <div class="box-part text-center" (click)="selectFormule(ass, pack)" placement="left"
                                    ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave" popoverTitle="{{ass?.assureur?.nom}}">
                                        
                                        <!-- <i class="fa fa-twitter fa-3x" aria-hidden="true"></i> -->
                                        <img class="heart" src="assets/images/heart.png" alt="">   
                                      <div class="title">
                                        <h4 style="color: #2e8746 !important;">{{pack?.nom}}</h4>
                                      </div>
                                                  
                                      <div class="text">
                                        <h6>Couvertures</h6>
                                                <p>La Responsabilité Civile de l’établissement ;
                                                    Les risques majeurs auxquels l’étudiant est exposé durant sa la vie estudiantine et son séjour dans l’établissement : cours, stages en entreprise, activités sportives, sorties, etc.</p>
                                                <h6>Avantages</h6>
                                                <p>Décès accidentel : versement d’un capital de 300.000 FCFA à sa famille ;
                                                    Invalidité définitive : versement à l’assuré d’un capital maximum de 500.000 FCFA ;
                                                    Hospitalisation : suite à une maladie grave ou un accident (jusqu’à 300.000 FCFA)</p>
                                        <!-- <span>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span> -->
                                      </div>
                                                  
                                      Prix: <b>12 000/ mois</b>
                                                  
                                     </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                    <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('duree').invalid"  awNextStep>Suivant <i class="fa fa-angle-double-right"></i></button> -->
                </aw-wizard-step>
    
                <!-- Simulation -->
                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Simulateur">
                    <div class="form-container" formGroupName="simulation">
                        <!-- <div class="row no-margin second-title">
                            <h2 class="mb-4 mx-auto">Simulateur de prime</h2>
                        </div> -->
                        <div class="row assurance-for">
                            
                            <div class="radio-toolbar col-12">
                                <div class="test">
                                   <input checked id="capital" name="radioFruit" type="radio" value="1">
                                   <label (click)="setType('capital')" for="capital" class="gauche">La prime annuelle</label>
                                   <input id="prime" name="radioFruit" type="radio" value="2">
                                   <label (click)="setType('prime')" for="prime" class="droite">Le capital</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez selectionner le date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Naissance">
                                        <div class="input-group-append">
                                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                        </div>
                                            <!-- <label for="age">Date de naissance <span class="text-red">*</span></label> -->
                                           <input #ag="ngbDatepicker" (click)="ag.toggle()"
                                           (dateSelect)="onDateSelect($event, 'simulation','age')" [maxDate]="today"
                                           [minDate]="{year: 1900, month: 1, day: 1}"
                                           class="form-control" id="age"
                                           ngbDatepicker type="text" placeholder="Date de naissance">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                 <div class="input-group" placement="left"
                                 ngbPopover="Veuillez renseigner la duree" triggers="mouseenter:mouseleave" popoverTitle="Duree">
                                        <div class="input-group-append">
                                        <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="duree">Durée du contrat <span class="text-red">*</span></label> -->
                                    <input class="form-control" formControlName="duree" id="duree" type="number" placeholder="Durée du contrat" placeholder="Durée">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le montant" triggers="mouseenter:mouseleave" popoverTitle="Montant">
                                        <div class="input-group-append">
                                        <i class="fa fa-money text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="montant">Montant {{getType()}}<span class="text-red">*</span></label> -->
                                    <input class="form-control" formControlName="montant" id="montant"
                                           type="text" placeholder="Montant {{getType()}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center" *ngIf="simulationVie">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <label for="montantResult{{type}}">Montant {{resultType}}<span class="text-red">*</span></label>
                                    <input class="form-control" mask="separator" formControlName="montantResult{{type}}" id="montantResult{{type}}" [value]="souscriptionForm.get('simulation').get('montantResult'+type).value"
                                           type="text">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                        <label for="email">Sexe</label>
                        <select class="form-control"  formControlName="genre">
                        <option value="">Sélectionner une option</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                    </div>
                  </div> -->
                    
                    
                    </div>
                
                <!-- <div class="row forfait-content">
                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-12">
                        <button awPreviousStep class="btn btn-warning btn-prev pull-left" type="button"><i
                                class="fa fa-chevron-left"></i></button>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-12">
                        <button (click)="simuleVie()"
                                class="btn btn-success btn-simuler"
                                [disabled]="souscriptionForm.get('simulation').invalid"
                                type="button">Simuler
                        </button>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-12">
                        <button [disabled]="souscriptionForm.get('simulation').invalid" awNextStep
                                class="btn btn-success btn-next pull-right" type="button">Suivant
                            <i class="fa fa-angle-double-right"></i></button>
                    </div>
                </div> -->
                    <!-- <div class="row justify-content-center mt-4" *ngIf="simulationVie">
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form-group">
                                        <label for="montantResult">Montant {{resultType}}<span class="text-red">*</span></label>
                                        <input class="form-control" mask="separator" formControlName="montantResult" id="montantResult" [value]="type == 'prime' ? simulationVie.capitalGaranti : simulationVie.primeAnnuelle"
                                               type="text">
                                    </div>
                                </div>
                    </div> -->
                    <div class="row forfait-content justify-content-center">
                        <div class="col-lg-8 col-md-8 col-xs-6 col-sm-12">
                            <button (click)="simuleVie()"
                                    class="btn btn-large btn-success btn-simuler"
                                    [disabled]="souscriptionForm.get('simulation').invalid"
                                    type="button">Simuler
                            </button>
                        </div>
                        <div class="col-lg-6 col-md-4 col-xs-6 col-sm-12">
                            <button awPreviousStep class="btn btn-warning btn-prev pull-left" type="button"><i
                                    class="fa fa-chevron-left"></i></button>
                        </div>
                        <div class="col-lg-6 col-md-4 col-xs-6 col-sm-12">
                            <button [disabled]="souscriptionForm.get('simulation').invalid" awNextStep
                                    class="btn btn-success btn-next pull-right" type="button">Suivant
                                <i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
               
         
                </aw-wizard-step>
    
                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Souscripteur">
                    <div class="form-container" formGroupName="souscripteur">
                        
                        <div class="row assurance-for">
                            <!-- <h6 class="col-12">Je souscris </h6> -->
                            <div class="radio-toolbar col-12">
                                <input type="radio" id="moi" name="radioFruit" value="1" checked>
                                <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                                <input type="radio" id="tiers" name="radioFruit" value="2">
                                <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
    
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le prénom" triggers="mouseenter:mouseleave" popoverTitle="prénom">
                                        <div class="input-group-append">
                                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                                    <input id="prenom" type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le nom" triggers="mouseenter:mouseleave" popoverTitle="Nom">
                                        <div class="input-group-append">
                                        <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                                    <input id="nom" type="text" class="form-control" formControlName="nom" placeholder="nom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le numéro de téléphone" triggers="mouseenter:mouseleave" popoverTitle="Téléphone">
                                        <div class="input-group-append">
                                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numero">Numéro téléphone <span class="text-red">*</span></label> -->
                                    <input id="numero" type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                                           formControlName="telephone" placeholder="numero telephone">
                                        </div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le numéro Carte d'identité" triggers="mouseenter:mouseleave" popoverTitle="numéro Carte d'identité">
                                        <div class="input-group-append">
                                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numeroCni">Numéro CNI <span class="text-red">*</span></label> -->
                                    <input id="numeroCni" type="text" class="form-control" [mask]="cinMask"
                                           formControlName="numeroCni" placeholder="Numéro Carte d'identité">  
                                        </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group"placement="left"
                                    ngbPopover="Veuillez renseigner l'email" triggers="mouseenter:mouseleave" popoverTitle="Email">
                                        <div class="input-group-append">
                                        <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="email">Email <span class="text-red">*</span></label> -->
                                    <input id="email" type="email" class="form-control" formControlName="email" placeholder="email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner la date de naissance" triggers="mouseenter:mouseleave" popoverTitle="date de naissance">
                                        <div class="input-group-append">
                                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="dateNaissance">Date de naissance <span class="text-red">*</span></label> -->
                                    <input id="dateNaissance" type="text" class="form-control" [maxDate]="today"
                                           [minDate]="{year: 1900, month: 1, day: 1}"
                                           (dateSelect)="onDateSelect($event, 'souscripteur','dateNaissance')" ngbDatepicker
                                           #d="ngbDatepicker" (click)="d.toggle()" placeholder="date de naissance">
                                </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form-group">
                            <label for="email">Sexe</label>
                            <select class="form-control"  formControlName="genre">
                            <option value="">Sélectionner une option</option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                          </select>
                        </div>
                      </div> -->
                        </div>
                    </div>
                    <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep><i
                            class="fa fa-chevron-left"></i></button>
                    <button type="button" class="btn btn-success btn-next pull-right"
                            [disabled]="sendingRequest || souscriptionForm.get('souscripteur').invalid" awNextStep>Suivant
                        <i class="fa fa-angle-double-right"></i></button>
                </aw-wizard-step>
    
                <aw-wizard-step (stepEnter)="enterStep($event)" [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                    <div class="form-container" formGroupName="assure">
                        <!-- <div class="row no-margin second-title">
                            <h2 class="mb-4 mx-auto">Informations assuré</h2>
                        </div> -->
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le prénom" triggers="mouseenter:mouseleave" popoverTitle="prénom">
                                        <div class="input-group-append">
                                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                                    <input id="prenom" type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le nom" triggers="mouseenter:mouseleave" popoverTitle="Nom">
                                        <div class="input-group-append">
                                        <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                                    <input id="nom" type="text" class="form-control" formControlName="nom" placeholder="nom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le téléphone" triggers="mouseenter:mouseleave" popoverTitle="Téléphone">
                                        <div class="input-group-append">
                                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numero">Numéro téléphone <span class="text-red">*</span></label> -->
                                    <input id="numero" type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                                           formControlName="numeroTelephone" placeholder="numero telephone">
                                        </div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le numéro Carte d'identité" triggers="mouseenter:mouseleave" popoverTitle="numéro Carte d'identité">
                                        <div class="input-group-append">
                                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numeroCni">Numéro CNI <span class="text-red">*</span></label> -->
                                    <input id="numeroCni" type="text" class="form-control" [mask]="cinMask"
                                           formControlName="numeroCni" placeholder="Numéro Carte d'identité">  
                                        </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner l'adresse" triggers="mouseenter:mouseleave" popoverTitle="Adresse">
                                        <div class="input-group-append">
                                        <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="review">Adresse <span class="text-red">*</span></label> -->
                                    <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                                </div>
                            </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner l'email" triggers="mouseenter:mouseleave" popoverTitle="Email">
                                        <div class="input-group-append">
                                        <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="email">Email <span class="text-red">*</span></label> -->
                                    <input id="email" type="email" class="form-control" formControlName="email" placeholder="email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="input-group" placement="left"
                                        ngbPopover="Veuillez selectionner la latéralité" triggers="mouseenter:mouseleave" popoverTitle="Latéralité">
                                            <div class="input-group-append">
                                            <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                            </div>
                                    <!-- <label for="review">Latéralité <span class="text-red">*</span></label> -->
                                    <select class="form-control" name="lateralite" formControlName="lateralite">
                                        <option [value]="null"  selected disabled>Latéralité</option>
                                        <option value="droitier">Droitier</option>
                                        <option value="gaucher">Gaucher</option>
                                    </select>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="input-group" placement="left"
                                        ngbPopover="Veuillez renseigner la date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                                            <div class="input-group-append">
                                            <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                            </div>
                                    <!-- <label for="email">Date de naissance <span class="text-red">*</span></label> -->
                                    <input type="text" class="form-control" [maxDate]="today"
                                           [minDate]="{year: 1900, month: 1, day: 1}"
                                           (dateSelect)="onDateSelect($event, 'assure','dateNaissance')" ngbDatepicker
                                           #dateAss="ngbDatepicker" (click)="dateAss.toggle()" placeholder="Date de naissance" value="{{date}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez selectionner le genre" triggers="mouseenter:mouseleave" popoverTitle="Genre">
                                        <div class="input-group-append">
                                            <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                            </div>
                                    <!-- <label for="email">Sexe <span class="text-red">*</span></label> -->
                                    <select class="form-control" formControlName="genre">
                                        <option [value]="null"  selected disabled>Genre</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep><i
                            class="fa fa-chevron-left"></i></button>
                    <button type="button" class="btn btn-success btn-next pull-right"
                            [disabled]="sendingRequest || souscriptionForm.get('assure').invalid" awNextStep>Suivant
                    </button>
                </aw-wizard-step>
    
                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Bénéficiaire">
                    <div class="no-margin row justify-content-center mb-4" *ngIf="typeAssurance != 'voyage'">
                        <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <label class="badge-btn" [class.active]="beneficiaryChoosen" name="button"
                                   (click)="beneficiaryChoosen = true"><i class="fa fa-plus"></i> Ajouter un
                                </label>
                        </div> -->
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <label class="badge-btn " name="button" [class.active]="!beneficiaryChoosen"
                                   (click)="beneficiaryChoosen = false"> <i class="fa fa-users"></i> Mes héritiers seront
                                mes bénéficiaires</label>
                        </div>
                    </div>
                    <div class="row beneficiary-section form-container mt-4" formGroupName="beneficiaire"
                         *ngIf="beneficiaryChoosen">
                        <!-- <div class="col-12">
                            <h4>Informations sur le bénéficiaire</h4>
                        </div> -->
                        <div class="row no-margin mt-4">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le prénom" triggers="mouseenter:mouseleave" popoverTitle="Prénom">
                                        <div class="input-group-append">
                                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                                    <input id="prenom" type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le nom" triggers="mouseenter:mouseleave" popoverTitle="Nom">
                                        <div class="input-group-append">
                                        <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                                    <input id="nom" type="text" class="form-control" formControlName="nom" placeholder="nom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le CIN" triggers="mouseenter:mouseleave" popoverTitle="CNI">
                                        <div class="input-group-append">
                                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numero">Numéro téléphone <span class="text-red">*</span></label> -->
                                    <input id="numero" type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                                           formControlName="telephone" placeholder="numero telephone">
                                        </div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le numéro Carte d'identité" triggers="mouseenter:mouseleave" popoverTitle="numéro Carte d'identité">
                                        <div class="input-group-append">
                                        <i class="fa fa-vcard text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="numeroCni">Numéro CNI <span class="text-red">*</span></label> -->
                                    <input id="numeroCni" type="text" class="form-control" [mask]="cinMask"
                                           formControlName="numeroCni" placeholder="Numéro Carte d'identité">  
                                        </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner l'adresse" triggers="mouseenter:mouseleave" popoverTitle="Adresse">
                                        <div class="input-group-append">
                                        <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="review">Adresse <span class="text-red">*</span></label> -->
                                    <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                                </div>
                            </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="input-group" placement="left"
                                        ngbPopover="Veuillez renseigner la date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                            <div class="input-group-append">
                                            <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                            </div>
                                    <!-- <label for="email">Date de naissance <span class="text-red">*</span></label> -->
                                    <input type="text" class="form-control" [maxDate]="today"
                                           [minDate]="{year: 1900, month: 1, day: 1}"
                                           (dateSelect)="onDateSelect($event, 'assure','dateNaissance')" ngbDatepicker
                                           #dateAss="ngbDatepicker" (click)="dateAss.toggle()" placeholder="Date de naissance">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <div class="input-group" placement="left"
                                    ngbPopover="Veuillez renseigner le genre" triggers="mouseenter:mouseleave" popoverTitle="genre">
                                        <div class="input-group-append">
                                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                    <!-- <label for="email">Sexe</label> -->
                                    <select class="form-control" formControlName="genre">
                                        <option [value]="null"  selected disabled>Genre</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 justify-content-center">
                        <!-- <h6 class="col-12">Choisir un moyen de paiement</h6> -->
                        <div class="badge-content" [ngSwitch]="pay?.nom | lowercase" *ngFor="let pay of paymentMethods"
                             (click)="selectPayment(pay)">
                            <div class="w-100 img-section text-center">
                                <img src="assets/images/wallet.png" class="payment-method-img" alt=""
                                     *ngSwitchCase="'wallet'">
                                <img src="assets/images/credit-card.png" class="payment-method-img" alt=""
                                     *ngSwitchCase="'carte'">
                                <img src="assets/images/card.png" class="payment-method-img" alt="" *ngSwitchDefault>
                            </div>
                            <div class="badge" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
                                {{pay?.nom}}
                            </div>
                        </div>
                    </div>
    
                    <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep><i
                            class="fa fa-chevron-left"></i></button>
                    <button type="button" class="btn btn-success btn-next pull-right" [swal]="confirmSubscription"
                            [disabled]="sendingRequest" awNextStep><i class="fa fa-spinner fa-pulse"
                                                                      *ngIf="sendingRequest"></i>Terminer
                    </button>
    
                </aw-wizard-step>
            </aw-wizard>
        </form>
      </div>
    </div>
 
</div>
<div class="amsa-form-page">
     
</div>
<swal
    #confirmSubscription
    title="Confirmation"
    text="Voulez-vous vraiment proceder à la souscription ?"
    icon="question"
    [showCancelButton]="true"
    [focusCancel]="false"
    [confirmButtonText]="'OUI, BIEN SURE'"
    [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
