import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enterprice-services',
  templateUrl: './enterprice-services.component.html',
  styleUrls: ['./enterprice-services.component.scss']
})
export class enterpriceServicesComponent implements OnInit {
  name = environment.appName;
  public config: SwiperConfigInterface = {
        // effect: 'coverflow',
        // loop: true,
        // centeredSlides: true,
         slidesPerView: 4,
        // keyboard: true,
        // mousewheel: false,
         preventClicks: false,
        // preventClicksPropagation: false,
         spaceBetween: 40,
        // coverflowEffect: {
        //     rotate: 0,
        //     stretch: 0,
        //     depth: 100,
        //     modifier: 1.50,
        //     slideShadows: false
        // },
        // breakpoints: {
        //     1199: {
        //         slidesPerView: 3,
        //
        //     },
        //     991: {
        //         slidesPerView: 3
        //     },
        //     767: {
        //         slidesPerView: 2
        //     },
        //     575: {
        //         slidesPerView: 2
        //     }
        // },
        autoplay: {
            delay: 5500,
            disableOnInteraction: false
        },
        // pagination: {
        //     el: '.swiper-pagination'
        // }
  };
  insurances: any = [];
  constructor(private serviceData: SubscriptionService) { }

  ngOnInit() {
    this.getInsurances();
  }

  getInsurances(){
    this.serviceData.mockedInsurances().subscribe((resp) => {
      this.insurances = resp;
    })
  }
  redirection(url: string){
    window.open(url, '_blank');
  }

  services = [
    // {
    //   img: "assets/images/app_landing1/icons/1-1.png",
    //   title: "Garantie complète",
    //   description: ""
    // },
    // {
    //   img: "assets/images/app_landing1/icons/1-2.png",
    //   title: "Remboursement rapide",
    //   description: "Un capital en cas de Décès ou d’Infirmité."
    // },
    // {
    //   img: "assets/images/app_landing1/icons/1-3.png",
    //   title: "Assistance 24/7",
    //   description: "chirurgicaux, pharmaceutiques, d’hospitalisation et de transport"
    // },
  ]

}
