<!-- pricing section Start-->

<section class="app1 pricing mb-0" id="plan">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="title title1">
                    <div class="main-title">
                        <h2 class="title">Ce que nos clients disent</h2>
                    </div>
                    <div class="sub-title">
                        <p class="para">Innove en créant des services personnalisés à des coûts optimisés. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 margbt">
                <owl-carousel-o [options]="owlcarouselOptions" class="pricing-slider price-margin">
                    <ng-container *ngFor="let slide of owlcarousel1">
                        <ng-template carouselSlide class="item">
                        <div class="price-container text-center">
                          <div class="img-section">
                              <img [src]="slide.img" alt="" class="img-fluid testimonial-img">
                          </div>
                            <div class="set-relative">
                              <div class="name-text">
                                {{slide?.name || "Anonymat"}}
                              </div>
                              <div class="poste-texte">
                                {{slide?.poste || "Directeur Marketing SUN TELECOMS"}}
                              </div>
                              <div class="title-text">
                                {{slide?.title}}
                              </div>
                              <div class="content-text">
                                {{slide.description}}
                              </div>

                            </div>
                        </div>
                      </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<!-- pricing section end-->
