import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from './shared/service/subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Micro Assurance';
  token: any;
  tokenid: any;

  constructor(private subscriptionService: SubscriptionService){

  }

  ngOnInit(){
    // this.subscriptionService.login2().subscribe((resp) =>{
    //   if(resp['data'])
    //     localStorage.setItem('access_token_ma', resp['data']['access_token']);
    // });
    // this.token =  JSON.parse(localStorage.getItem('microFinance'));
    // if(this.token)
    //   this.tokenid= this.token.id_token
    // console.log('token',this.token)
  };
}
