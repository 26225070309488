<!-- screenshot section Start-->

 <!-- Start Blog Area -->
 <section class="blog-area pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <!-- <span>Quoi de neuf ?</span> -->
            <h2>Actualités</h2>
            <p>Toute l'actualité du monde monetique ici et ailleurs</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a href="#">
                            <img src="assets/img/blog/image1.jpg" alt="image">
                        </a>

                        <a href="#" class="date">#DATE</a>
                    </div>

                    <div class="content">
                        <h3 class="color-text">
                            Optima Sénégal voit le jour
                        </h3>

                        <a href="#" class="blog-btn">voir +</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a href="#">
                            <img src="assets/img/blog/image2.jpg" alt="image">
                        </a>

                        <!-- <a href="#" class="date">20 April, 2020</a> -->
                        <a href="#" class="date">{{toDay}}</a>
                    </div>

                    <div class="content">
                        <h3 class="color-text">
                            Le transfert d'argent moderne le plus utilisé
                        </h3>

                        <a href="#" class="blog-btn">Voir +</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a href="#">
                            <img src="assets/img/blog/image3.jpg" alt="image">
                        </a>

                        <a href="#" class="date">{{toDay}}</a>
                    </div>

                    <div class="content">
                        <h3 class="color-text">
                            Sénégal encore une aubaine pour le digital
                        </h3>

                        <a href="#" class="blog-btn">Voir +</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->
<!-- screenshot section end-->
