import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GenererPdfService} from "../shared/service/generer-pdf.service";

@Component({
  selector: 'app-generer-pdf',
  templateUrl: './generer-pdf.component.html',
  styleUrls: ['./generer-pdf.component.scss']
})
export class GenererPDFComponent implements OnInit {
  uuid: any;
  uuidSante: any;
  uuidAuto: any;

  constructor(private router: ActivatedRoute, private genererPdfService: GenererPdfService, private _router: Router) {
    let uuId;
    console.log("Router",this.router.snapshot.routeConfig.path);
    this.uuid = this.router.snapshot.params['id'];
    this.uuidSante = this.router.snapshot.params['idSante'];
    this.uuidAuto = this.router.snapshot.params['idAuto'];
    if (this.router.snapshot.routeConfig.path && this.router.snapshot.routeConfig.path == 'generer-pdf/:id'){
      if(this.uuid){
        uuId = this.uuid;
        this._router.navigate(['/devis/6'], {queryParams: {data: uuId}});
        console.log("UUID", this.uuid);
      }
    } else if(this.router.snapshot.routeConfig.path && this.router.snapshot.routeConfig.path == 'generer-pdfSante/:idSante'){
     console.log("UUID", this.uuid)
     console.log("UUIDSante", this.uuidSante)
      if(this.uuidSante){
        uuId = this.uuidSante;
        this._router.navigate(['/devis/7'], {queryParams: {data: uuId}});
        console.log("UUID", this.uuid);
      }
    } else if(this.router.snapshot.routeConfig.path && this.router.snapshot.routeConfig.path == 'generer-pdfAuto/:idAuto'){
     console.log("UUID", this.uuid)
     console.log("UUIDAuto", this.uuidSante)
      if(this.uuidAuto){
        uuId = this.uuidAuto;
        this._router.navigate(['/devis/5'], {queryParams: {data: uuId}});
        console.log("UUID", this.uuid);
      }
    }
  }

  ngOnInit(): void {
  }

}
