import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';

@Component({
  selector: 'app-enterprice-pricing',
  templateUrl: './enterprice-pricing.component.html',
  styleUrls: ['./enterprice-pricing.component.scss']
})
export class enterpricePricingComponent implements OnInit {
  owlcarousel1: any = [];

  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.subscriptionService.mockedTestimonials().subscribe((resp) => {
       this.owlcarousel1 = resp;
       console.log('this.owlcarousel1 :',this.owlcarousel1);
       
    })
  }


  owlcarouselOptions= {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: true,
    slideSpeed: 500,
    paginationSpeed: 400,
    grabCursor: true,
    loop: true,
    dots: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        600:{
            items: 2,
            margin: 10
        },
        992: {
            items: 3
        }
    }
  }

}
