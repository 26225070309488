import * as jsPDF from 'jspdf'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {ActivatedRoute,Router} from "@angular/router";
import { AccountService } from 'src/app/shared/service/account.service';
import { from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
@Component({
  selector: 'apg-success',
  templateUrl: './apg-success.component.html',
  styleUrls: ['./apg-success.component.scss','./demo.css','./style.css']
})
export class ApgSuccessComponent implements OnInit {
  parameterValue: any;
  @ViewChild('simulateurDevis') simulateurDevis: any;
  @ViewChild('santeDownload') santeDownload: ElementRef;

  @ViewChild('contentResultass') contentResultass: any;
  @ViewChild('mrhDownload') mrhDownload: ElementRef;

  @ViewChild('contentAuto') contentAuto: any;
  @ViewChild('autoDownload') autoDownload: ElementRef;

  uid: any;
  route: any;
  currentQuittance:any;
  user: any;
  datas: any;
  status = 1;
  resultSimulation = [];
  primerc: any;
  primeAss = [];
  garantieAss = [];
  tot: any;

    constructor(
    private subscriptionService: SubscriptionService,
    private accountService:AccountService,
    private _activatedRoute: ActivatedRoute,
    private _router:Router,
    private modalService: NgbModal,
    ) { 
      this.user = JSON.parse(localStorage.getItem('microFinance'));
      // this.datas = this._activatedRoute.snapshot.params['data'];
      this._activatedRoute.queryParams.subscribe(params => {
        this.datas = params;
        console.log('params :',params);
      });
      this.getSouscription(this.datas.id);
    }

  ngOnInit(): void {   
     //@ts-ignore
     this.route=''+this._activatedRoute.queryParams.value.type;
     //@ts-ignore
     this.uid=this._activatedRoute.queryParams.value.id;  
  }
  aller()
  { 
    // console.log(this.route)
    this.accountService.activate(this.uid).subscribe((response)=>{
         
    },(error)=>{

    })
    // console.log(this.route)
    this._router.navigate(['devis/'+this.route]);
  }
  continuer(){
    this._router.navigate(['enterprice']);
  }
  getImageAssureur() {
    if (this.currentQuittance['assureur']['code'] == 'NSIA') this.currentQuittance['img'] = "../../../../../assets/images/nsia.png";
    else if (this.currentQuittance['assureur']['code'] == 'AXA') this.currentQuittance['img'] = "../../../../../assets/images/axa.png";
    else if (this.currentQuittance['assureur']['code'] == 'AMSA') this.currentQuittance['img'] = "../../../../../assets/images/amsa.png";
    else if (this.currentQuittance['assureur']['code'] == 'SALAMA') this.currentQuittance['img'] = "../../../../../assets/images/salama.png";
        // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
    // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
    else this.currentQuittance['img'] = "../../../../../assets/images/assurance.jpg";
  }
  openModal(resultModal){
    console.log('resultModal',resultModal);
    
    // let type: any = {
    //   "AUTO": '',
    //   "SANTE": this.simulateurDevis,
    //   "MRH": this.contentResultass
    // }
    if(resultModal === 'MRH')
      resultModal = this.contentResultass;
    else if(resultModal === 'SANTE')
      resultModal = this.simulateurDevis;
    else if(resultModal === 'AUTO')
      resultModal = this.contentAuto;

    console.log("type['resultModal']",resultModal);
    
    //this.modalService.dismissAll(this.modasign); 
    this.modalService.open(resultModal, {
      ariaLabelledBy: 'modal-basic-title',
      size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {
              window.scrollTo(500, 500);
          }
      }, (reason) => {
          console.log('reason', reason);
      });
  }
  captureScreen(docId, filetype: string): void {
    const data = document.getElementById(docId);
    html2canvas(data, {backgroundColor: 'white', removeContainer: true, scrollX: 0, scrollY: 0}).then(canvas => {
        var imgWidth = 210;
        var pageHeight = 395;
        const contentDataURL = canvas.toDataURL('application/pdf')
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        // var doc = new jsPDF('p', 'mm', 'a4');
        let doc = new jsPDF('p', 'mm', 'a4');
        var position = 0;
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        doc.save('Devis_'+this.datas.produit+'.pdf');
        if (this.datas) {
            this.modalService.dismissAll();
        }
    });
  }
  getSouscription(id){
    this.subscriptionService.getSouscription(id)
      .subscribe(resp => {
        this.currentQuittance = resp['data'];
          this.resultSimulation = this.currentQuittance.prime;
          console.log("Prime ", this.currentQuittance.prime)
          // if()
        let expireDate = new Date(this.currentQuittance['date']);
          if(this.currentQuittance['formule']) {
              this.currentQuittance['avantages'] = this.convertStringToArray(this.currentQuittance.formule.description)
              this.currentQuittance['prestations'] = this.currentQuittance.produit.prestations
          }
        this.currentQuittance['dateExpiration'] = 
                new Date((expireDate.getFullYear()+1)+'/'+(expireDate.getMonth()+1)+'/'+expireDate.getDate())
          this.resultSimulation.forEach(element => {
              if (element.key == 'Prime RC') {
                  this.primerc = element.value;
                  this.primeAss.push(element);
              }
              if (element.key == 'Prime Totale TTC') {
                  // this.tot = this.primerc + element.value;
                  this.tot = element.value;
                  element.value = this.tot;
              }
              if(element.key!='Prime nette' && element.key!='Cout de police' && element.key!='Taxe' && element.key!='FGA'&& element.key!='Prime Totale TTC' )
                  this.garantieAss.push(element);
              else this.primeAss.push(element);

          });

          this.getImageAssureur();
      },err=>{
        console.log('err :',err);
      })
  }

    generatePdfAttestation() {
        var data = document.getElementById('attestation');
        html2canvas(data).then(canvas => {
            // Few necessary setting options
            const contentDataURL = canvas.toDataURL('image/png');
            let pdf = new jsPDF('l', 'mm'); // A4 size page of PDF
            var imgWidth = pdf.internal.pageSize.getWidth();
            var pageHeight = 380;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save('Attestation.pdf'); // Generated PDF
        });
    }

    generatePdfCarteBrune() {
        var data = document.getElementById('carteBrune');
        html2canvas(data).then(canvas => {
            // Few necessary setting options
            //var imgWidth = 210;
            const contentDataURL = canvas.toDataURL('image/png');
            let pdf = new jsPDF('l', 'mm'); // A4 size page of PDF
            var imgWidth = pdf.internal.pageSize.getWidth();
            var pageHeight = 380;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save('carteBrune.pdf'); // Generated PDF
        });
    }

    convertStringToArray(data) {
        let value = data.split('->');
        let values = [];
        for (let val of value) {
            if (val != "") {
                val.replace('\n', '');
                values.push({ nom: val });
            }
        }
        return values;
    }


}
