import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from "@angular/router";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/service/utils.service';

@Component({
  selector: 'app-reset_password',
  templateUrl: './reset_password.component.html',
  styleUrls: ['./reset_password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  forgotForm= new FormGroup({
    confirmPassword: new FormControl(null, [Validators.required]),
    token: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required]),
  });
  invalid:boolean=false;
  loaderVisible: boolean=false;
  password: boolean=false;
  reinitialisé: boolean;
  reinitialise: boolean;
  constructor(private subscriptionService: SubscriptionService,private utilsService: UtilsService,) { }

  ngOnInit(): void {
   
  }
  nouveaupassword(){
    if(this.forgotForm.valid && !this.password){
    let data ={
    
      ...this.forgotForm.value,

      }

      this.loaderVisible = true;
      this.subscriptionService.reinitialiser(data).subscribe((resp)=>{
        if(resp["responseCode"]=200){
          this.utilsService.showToastSuccess("Votre mot de passe à été réinitialisé avec succés","Succés");  
          this.reinitialise=true;   
        }
        else{
          this.utilsService.showToastError(resp['message'], "Echec");
        }
        this.loaderVisible = false;
      }, error => {
        let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
        if(error && error.error){
          message = error.error.message;
        }
        this.utilsService.showToastError(message, "Echec");
  
        this.loaderVisible = false;
      })
  }
  }
  changer(){
    if(this.forgotForm.get('confirmPassword').value==this.forgotForm.get('newPassword').value){
        this.password=true;
    }else{
        this.password=false;
    }
  }
}
