import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { exit } from 'process';

@Component({
  selector: 'app-enterprice-screenshots',
  templateUrl: './enterprice-screenshots.component.html',
  styleUrls: ['./enterprice-screenshots.component.scss']
})
export class enterpriceScreenshotsComponent implements OnInit {
  @ViewChild('contentResult', {static: false}) contentResult: any;
  valid:any ;
  declarersinistreForm = new FormGroup({
    lieu: new FormControl(null, [Validators.required]),
    numeroPolice: new FormControl(null ,[Validators.required, Validators.min(1), Validators.max(25)]),
    date: new FormControl(null ,[Validators.required]),
    description: new FormControl(null ,[Validators.required]),
    
  });
  toDay: any = new Date()+'';
  // utilsService: any;
  constructor(private modalService: NgbModal,private subscriptionService: SubscriptionService,private utilsService: UtilsService) { }

  ngOnInit() {  
    this.toDay = this.toDay.split(' ');
    this.toDay = this.toDay[2]+' '+this.toDay[1]+', '+this.toDay[3];
    
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }}
  openModalResult() {
    this.modalService.open(this.contentResult, {ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      if(result == 'subscribe'){
        let data = {
          ...this.declarersinistreForm.value,
        };
        let police = {
          "numeroPolice" : this.declarersinistreForm.value.numeroPolice
        };
        // console.log('data' ,data);
         
        this.subscriptionService.checkSinistre(police).subscribe((resp)=>{
          if(resp['responseCode'] == 200){
            // this.valid = resp['data'];
            // console.log(this.valid);
            // this.valid = true;
            //  console.log('nieuw na');
            if (resp['data']['actif'] == true) {
              this.subscriptionService.registerSinistre(data).subscribe((resp)=>{
                if(resp['responseCode'] == 200){
                  this.utilsService.showToastSuccess(resp['message'], "Sinistre déclaré avec success");
                }
                else{
                      this.utilsService.showToastError(resp['message'], "Erreur lors de la déclaration");
                    }
              });
            }
          }
          else{
            this.utilsService.showToastError(resp['message'], "Ce numéro de police n'existe pas");
          }
        })
      
      }   
    }, (reason) => {
      // console.log(reason);
    });
  }
  onDateSelect(event,  controlName){
    this.declarersinistreForm.get(''+controlName).setValue(new Date(event.year+'/'+event.month+'/'+event.day));
  }
  
}
