import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Observable, Subject, throwError } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private httpClient: HttpClient) {}
  private subject = new Subject<any>();
  sendAccount(account: string) {
    this.subject.next(account);
  }
  clearAccount() {
    this.subject.next();
  }
  getAccount(): Observable<any> {
    return this.subject.asObservable();
  }
  activate(params) {
    return this.httpClient
      .get(
        environment.baseApiUrl + "souscripteurs/activate-account?id=" + params
      )
      .pipe((response) => response);
  }
}
