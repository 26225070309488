import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInRight, fadeInLeft } from 'ng-animate';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { Menu, NavService } from '../../../shared/service/nav.service';

@Component({
  selector: 'app-enterprice-download',
  templateUrl: './enterprice-download.component.html',
  styleUrls: ['./enterprice-download.component.scss'],
  animations: [
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      // Set the duration to 5seconds and delay to 0seconds
      params: { timing: 5, delay: 0 }
    }))])
  ],
})
export class enterpriceDownloadComponent implements OnInit {

  fadeInLeft:any;
  owlcarouselOptions= {
    items: 6,
    margin: 30,
    nav: false,
    autoplay: true,
    slideSpeed: 500,
    paginationSpeed: 400,
    grabCursor: true,
    loop: true,
    dots: true,
    responsive: {
        0: {
            items: 2,
            margin: 10
        },
        600:{
            items: 4,
            margin: 10
        },
        992: {
            items: 6
        }
    }
  }
  owlcarousel1: any = [];
  public menuItems: Menu[];
  products: any = [];
  listener: any = [];
  items:any = [];
  constructor(private subscriptionService: SubscriptionService,public navServices: NavService) { 
    this.navServices.items.subscribe(menuItems => {
      this.getProducts();
      this.menuItems = menuItems;
    });
  }

  ngOnInit() {
    this.getPartners();
  }

  getPartners(){
    this.subscriptionService.mockedPartners().subscribe((resp) =>{
      this.owlcarousel1 = resp;
    })
  }

  getProducts(){
    // console.log("1");
    this.subscriptionService.getProducts().subscribe((resp) => {
      this.products = resp['data'];
      // console.log(this.products);
      this.items = this.menuItems.map((item) =>{
          item = this.products.filter((p) =>{
             p['path'] = '/devis/'+p.id;
             p['title'] = (p.code).toLowerCase();
             p['type'] = 'link';
             console.log('p',p);
             return p;  
            // 
          }) 
        //  return item ;
          // console.log(item);
      }); 
    });
      
      
  }
}
