import { Component, isDevMode, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Location } from '@angular/common';
import { ActivatedRoute, RouterEvent, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { filter, map } from 'rxjs/operators';
import { apiUrls } from 'src/app/shared/api-urls';
import { WizardComponent } from 'angular-archwizard';
import { HttpErrorResponse } from '@angular/common/http';
import { interval } from 'rxjs';
import { SelectPays } from 'src/app/shared/service/select_pays.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
// import { PdfMake } from 'pdfmake';
import * as pdfMake from "pdfmake/build/pdfmake";

import * as pdfFonts from "pdfmake/build/vfs_fonts";

// import pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import html2canvas from 'html2canvas';
// import  "jspdf-autotable" ;
import { collectExternalReferences } from '@angular/compiler';
import { ElementRef } from '@angular/core';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  // paymentForm = {
  //     timestamp: this.dateTime,
  //     returnUrl: environment.baseApiUrl+'contrats/save-contrat',
  //     auth: '',
  //     amount: '',
  //     requestId: this.dateTime,
  //     terminalNumber: 160820000101,
  //     transactionCurrencyCode: 'XOF',
  //     customerLastName: '',
  //     customerFirstName: '',
  //     customerAddress: '',
  //     customerCountry: '',
  //     customerEmail: '',
  //     customerPhone: '',
  //     customerState: '',
  //     customerCity: '',
  //     customerZipCode: '',
  //     ville: '',
  //     montantSaisie: '',
  //   }
  beneficiaryChoosen: boolean = false;
  paymentMethod: string;
  beneficiaries = [];
  formules = [];
  durees = [];
  debut: any;
  fin: any;
  avalaibleDuration = [];
  paymentMethods = [];
  sendingRequest: boolean = false;
  assure: number = 1;
  apiResponse: any = {
    title: '',
    text: '',
    type: ''
  };
  price: number;
  pn: number;
  cout: number;
  te: number;
  phoneMask: string = '00 000 00 00';
  cinMask: string = '0 000 0000 00000';
  dateTime = Math.floor(Date.now() / 1000);
  key = '676AC4CC226201685E89ADB0C9B4510D';
  // initPaymentForm = {
  //     requestId: 0,
  //     numeroSouscription: '',
  //     status: '',
  //     montant: '',
  //   }
  paymentForm = {
    timestamp: this.dateTime,
    returnUrl: 'http://web-ma.suntelecoms.com/api/contrats/save-contrat',
    auth: '',
    amount: '',
    requestId: this.dateTime,
    terminalNumber: 160820000101,
    transactionCurrencyCode: 'XOF',
    customerLastName: '',
    customerFirstName: '',
    customerAddress: '',
    customerCountry: '',
    customerEmail: '',
    customerPhone: '',
    customerState: '',
    customerCity: '',
    customerZipCode: '',
    ville: '',
    montantSaisie: '',
  }
  today: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  souscriptionForm = new FormGroup({
    duree: new FormControl(null, [Validators.required]),
    formule: new FormControl(null, [Validators.required]),
    canal: new FormControl(1, [Validators.required]),
    moyenDePaiement: new FormControl(1, [Validators.required]),
    assureur: new FormControl(null, [Validators.required]),
    produit: new FormControl(null, [Validators.required]),
    montant: new FormControl(null),
    souscripteur: new FormGroup({
      prenom: new FormControl(null, [Validators.required]),
      nom: new FormControl(null, [Validators.required]),
      genre: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)]),
      dateNaissance: new FormControl(null),
      telephone: new FormControl(null, [Validators.required]),
      numeroCni: new FormControl(null)
    }),
    assure: new FormGroup({
      prenom: new FormControl(null, [Validators.required]),
      nom: new FormControl(null, [Validators.required]),
      dateNaissance: new FormControl(null, [Validators.required]),
      numeroTelephone: new FormControl(null, [Validators.required]),
      // adresse: new FormControl(null, [Validators.required]),
      passeport: new FormControl(null, [Validators.required]),
      lateralite: new FormControl(null, [Validators.required]),
      genre: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)])
    }),
    beneficiaire: new FormGroup({
      prenom: new FormControl(null, [Validators.required]),
      nom: new FormControl(null, [Validators.required]),
      dateNaissance: new FormControl(null, [Validators.required]),
      numeroTelephone: new FormControl(null, [Validators.required]),
      genre: new FormControl(null, [Validators.required]),
      adresse: new FormControl(null, [Validators.required]),
      numeroCni: new FormControl(null, [Validators.required]),
    }),
    voyage: new FormGroup({
      dateDebut: new FormControl(null, [Validators.required]),
      dateFin: new FormControl(null, [Validators.required]),
      provenance: new FormControl('Sénégal', [Validators.required]),
      duree: new FormControl(null, [Validators.required]),
      destination: new FormControl(null, [Validators.required]),
      agence_voyage: new FormControl(null, [Validators.required]),
    }),
  });
  initPaymentForm = {
    requestId: 0,
    numeroSouscription: '',
    status: '',
    montant: '',
  }
  invaliddate: boolean = false;
  products: any;
  devisType: string = "";
  assureurs: any = [];
  idAssurance: string;
  @ViewChild('htmlData') mondevis: ElementRef;
  // @ViewChild('couponPage', { static: true }) couponPage: ElementRef;
  @ViewChild('contentResult', { static: false }) contentResult: any;
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;
  @ViewChild('htmlData') htmlData: ElementRef;
  devisErrors: string = "";
  pays: any = [];
  date: string;
  constructor(private subscriptionService: SubscriptionService,
    private location: Location,
    private route: ActivatedRoute,
    public router: Router,
    private utilsService: UtilsService,
    private selectService: SelectPays, private modalService: NgbModal) {
      this.newIndentifiant(this.route.snapshot.params.type);

    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.idAssurance = this.route.snapshot.paramMap.get('type');
        this.fireFunctions();
      }
    });
    
  }

  fireFunctions() {
    this.getPaymentMethods();
    this.getProducts();
    this.getAssureur();
    this.paymentForm.returnUrl = environment.baseApiUrl + 'contrats/save-contrat';
  }

  ngOnInit(): void {
    this.idAssurance = this.route.snapshot.paramMap.get('type');
    this.getPays();
  }

  newIndentifiant(id) {
    this.subscriptionService.changeIndentifiant(id)
  }
  getAssureur() {
    this.subscriptionService.reqPost(apiUrls.assureurByProduit, { 'typeProduit': this.idAssurance }).subscribe((resp) => {
      this.assureurs = resp['data'];
    }, (error: HttpErrorResponse) => {
      //console.log('error', error);
      this.devisErrors = error['message'];
    });
  }
  getPays() {
    this.pays = this.selectService.recuperer_pays();
    //  //console.log(this.pays);
  }
  setRequiredField() {
    this.souscripteur.get('numeroCni').setValidators(Validators.required);
    this.souscripteur.get('dateNaissance').setValidators(Validators.required);
  }

  get souscripteur() {
    return this.souscriptionForm.get('souscripteur')
  }

  // saveData() {
  //     this.sendingRequest = true;
  //     let data = {
  //         ...this.souscriptionForm.value,
  //     };
  //     if (this.assure == 1) {
  //         data.assure == data.souscripteur;
  //     }

  //     this.subscriptionService.saveSouscription(data).subscribe((resp) => {
  //         this.sendingRequest = false;
  //         this.paymentForm.requestId = resp['data']['id'];
  //         this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
  //             if (resp['responseCode'] == 200) {
  //                 let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.requestId + 'XOF' + this.key;
  //                 this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);

  //                 this.apiResponse =
  //                     {
  //                         title: 'Réussi',
  //                         text: 'Votre souscription a été enregistréé avec succès. Vous allez être redirigé vers la page de paiement',
  //                         type: 'success'
  //                     };
  //                 setTimeout(() => {
  //                     this.apiResponse.title = '';
  //                     window.open(respApg['paymentUrl'], '_blank');
  //                 }, 3000);
  //                 this.checkSubscription(this.initPaymentForm.numeroSouscription)
  //             } else {

  //                 this.apiResponse = {
  //                     title: 'Echec',
  //                     text: 'Echec souscription. Veuillez vérifier vos informations et recommencer SVP',
  //                     type: 'danger'
  //                 }
  //                 setTimeout(() => {
  //                     this.apiResponse.title = '';
  //                 }, 2000);
  //             }
  //         });
  //     }, error => {
  //         this.apiResponse = {
  //             title: 'Echec',
  //             text: 'Erreur lors de la connexion au serveur. Si ca persiste veuillez contacter l\'admin SVP',
  //             type: 'danger'
  //         }
  //         setTimeout(() => {
  //             this.apiResponse.title = '';
  //         }, 2000);
  //         this.sendingRequest = false;
  //     });
  // }

  saveData() {
    this.sendingRequest = true;
    let data = {
      ...this.souscriptionForm.value,
    };
    if (this.assure == 1) {
      data.assure == data.souscripteur;
    }

    //console.log(data);
    this.subscriptionService.saveSouscription(data).subscribe((resp) => {
      this.sendingRequest = false;
      //console.log("Resp", resp['data'])
      if (resp['responseCode'] == 200) {
        let type = '&meansType=CARTE_OTHER';
        //console.log("RequestId", this.paymentForm.requestId);
        this.initPaymentForm.montant = this.paymentForm.amount;
        this.initPaymentForm.requestId = this.paymentForm.requestId
        this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
        this.initPaymentForm.status = "PENDING"
        this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
          //console.log("Reponse", rep)
          if (rep['responseCode'] != 200) {
            this.utilsService.showToastError(resp['message'], "Echec");
          }
        });
        if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
        let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.timestamp + 'XOF' + this.key;
        this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
        this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
          if (respApg['code'] == '0000') {
            this.utilsService.showToastSuccess("Votre souscription a été enregistréé avec succès. Vous allez être redirigé vers la page de paiement", "Souscription réussie")
            setTimeout(() => {
              //console.log(respApg['paymentUrl'] + type)
              window.open(respApg['paymentUrl'] + type, '_blank');
            }, 3000);
            this.checkSubscription(this.initPaymentForm.numeroSouscription)
          } else {
            this.utilsService.showToastError(respApg['message'], "Echec");

          }
        });
      } else {
        this.utilsService.showToastError(resp['message'], "Echec");
      }
    }, error => {
      let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
      if (error && error.error) {
        message = error.error.message;
      }
      this.utilsService.showToastError(message, "Echec");

      this.sendingRequest = false;
    });
  }
  onDateSelect(event, groupName, controlName) {
    this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
  }
  onDateSelect2(event, groupName, controlName) {

    // //console.log("dah");
    if (controlName == "dateDebut") {
      this.debut = new Date(event.year + '/' + event.month + '/' + event.day);
      //  alert(this.debut);
      if (this.fin) {
        if (this.debut < this.fin) {
          this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
          let difference = (this.fin.getTime() - this.debut.getTime()) / (1000 * 3600 * 24);
          this.souscriptionForm.get('voyage').get('duree').setValue(difference);
          // //console.log(difference);
          this.invaliddate = false
          if (difference >= 1 && difference <= 7) {
            this.price = 8600;
            this.pn = 6818;
            this.cout = 1000;
            this.te = 782;
          }
          else if (difference >= 8 && difference <= 10) {
            this.price = 11100;
            this.pn = 9091;
            this.cout = 1000;
            this.te = 1009;
          }
          else if (difference >= 11 && difference <= 15) {
            this.price = 14100;
            this.pn = 11818;
            this.cout = 1000;
            this.te = 1282;
          }
          else if (difference >= 16 && difference <= 21) {
            this.price = 18101;
            this.pn = 15455;
            this.cout = 1000;
            this.te = 1646;
          }
          else if (difference >= 22 && difference <= 30) {
            this.price = 22100;
            this.pn = 19091;
            this.cout = 1000;
            this.te = 2009;
          }
          else if (difference >= 31 && difference <= 60) {
            this.price = 41100;
            this.pn = 36364;
            this.cout = 1000;
            this.te = 5100;
          }
          else if (difference >= 61 && difference <= 90) {
            this.price = 56100;
            this.pn = 50000;
            this.cout = 1000;
            this.te = 5100;
          }
          else if (difference >= 91 && difference <= 180) {
            this.price = 84101;
            this.pn = 75000;
            this.cout = 1000;
            this.te = 7646;
          }
          else if (difference >= 181 && difference <= 360) {
            this.price = 112100;
            this.pn = 100909;
            this.cout = 1000;
            this.te = 10191;
          }

        }

        else {
          this.invaliddate = true;
        }
      } else {
        this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
        this.invaliddate = false;
      }
    }
  }
  onDateSelect3(event, groupName, controlName) {
    // //console.log( event,groupName, controlName);

    this.fin = new Date(event.year + '/' + event.month + '/' + event.day);
    // this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(this.fin);
    // //console.log(this.souscriptionForm.get('' + groupName).get('' + controlName).value);
    if (this.debut) {
      if (this.debut < this.fin) {
        // //console.log(this.fin);
        // //console.log(this.souscriptionForm.get('' + groupName).get('dateFin').value);
        this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(this.fin);
        this.invaliddate = false;
        let difference = (this.fin.getTime() - this.debut.getTime()) / (1000 * 3600 * 24);
        //  alert(difference);
        this.souscriptionForm.get('voyage').get('duree').setValue(difference);
        if (difference >= 1 && difference <= 7) {
          this.price = 8600;
          this.pn = 6818;
          this.cout = 1000;
          this.te = 782;
        }
        else if (difference >= 8 && difference <= 10) {
          this.price = 11100;
          this.pn = 9091;
          this.cout = 1000;
          this.te = 1009;
        }
        else if (difference >= 11 && difference <= 15) {
          this.price = 14100;
          this.pn = 11818;
          this.cout = 1000;
          this.te = 1282;
        }
        else if (difference >= 16 && difference <= 21) {
          this.price = 18101;
          this.pn = 15455;
          this.cout = 1000;
          this.te = 1646;
        }
        else if (difference >= 22 && difference <= 30) {
          this.price = 22100;
          this.pn = 19091;
          this.cout = 1000;
          this.te = 2009;
        }
        else if (difference >= 31 && difference <= 60) {
          this.price = 41100;
          this.pn = 36364;
          this.cout = 1000;
          this.te = 5100;
        }
        else if (difference >= 61 && difference <= 90) {
          this.price = 56100;
          this.pn = 50000;
          this.cout = 1000;
          this.te = 5100;
        }
        else if (difference >= 91 && difference <= 180) {
          this.price = 84101;
          this.pn = 75000;
          this.cout = 1000;
          this.te = 7646;
        }
        else if (difference >= 181 && difference <= 360) {
          this.price = 112100;
          this.pn = 100909;
          this.cout = 1000;
          this.te = 10191;
        }
        //console.log(difference);
      }
      else {
        this.invaliddate = true;
      }
    }
    else {
      this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
      this.invaliddate = false;
    }


  }
  // getFormules(){
  //   this.sendingRequest = true;
  //   this.subscriptionService.getFormules().subscribe((resp) => {
  //     this.allFormula = resp['data'];
  //     this.sendingRequest = false;
  //   }, error => {
  //     this.sendingRequest = false
  //   });
  // }

  // getDuration(){
  //   this.subscriptionService.getDuration().subscribe((resp) =>{
  //     this.durees  = resp['data'];
  //   });
  // }

  getPaymentMethods() {
    this.subscriptionService.getPaymentMethods().subscribe((resp) => {
      //   //console.log('moyens', resp);
      this.paymentMethods = resp['data'];
    });
  }

  getProducts() {
    this.subscriptionService.getProducts().subscribe((resp) => {
      this.products = resp['data'];
      // //console.log('idAssuranceGetP', this.idAssurance);
      let typeAss = this.products.find((p) => p.id == this.idAssurance);
      this.devisType = typeAss ? typeAss.code.toLowerCase().split(' ').splice(-1)[0] : 'automobile';
      //console.log('devisType',this.devisType);
      // //console.log('devis', this.devisType);
      // //console.log('prodsFilter', this.products.filter(p => p.id == this.idAssurance)[0]);
      // //console.log('prodsFind', this.products.find((p) => p.id == this.idAssurance));
    }, (error: HttpErrorResponse) => {
      if (isDevMode()) {
        //console.log('messagesErreurs >> ', error.error.errors[0].map(el => el.message));
      }
    });
  }

  selectPayment(pay) {
    this.souscriptionForm.get('moyenDePaiement').setValue(pay.code == this.souscriptionForm.get('moyenDePaiement').value ? null : pay.code);
  }

  selectFormule(ass, pack) {
    this.souscriptionForm.get('assureur').setValue(ass.assureur.id);
    this.souscriptionForm.get('formule').setValue(pack.formule.id);
    this.souscriptionForm.get('duree').setValue(pack.duree.id);
    this.souscriptionForm.get('montant').setValue(pack.prix);
    this.souscriptionForm.get('produit').setValue(pack.produit.id);
    this.paymentForm.amount = pack.prix;
    this.wizard.goToNextStep();
  }
  openModalResult() {
    this.modalService.open(this.contentResult, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      // //console.log(result);
      if (result == 'subscribe')
        //this.stepWa.goToStep(this.wizard, 4)
        this.wizard.goToNextStep();
    }, (reason) => {
      // //console.log(reason);
    });
  }
  enterStep(event) {
    if (this.assure == 1) {
      this.souscriptionForm.get('assure').patchValue(this.souscriptionForm.get('souscripteur').value);
      this.souscriptionForm.get('assure').get('dateNaissance').setValue(this.souscriptionForm.get('souscripteur').get('dateNaissance').value);
      if (this.souscriptionForm.get('assure').get('dateNaissance').value) {
        let day = this.souscriptionForm.get('assure').get('dateNaissance').value.getDate();
        let month = this.souscriptionForm.get('assure').get('dateNaissance').value.getMonth() + 1;
        let year = this.souscriptionForm.get('assure').get('dateNaissance').value.getFullYear();
        this.date = year + '-' + month + '-' + day;
      }
      this.souscriptionForm.get('assure').get('numeroTelephone').setValue(this.souscriptionForm.get('souscripteur').get('telephone').value);
    }
  }

  setAssure(n) {
    this.assure = n;
  }
  checkSubscription(numberSouscription) {
    const source = interval(5000)
    const check = source.subscribe(x => {
      fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
        .then(response => {
          //console.log("Response Code", response);
          response.json()
            .then(data => {
              if (data['responseCode'] == 200) {
                this.router.navigateByUrl("success-payment");
                //console.log("Test1", data);
                check.unsubscribe();
              }
              //console.log("Test2", data);
            });
        });
    });
  }
  // public openPDF():void {
  //   var data = document.getElementById('htmlData');
  //@ts-ignore
  // data.style.zoom=30;
  // html2canvas(data).then(canvas => {
  //   // Few necessary setting options  

  //   var imgWidth = 210;
  //   var pageHeight = 380;
  //   var imgHeight = canvas.height * imgWidth / canvas.width;
  //   var heightLeft = imgHeight; 
  //   const contentDataURL = canvas.toDataURL('image/png',1.0)
  //   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
  //   var position = 0;
  //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //   pdf.save('DetailsDevis.pdf'); // Generated PDF 

  // });
  // window.pdfMake = require('pdfmake/build/pdfmake.js');
  // var vfs = require('pdfmake/build/vfs_fonts.js');
  // window.pdfMake.vfs = vfs.pdfMake.vfs;
  // pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //       let dd = {
  //                   pageSize: "A4",
  //                   pageOrientation: "Portrait",
  //in pageOrientation you can put "Portrait" or "landscape"

  // start the body of your impression:
  // content: [

  //              {
  //                data
  // table: {
  //   widths: ["*"],
  //   body: [
  //     [
  //       {
  //         text: [
  //           { text: '{{data}}' }
  //         ],
  //         style: "body",
  //         width: "150",
  //         alignment: "left",
  //         border: [false, false, false, false],
  //         margin: []
  //       }
  //     ]
  //   ]
  // }
  //                   },
  //     ]

  // }

  //     pdfMake.createPdf(dd).download("Name of Print");
  // const pdf = new pdfMake.createPdf(data);

  // pdf.add(data);
  // pdfMake.createPdf(data)
  // pdf.download();
  // }
}
