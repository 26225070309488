export const environment = {
    production: false,
    baseApiUrl: 'https://insurtech.suntelecoms.com/api/',
    // baseApiUrl: 'http://74.208.120.109:8888/microassurance/api/',
    // baseApiUrl: 'http://192.168.1.:9000',
    // baseApiUrl: 'http://localhost:9000/api/',
    //apgUrl: 'https://labs.afripayway.com/api/gateway/operations/v1/',
    //frontWebUrl: 'http://192.168.27:4200/',
    frontWebUrl: 'https://insurtech.suntelecoms.com/',
    apgUrl: 'https://dev.afripayway.com/payline/v1/',
    assuranceApiUrl: 'https://insurtech.suntelecoms.com/api/',
    appName: 'Insurtech Assurance',
    defaultCredentials: {
        username: 'webclient',
        password: 'webclient@SUN'
    },
    apg: {
        baseUrl: 'https://wdev.afripayway.com/payline/v1/',
        baseUrlPrelevement: 'https://wdev.afripayway.com/payline/',
        urlPrelevement: 'https://wdev.afripayway.com/payline/card/switch/prelevementBank',
        prelevementURL: '/card/switch/prelevementBank',
        terminalNumber: '423820000101',
        transactionCurrencyCode: 'XOF',
        initURL: '/payment/api/initiateOnlinePayment',
        checkStatusURL: '/payment/api/getStatus',
        returnUrl: `https://preprodonline.secusociale.sn/api/apg-callback`,
        secretKey: 'FC48ABF885F26C13B0BE46B54618BC',
        accountsToCreditIpress: "SN0110111700300990244206",
        accountsToCreditCss: "SN0110111700300990244270",
        initPayment:"/payment/api/initiateOnlinePayment",
      },
};
