import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/shared/service/account.service';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { UtilsService } from 'src/app/shared/service/utils.service';
@Component({
  selector: 'app-enterprice-header',
  templateUrl: './enterprice-header.component.html',
  styleUrls: ['./enterprice-header.component.scss']
})
export class enterpriceHeaderComponent implements OnInit {
  insurances: Array<{}> = [];
  utilisateur: any;
  subscription: Subscription;
  utilisateurs: any;
  constructor(public route: Router,private utilsService:UtilsService, private serviceData: SubscriptionService,private accountService:AccountService) { 

  }

  ngOnInit() {
    this.getInsurances();
    
    if(localStorage.getItem('microFinance')){
    this.utilisateur = JSON.parse(localStorage.getItem('microFinance'))
  //  console.log(this.utilisateur);
  }
  }

  getInsurances(){
    this.serviceData.mockedInsurances().subscribe((resp) => {
      this.insurances = resp;
      // console.log(this.insurances);
    })
  }

  redirection(url: string){
    window.open(url, '_blank');
  }

}
