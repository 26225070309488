<app-enterprice-header></app-enterprice-header>
<!-- <app-enterprice-nav></app-enterprice-nav> -->
<!-- <app-enterprice-header></app-enterprice-header> -->

<section class="bg-page" *ngIf="datas.statut === '1'">
  <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
            
<!-- partial:index.partial.html -->
<div id='card1' class="animated fadeIn">
<div id='upper-side'>
  <!-- <?xml version="1.0" encoding="utf-8"?> -->
    <!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <!-- <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> -->
    <svg version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve">
      <path d="M131.583,92.152l-0.026-0.041c-0.713-1.118-2.197-1.447-3.316-0.734l-31.782,20.257l-4.74-12.65
        c-0.483-1.29-1.882-1.958-3.124-1.493l-0.045,0.017c-1.242,0.465-1.857,1.888-1.374,3.178l5.763,15.382
        c0.131,0.351,0.334,0.65,0.579,0.898c0.028,0.029,0.06,0.052,0.089,0.08c0.08,0.073,0.159,0.147,0.246,0.209
        c0.071,0.051,0.147,0.091,0.222,0.133c0.058,0.033,0.115,0.069,0.175,0.097c0.081,0.037,0.165,0.063,0.249,0.091
        c0.065,0.022,0.128,0.047,0.195,0.063c0.079,0.019,0.159,0.026,0.239,0.037c0.074,0.01,0.147,0.024,0.221,0.027
        c0.097,0.004,0.194-0.006,0.292-0.014c0.055-0.005,0.109-0.003,0.163-0.012c0.323-0.048,0.641-0.16,0.933-0.346l34.305-21.865
        C131.967,94.755,132.296,93.271,131.583,92.152z" />
      <circle fill="none" stroke="#ffffff" stroke-width="5" stroke-miterlimit="10" cx="109.486" cy="104.353" r="32.53" />
    </svg>
    <h3 id='status'>
      Succès
    </h3>
</div>
<div id='lower-side'>
  <p id='message'>
    Votre paiement a été effectué avec succès.
  </p>
  <!-- <p id='message'>
    Les références de la transaction vous ont étés envoyées par courrier électronique.
  </p> -->
  <div class="row">
    <div class="col-6">
      <button id="contBtn" (click)="continuer()" class="btnHeight">
        <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
        Accueil
      </button>
    </div>
    <div class="col-6">
      <button class="contBtn btnHeight" id="contBtn" (click)="openModal(datas.produit)">
        <!-- <img src="../../../../assets/images/icon-imprimer.svg" alt=""> -->
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
        condition particuliére
      </button>
    </div>
  </div>
<!--  <div class="row">
    <div class="btn-success" data-toggle="modal" data-target="#carteBruneModal" *ngIf="datas.produit == 'AUTO'">
      <span>Télécharger Attestation</span>
    </div>
  </div>-->
</div>
</div>
</div>
</div>
</div>
</section>
<section class="bg-page" *ngIf="datas.statut === '2'">
  <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
            
<div id='card' class="animated fadeIn">
<div id='upper-side' class="bg-error">
    <i class="fas fa-times text-white" id="checkmark2"></i>
    <h3 id='status'>
      Erreur
    </h3>
</div>
<div id='lower-side'>
  <p id='message'>
    Erreur lors du paiement veuillez réessayer.
  </p>
  <p id='message'>
    <b>équipe INSURTECH GLOBALE</b>
  </p>
  <button id="contBtn" (click)="continuer()">
    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
    Accueil
  </button>
</div>
</div>

</div>
</div>
</div>
</section>
<!-- MRH -->
<ng-template #contentResultass let-modal>
<div #mrhDownload id="mrhDownload">
  <div class="modal-body p-5 bg-white">
    <div class="mesImage">
      <div class="imgLogo">
        <img [src]="currentQuittance.img" alt="">
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Condition Particuliere MRH</div>
        <div class="sub-title">(Cette condition est valable jusqu'au {{currentQuittance.dateExpiration | date:'dd/MM/yyyy'}}) </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Références du souscripteur
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> SN{{currentQuittance.numeroSouscription}}SC </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.prenom}}
                {{currentQuittance.souscripteur.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.email}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.telephone}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.adresse}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.profession}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.secteuractivite}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentQuittance.assureur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Références Condition Particuliére</span>
          </div>
          <div class="contenu-div">
            <div class="row">


              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SN{{currentQuittance.numeroSouscription}}PR </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance.dateCreation |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance.assure.prenom}} {{currentQuittance.assure.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance.assure.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance.dateEffet | date:'dd/MM/yyyy'}} 
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance.dateExpiration | date:'dd/MM/yyyy'}} à Minuit
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 ans </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            Identification de la maisson
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Qualité souscripteur</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.qualiteSouscripteur}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre de pièces</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    F{{currentQuittance?.nombrePiece}} </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                       *ngIf="currentQuittance?.qualiteSouscripteur === 'proprietaire'">Valeur du
                    batiment</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                       *ngIf="currentQuittance?.qualiteSouscripteur ==='proprietaire'">
                    {{currentQuittance?.valeurBatiment | number:'1.0-0':'fr'}} </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                       *ngIf="currentQuittance?.qualiteSouscripteur !== 'proprietaire'">Loyer mensuel
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                       *ngIf="currentQuittance?.qualiteSouscripteur !== 'proprietaire'">
                    {{currentQuittance?.capitalGarantie | number:'1.0-0':'fr'}} </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Valeur du contenu</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.capitalGarantie | number:'1.0-0':'fr'}} </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Surface à développée</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.surface}} m²
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.situationRisque}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="proposition2">
        <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Nature des garanties
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Responsabilité civile
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Incendie et événements assimilés
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Vol par agression et/ou effraction
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Dégâts des eaux
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Bris de glaces
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Défense et recours
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of  granddevis?.garanties">
                <!-- {{garantie.libelle}} -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Capitaux garanties
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val" *ngFor="let garantie of  granddevis?.garanties">
                <!-- <span> {{garantie.primeTtc | number:'1.0-0':'fr'}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
        {{currentQuittance?.prix | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                <div *ngFor="let item of granddevis?.garanties">
                  <span>{{item.libelle}}</span>

                </div>
                <span class="valPrime">Prime totale TTC</span>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="val" *ngFor="let item of granddevis?.garanties">
                  <span>{{item.primeTtc | number:'1.0-0':'fr'}} FCFA</span>

                </div>
                <span class="valPrime val">{{currentQuittance?.prix | number:'1.0-0':'fr'}}
                  FCFA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentQuittance?.assureur?.nom}}</b> est une <b>{{currentQuittance?.assureur?.typeSociete}}</b> avec conseil
          d'administration au capital de <b>{{currentQuittance?.assureur?.capital |
                number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentQuittance?.assureur?.anneeCreation}}</b>. NINEA
          <b>{{currentQuittance?.assureur?.ninea}}</b>-RC: <b>{{currentQuittance?.assureur?.registreCommerce}}</b> Elle est régie par le code
          des assurances CIMA. Son siège est situé à <b>{{currentQuittance?.assureur?.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentQuittance?.assureur?.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentQuittance?.assureur?.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentQuittance?.img" alt="">
      </div>
    </div>
  </div>

</div>
<div>
  <div class="row">
    <div class="col-6">
      <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="modal.close('close')"><i
        class="fa fa-chevron-left"></i></button>
    </div>
    <div class="col-6 ">
      <!-- modal.close('subscribe') -->
      <button type="button" #btnDownload id="btnDownload" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" 
      placement="top" ngbPopover="Télécharger le devis" (click)="captureScreen('mrhDownload','MRH')">
      Télécharger <i class="fa fa-download"></i>
      </button>
    </div>
  </div>
</div>
</ng-template>

<!-- pack assurance -->
<ng-template #simulateurDevis let-modal>
  <div class="modal-body p-5 bg-white download" #santeDownload id="santeDownload">
    <div class="row">
      <div class="col-10 mesImage">
        <div class="imgLogo">
          <img [src]="currentQuittance.img" alt="">
        </div>
      </div>
      <div class="col-lg-2 text-right">
        <!-- <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
          class="fa fa-times times-ass c-times-ass1"></i></a> -->
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Condition Particuliére Sante</div>
        <div class="sub-title">(Cette proposition est valable pour une année )
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Références du souscripteur
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> SN{{currentQuittance.numeroSouscription}}SC </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.prenom}}
                {{currentQuittance.souscripteur.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.email}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.telephone}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.adresse}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.profession}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                {{currentQuittance.souscripteur.secteuractivite}} </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentQuittance.assureur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span>Références Condition Particuliére</span>
          </div>
          <div class="contenu-div">
            <div class="row">

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SN{{currentQuittance.numeroSouscription}}PR </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance.date |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type formule</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.typeFormule?.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Formule</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.formule?.nom}} </div>
              <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date naissance</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{this.souscriptionForm.get('AxASimulateur').get('typeFormule').value |date:'dd/MM/yyyy'}}
              </div> -->

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an(s)
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance.dateExpiration | date:'dd/MM/yyyy'}} à
                Minuit
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adulte(s)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 
                {{currentQuittance?.nombreAdulte}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Enfant(s)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 
                {{currentQuittance?.nombreEnfant}}
              </div>
              <!-- <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                  <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte(s)</div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{currentQuittance?.nombreAdulte}}</div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                  <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant(s)</div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{currentQuittance?.nombreEnfant}}</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row "  *ngIf="currentQuittance.prime">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Identification du produit </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.formule?.nom}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.typeFormule?.nom}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Service</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.assureur?.nom}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    <!-- {{dataSimulateur.age}} an(s) -->
                    0 an(s)
                  </div>

                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Cout piéce</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance.prime.assureur.parametrageSante[0].coutPiece | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Taxe</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> 
                    {{currentQuittance.prime.assureur.parametrageSante[0].taxe | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime Nette</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> 
                    {{currentQuittance.prime.assureur.parametrageSante[0].primeNette | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime TTC</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> 
                    {{currentQuittance.prime.assureur.parametrageSante[0].primeTtc | number:'1.0-0':'fr'}} Fcfa
                  </div>
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Identification du produit pour les packs santés-->
      <div class="row "  *ngIf="currentQuittance.produit">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Identification du produit </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance.formule.nom}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Produit</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance.produit.nom}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    0 an(s)
                  </div>

                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prix</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance.prix |number:'1.0-0':'fr'}}
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Durée</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{currentQuittance.duree.duree}}  {{currentQuittance.duree.unite}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Fin Identification-->

      <!-- Avantages pour les packs-->
      <div class="proposition2">
        <div class="pro-title"> Avantages</div>
      </div>
      <div class="row c-row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Description </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentQuittance.avantages">
                {{item.nom}}
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Fin Avantages-->

      <!-- Des Prestations -->
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
        de <b> {{currentQuittance.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Nom </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentQuittance.prestations">
                {{item.nom}}
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Plafond (FCFA)
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                   *ngFor="let item of currentQuittance.prestations">
                {{item.plafond |number:'1.0-0':'fr'}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Fréquence
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                   *ngFor="let item of currentQuittance.prestations">
                {{item.frequence}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin Prestations-->
      <div class="description-ass"  *ngIf="currentQuittance.prime">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
        de <b> {{currentQuittance.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row c-row"  *ngIf="currentQuittance.prime">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Prime </span>
            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
              triggers="mouseenter:mouseleave" (click)="goTo(0)"></span> -->
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" >
                Taxe
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" >
                Prime Nette
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" >
                Prime TTC
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right c-col-6">
          <div class="title-div">
            Plafond (FCFA)
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentQuittance?.prime?.assureur?.parametrageSante">
                {{item.taxe |number:'1.0-0':'fr'}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentQuittance?.prime?.assureur?.parametrageSante">
                {{item.primeNette |number:'1.0-0':'fr'}}
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentQuittance?.prime?.assureur?.parametrageSante">
                {{item.primeTtc |number:'1.0-0':'fr'}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Fréquence
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentQuittance?.produit?.prestations">
                {{item.frequence}}
              </div>&nbsp;


            </div>
          </div>
        </div> -->
      </div>

      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
          disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
            générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
            visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentQuittance?.assureur?.nom}}</b> est une <b>{{currentQuittance?.assureur?.typeSociete}}</b> avec
          conseil
          d'administration au capital de <b>{{currentQuittance?.assureur?.capital |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentQuittance?.assureur?.anneeCreation}}</b>. NINEA
          <b>{{currentQuittance?.assureur?.ninea}}</b>-RC: <b>{{currentQuittance?.assureur?.registreCommerce}}</b> Elle est régie par le
          code
          des assurances CIMA. Son siège est situé à <b>{{currentQuittance?.assureur?.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentQuittance?.assureur?.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentQuittance?.assureur?.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentQuittance?.img" alt="">
      </div>
    </div>

  </div>
  <div>
    <div class="row">
      <div class="col-6">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="modal.close('close')"><i
          class="fa fa-chevron-left"></i></button>
      </div>
      <!-- <div class="col-4 justify-content-center">
        <button #btnDownload id="btnDownload" type="button" class="btn btn-primary btn-tel pull-left c-roundbtn" (click)="captureScreen()" placement="left"
        ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave">Télécharger <i class="fa fa-download"></i></button>
      </div> -->
      <div class="col-6 ">
        <!-- modal.close('subscribe') -->
        <button type="button" #btnDownload id="btnDownload" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" 
        placement="top" ngbPopover="Télécharger le devis" (click)="captureScreen('santeDownload','SANTE')">
        <!-- fa-angle-double-right -->
        Télécharger <i class="fa fa-download"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Auto -->
<ng-template #contentAuto let-modal>
  <div class="modal-body p-5 bg-white autodown" #autoDownload id="autoDownload">
    <div class="mesImage">
      <div class="imgLogo">
        <img [src]="currentQuittance?.img" alt="">
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Condition Particuliére automobile</div>
        <div class="sub-title">(Cette proposition est valable jusqu'au {{currentQuittance?.dateExpiration | date:'dd/MM/yyyy'}}) </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span>Références du souscripteur</span>
            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
                      triggers="mouseenter:mouseleave" (click)="openModal()" (click)="modal.close('close')"></span> -->
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SN{{currentQuittance?.numeroSouscription}}SC </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.prenom}}
                {{currentQuittance?.souscripteur.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.email}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.telephone}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.profession}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.secteuractivite}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance?.souscripteur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Références Condition Particuliére</span>
          </div>
          <div class="contenu-div">
            <div class="row">

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SN{{currentQuittance?.numeroSouscription}}PR </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance?.date |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.prenom}} {{currentQuittance?.souscripteur.nom}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.souscripteur.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{currentQuittance?.dateEffet | date:'dd/MM/yyyy'}} </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance?.dateExpiration | date:'dd/MM/yyyy'}} à Minuit
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 
                {{ currentQuittance?.duree?.duree }} {{currentQuittance?.duree?.unite}}
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentQuittance?.categorieVehicule.libelle}} </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            Identification du véhicule
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">N° immatriculation</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.immatriculation}} </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Marque</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.vehicule?.marque}} 
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.puissance}} </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    <!-- {{currentQuittance?.valeurVenal | number:'1.0-0':'fr'}}  -->
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    <span class="fa fa-check"></span>
                  </div>
                  <div class="col-md-7 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                  <div class="col-md-5 col-lg-7 col-sm-12 col-xs-12 value c-value">
                    <!-- {{currentQuittance?.}}  -->
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Nombre de places</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.nombrePlace}} </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    <!-- {{nombrePlace}}  -->
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.dateMisEnCirculation |date:'dd/MM/yyyy'}} 
                  </div>

                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Modéle</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    {{currentQuittance?.vehicule?.modele}}
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Valeur vénale</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                     {{currentQuittance?.valeurVenal | number:'1.0-0':'fr'}} 
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Date immatriculation</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value">
                    <!-- {{currentQuittance?. | date:'dd/MM/yyyy'}}  -->
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 name">Zone</div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 value"> SENEGAL </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="proposition2">
        <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Nature des garanties
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                {{garantie.key}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Capitaux garanties
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
          <div class="title-div">
            Franchises par sinistre
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                <span class="c-col-val-neant">Néant</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
          {{currentQuittance?.prix | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                <!-- <div *ngFor="let item of primeAss"> -->
                  <!-- <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span> -->
                  <span class="valPrime">Prime Totale TTC</span>
                <!-- </div> -->
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <!-- <div class="val" *ngFor="let item of primeAss"> -->
                <div class="val">
                  <span>{{currentQuittance?.prix | number:'1.0-0':'fr'}} FCFA</span>
                  <!-- <span *ngIf="item.key!='Prime Totale TTC'">{{{{currentQuittance?.prix | number:'1.0-0':'fr'}} | number:'1.0-0':'fr'}} FCFA</span> -->
                  <!-- <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}} -->
                    <!-- FCFA</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentQuittance?.assureur?.nom}}</b> est une <b>{{currentQuittance?.assureur?.typeSociete}}</b> avec conseil
          d'administration au capital de <b>{{currentQuittance?.assureur?.capital |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentQuittance?.assureur?.anneeCreation}}</b>. NINEA
          <b>{{currentQuittance?.assureur?.ninea}}</b>-RC: <b>{{currentQuittance?.assureur?.registreCommerce}}</b> Elle est régie par le code
          des assurances CIMA. Son siège est situé à <b>{{currentQuittance?.assureur?.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentQuittance?.assureur?.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentQuittance?.assureur?.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentQuittance?.img" alt="">
      </div>
    </div>
  </div>
  
  <div>
    <div class="row">
      <div class="col-6">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="modal.close('close')"><i
          class="fa fa-chevron-left"></i></button>
      </div>
      <!-- <div class="col-4 justify-content-center">
        <button #btnDownload id="btnDownload" type="button" class="btn btn-primary btn-tel pull-left c-roundbtn" (click)="captureScreen()" placement="left"
        ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave">Télécharger <i class="fa fa-download"></i></button>
      </div> -->
      <div class="col-6 ">
        <!-- modal.close('subscribe') -->
        <button type="button" #btnDownload id="btnDownload" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" 
        placement="top" ngbPopover="Télécharger le devis" (click)="captureScreen('autoDownload','AUTO')">
        <!-- fa-angle-double-right -->
        Télécharger <i class="fa fa-download"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>



<div class="modal modalAttestation" #modalOpen data-backdrop="static" data-keyboard="false" id="attestationModal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content content-form">
      <div class="modal-body attestation" id="attestation" #attestation>
        <div class="row">
          <div class="col-md-3">
            <div class="nom">
              <span> {{currentQuittance?.souscripteur?.nom}} {{currentQuittance.souscripteur?.prenom}}</span>
            </div>
            <div class="">
              <span> {{currentQuittance?.souscripteur?.adresse}}</span>
            </div>
            <div class="numeroPolice">
              <span> {{currentQuittance?.numeroSouscription}}</span>
            </div>
            <div class="dateEffet">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="dateEffet">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="marque">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="matricule">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="typeVehicule">
              <span> {{currentQuittance?.categorieVehicule?.libelle}}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="numeroPolice1">
              <span> {{currentQuittance?.numeroSouscription}}</span>
            </div>
            <div class="dateEffet">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="dateEffet">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
            </div>
           <div class="typeVehicule">
              <span> {{currentQuittance?.categorieVehicule?.libelle}}</span>
            </div>
            <div class="marque">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="matricule1">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="typeVehicule1">
              <span> {{currentQuittance?.categorieVehicule?.libelle}}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="nom2">
              <span> {{currentQuittance?.souscripteur?.nom}} {{currentQuittance.souscripteur?.prenom}}</span>
            </div>
            <div class="nom2">
              <span> {{currentQuittance?.souscripteur?.adresse}}</span>
            </div>
            <div class="numeroPolice2">
              <span> {{currentQuittance?.numeroSouscription}}</span>
            </div>
            <div class="dateEffet1">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
              <span class="expiration1">{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="typeVehicule1">
               <span> {{currentQuittance?.categorieVehicule?.libelle}}</span>
             </div>
            <div class="marque1">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="matricule2">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="matriculeFooter">
              <span>  {{currentQuittance?.categorieVehicule?.libelle}}</span>
            </div>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="aligner">
          <button type="button" class="precedentModal" data-dismiss="modal" >
            <img src="assets/images/icon-bouton-precedent.svg" alt="">
          </button>
          <button type="button" class="imprimer" (click)="generatePdfAttestation()"><img src="assets/images/icon-imprimer.svg"
                                                                                         alt="">
            <span>Télécharger</span></button>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal carteBruneModal" #modalOpen data-backdrop="static" data-keyboard="false" id="carteBruneModal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content content-form">
      <div class="modal-body carteBrune" id="carteBrune" #carteBrune>
        <div class="row">
          <div class="premier col-md-3">
            <div class="nom">
              <span> {{currentQuittance?.souscripteur?.nom}} {{currentQuittance?.souscripteur?.prenom}}</span>
            </div>
            <div class="adresse">
              <span> {{currentQuittance?.souscripteur?.adresse}}</span>
            </div>
            <div class="matricule">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="marque">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="assureur">
              <span>{{currentQuittance?.assureur?.nom}}</span>
            </div>
            <div class="bureau">
              <span>{{currentQuittance?.assureur?.nom}}</span>
            </div>
            <div class="immatriculation">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <!--  <div class="numeroPoliceCarte">
                <span> {{souscription?.numeroSouscription}}</span>
              </div>-->
            <div class="dateEffetCarte">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
              <span class="expirationCarte">{{currentQuittance?.dateEffet  | date: 'dd-MM-yyyy'}}</span>
            </div>
          </div>
          <div class="premier col-md-3">
            <div class="nomCarte1">
              <span> {{currentQuittance?.souscripteur?.nom}} {{currentQuittance?.souscripteur?.prenom}}</span>
            </div>
            <div class="adresseCarte1">
              <span> {{currentQuittance?.souscripteur?.adresse}}</span>
            </div>
            <div class="matriculeCarte1">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="marqueCarte1">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="assureurCarte1">
              <span>{{currentQuittance?.assureur?.nom}}</span>
            </div>
            <div class="bureauCarte1">
              <span>{{currentQuittance?.assureur?.nom}}</span>
            </div>
            <div class="immatriculation1">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="dateEffetCarte1">
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
              <span class="expirationCarte1">{{currentQuittance?.dateEffet  | date: 'dd-MM-yyyy'}}</span>
            </div>
          </div>
          <div class="premier col-md-3 hauteur">
            <div class="nomCarte2">
              <span> {{currentQuittance?.souscripteur?.nom}} {{currentQuittance?.souscripteur?.prenom}}</span>
            </div>
            <div class="">
              <span> {{currentQuittance?.souscripteur?.adresse}}</span>
            </div>
            <div class="matriculeCarte2">
              <span> {{currentQuittance?.immatriculation}}</span>
            </div>
            <div class="assureurCarte2">
              <span>{{currentQuittance?.assureur?.nom}}</span>
              <span>{{currentQuittance?.assureur?.adresse}}</span>
            </div>
          </div >
          <div class="premier col-md-3" >
            <div>
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
              <span class="expirationCarte2">{{currentQuittance?.dateEffet  | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="numeroPoliceCarte2">
              <span>{{currentQuittance?.immatriculation}}</span>
            </div>
            <div>
              <span>{{currentQuittance?.dateEffet | date: 'dd-MM-yyyy'}}</span>
              <span class="expirationCarte2">{{currentQuittance?.dateEffet  | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="marqueCarte3">
              <span> {{currentQuittance?.vehicule?.marque}} {{currentQuittance?.vehicule?.modele}}</span>
            </div>
            <div class="immatriculation3">
              <span>{{currentQuittance?.immatriculation}}</span>
            </div>
          </div>

        </div>

      </div>
      <div class="modal-footer">
        <div class="aligner">
          <button type="button" #closeModal class="precedentModal" data-dismiss="modal" >
            <img src="assets/images/icon-bouton-precedent.svg" alt="">
          </button>
          <button type="button" class="imprimer" (click)="generatePdfCarteBrune()"><img src="assets/images/icon-imprimer.svg"
                                                                                        alt="">
            <span>Télécharger</span></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-enterprice-footer2></app-enterprice-footer2> -->
<app-enterprice-footer></app-enterprice-footer>
