<app-enterprice-header></app-enterprice-header>
<!-- <app-enterprice-nav></app-enterprice-nav> -->
<!-- <app-enterprice-header></app-enterprice-header> -->

  <div class="container-gauche">
    <div class="success-payment-section mt-5">
      <div class="row">
        <div class="col-12">
            <i class="fa fa-check fa-3x"></i>
        </div>
        <div class="col-12">
          <p class="mt-4 mb-4 text-white"><b>Inscription réussis !</b></p>
          <p class="mb-5 text-white">Votre compte a été activé avec succés!</p>
          <p class="mb-5"><a (click)="aller()" class="c-continue text-white"> Cliquer ici pour continuer  &nbsp;<i class="fa fa-chevron-right"></i></a></p>
        </div>
      </div>
    </div>
  </div>
<app-enterprice-footer2></app-enterprice-footer2>
<app-enterprice-footer></app-enterprice-footer>