<app-enterprice-nav></app-enterprice-nav>
<!-- <app-enterprice-header></app-enterprice-header> -->
<!-- <div class="loader-content">
  <i class="fa fa-spinner fa-pulse"></i>
</div> -->
<div class="amsa-form-page mt-5">
  <form [formGroup]="sinistreForm">
      <div class="form-container">
          <div class="row no-margin second-title">
              <h2 class="mb-4 mx-auto">Suivi sinistre</h2>
          </div>
          <div class="row mt-4">
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mx-auto">
                  <div class="form-group">
                      <label for="email">Numéro de la police</label>
                      <input type="text" class="form-control" formControlName="numeroPolice">
                  </div>
            </div>
          </div>
          <div class="row mt-4 mb-4" *ngIf="contract">
             <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 card-infos">
                <p>{{contract?.message}}</p>
                <p>Date de fin:  {{contract?.finDuContrat | date}}</p>
             </div>
          </div>
          <div class="row mt-3">
              <button type="button" class="btn btn-success mt-1 mx-auto" (click)="getData()">Rechercher</button>
          </div>
      </div>
</form>
</div>
<swal
  #confirmSubscription
  title="Confirmation"
  text="Voulez-vous vraiment proceder à la souscription ?"
  icon="question"
  [showCancelButton]="true"
  [focusCancel]="true"
  (confirm)="getData()">
</swal>
<app-enterprice-footer></app-enterprice-footer>
