<!-- <div class="top-header">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-sm-12 col-md-12 col-xs-12">
                <ul class="top-list">
                    <li>
                        <i class='fa fa-clock-o'></i>
                       03 janvier 2021
                    </li>
                    <li>
                        <i class='fa fa-phone'></i>
                        <a href="tel:821-456-241"> Tel: +221 33 860 60 67</a>
                    </li>
                    <li>
                        <i class='fa fa-paper-plane'></i>
                        <a href="#">contact@optima.world</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ul class="top-social">
                    <li>
                        <a href="#" class="facebook">
                            <i class="fa fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="twitter">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="pinterest">
                            <i class="fa fa-pinterest-p"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="instagram">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </li>
                   
                </ul>
            </div>
        </div>
    </div>
</div> -->
<div class="top-header">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <ul class="top-list">
                    
                   
                    <li>
                        <i class='fa fa-paper-plane'></i>
                        <a href="#">contact@suntelecom.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul class="top-social">
                    <li>
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=2720211074&text&app_absent=0" class="whatsapp">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="facebook">
                            <i class="fa fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="twitter">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                   
                    
                    <li>
                        <a target="_blank" href="https://bo-insurtech.suntelecoms.com/" class="log-in">
                            Assurance
                        </a>
                    </li>
                    <li>
                        <a href="https://bo-insurtech.suntelecoms.com/" class="log-in">
                            Espace Client
                           
                        </a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</div>
