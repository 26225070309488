<app-enterprice-header></app-enterprice-header>
<app-enterprice-nav></app-enterprice-nav>
<!-- <app-enterprice-header></app-enterprice-header> -->
  <div class="container-gauche">
    <form [formGroup]="forgotForm" class="form mx-auto" *ngIf="!reinitialise">
      <div class="form-container" >

        <div class="row no-margin second-title">
          <h2 class="mb-5 mx-auto">Réinitialisation mot de passe</h2>
        </div>
        <div class="row no-margin justify-content-right">
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group"  placement="top"
              ngbPopover="Veuillez renseigner le token" triggers="mouseenter:mouseleave" popoverTitle="Token">
                <div class="input-group-append">
                <i class="fa fa-code text-white"> <span class="text-red">*</span></i>
                </div>
              <!-- <label for="email">Prénom <span class="text-red">*</span></label> -->
              <input type="text" class="form-control" formControlName="token" placeholder="token">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && forgotForm.get('token').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group"  placement="top"
              ngbPopover="Veuillez renseigner le mot de passe" triggers="mouseenter:mouseleave" popoverTitle="Nouveau mot de passe">
                <div class="input-group-append">
                <i class="fa fa-lock text-white"> <span class="text-red">*</span></i>
                </div>
              <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
              <input type="password" class="form-control" formControlName="newPassword" placeholder="Nom">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && forgotForm.get('newPassword').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group"  placement="top"
              ngbPopover="Veuillez confirmer le mot de passe" triggers="mouseenter:mouseleave" popoverTitle="Mot de passe de confirmation">
                <div class="input-group-append">
                <i class="fa fa-lock text-white"> <span class="text-red">*</span></i>
                </div>
              <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
              <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirmer mot de passe" (change)="changer()">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && forgotForm.get('confirmPassword').invalid">
              Ce champ est obligatoire
            </div>
            <div class="error min-max text-red" *ngIf="password">
              Les mots de passe ne correspondent pas!
            </div>
          </div>
          <button type="button" class="btn btn-success btn-next pull-right" [disabled]="loaderVisible"   (click)="nouveaupassword()">Envoyer</button>
         
        </div>
      </div>
    </form>
    <div class="success-payment-section mt-4" *ngIf="reinitialise">
      <div class="row">
        <div class="col-12">
            <i class="fa fa-check fa-3x"></i>
        </div>
        <div class="col-12">
          <p class="mt-4 mb-4"><b>Félicitations !</b></p>
          <p class="mb-5">Votre mot de passe a été reinitialisé.</p>
        </div>
      </div>
    </div>
  </div> 
  <app-enterprice-footer2></app-enterprice-footer2>
  <app-enterprice-footer></app-enterprice-footer>