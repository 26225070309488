import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-enterprice',
  templateUrl: './enterprice.component.html',
  styleUrls: ['./enterprice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnterpriceComponent implements OnInit {
  scrollClass: string = "";

  constructor(private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(event.target.scrollingElement.scrollTop >= 120){
      this.scrollClass = "is-sticky";
     }
    else{
      this.scrollClass = "";
    }
    
  }

}
