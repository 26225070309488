import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enterprice-about',
  templateUrl: './enterprice-about.component.html',
  styleUrls: ['./enterprice-about.component.scss']
})
export class enterpriceAboutComponent implements OnInit {
  name = environment.appName;
  owlcarouselOptions= {
    items: 6,
    margin: 30,
    nav: false,
    autoplay: true,
    slideSpeed: 500,
    paginationSpeed: 400,
    grabCursor: true,
    loop: true,
    dots: true,
    responsive: {
        0: {
            items: 2,
            margin: 10
        },
        600:{
            items: 4,
            margin: 10
        },
        992: {
            items: 6
        }
    }
  }
  owlcarousel1: any = [];
  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.getPartners();
  }

  getPartners(){
    this.subscriptionService.mockedPartners().subscribe((resp) =>{
      this.owlcarousel1 = resp;
    })
  }

  counter = [
    // {
    //  img:"assets/images/app_landing1/about/Ellipse-3-copy.png",
    //  count: 'Souscription simple ',
    //  text: "La souscription est simple : à partir de votre téléphone mobile."
    // },
    // {
    //   img:"assets/images/app_landing1/about/close.png",
    //   count: 'Protéger sa famille ',
    //   text: "L’accident ne prévient pas. Mieux vaut être couvert et protéger."
    //  },
    //  {
    //   img:"assets/images/app_landing1/about/love.png",
    //   count: 'Indemnisation',
    //   text: "En 10 jours vous recevez votre indemnité."
    //  },
    //  {
    //   img:"assets/images/app_landing1/about/coffee.png",
    //   count: 'Couverture accident',
    //   text: "Vous êtes couvert en cas d’accident sur votre lieu de travail"
    //  },

  ]
}
