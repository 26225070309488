<div class="container-gauche bg-dark" *ngIf="login">
  <div class="mask">
  </div>
  <h2 class="text-center main-title" *ngIf="!mri && !mrh"><i>Veuillez choisir </i></h2>
  <h2 class="text-center main-title" *ngIf="mrh"><i>Devis Auto + MRH</i></h2>
  <h2 class="text-center main-title" *ngIf="mri"><i>Simulation MRH</i></h2>
  <!-- <img  src="../../../../../assets/images/car-d.png" alt="" > -->
  <div class="decoration-droite bg-dark"></div>


</div>
<div class="row container-row justify-content-center" *ngIf="login">
  <div class="col-12 droite">
    <div class="row">
      <div class="card  mrh  col-5" *ngIf="!mri && !mrh" (click)="choixmrh()">
        <div class="row g-0">
          <div class="col-md-4">
            <img src="../../../../../assets/images/vintage-1149558_1920.jpg" alt="..." class="img-fluid mrh-img" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title mrh-h5">Packs</h5>
              <p class="card-text">
                Faire un devis auto + mrh.
              </p>
              <!-- <p class="card-text">
                <small class="text-muted">Souscrire à une assurance habitation</small>
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card mri col-5" *ngIf="!mri && !mrh" (click)="choixmri()">
        <div class="row g-0">
          <div class="col-md-4">
            <img src="../../../../../assets/images/immeuble.jpg" alt="..." class="img-fluid mri-img" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title mrh-h5">Simulation</h5>
              <p class="card-text">
                Simuler une assurance automobile.
              </p>
              <!-- <p class="card-text">
                <small class="text-muted">Souscrire à une assurance immeuble</small>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="mrhForm" *ngIf="mri">
      <aw-wizard #wizard class="stepper-container pb-5" [navBarLocation]="'top'" [navBarLayout]="'large-empty'"
        [disableNavigationBar]="false">
        <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Maison">
          <div class="form-container" formGroupName="packForm1">
            <div class="row">
              <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select name="" id="cars" placeholder=" " class="form__select">
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                  <label for="cars" class="form__label">Email<span class="text-red"> *</span></label>              
                </div>
              </div> -->
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group" placement="top" ngbPopover="Qualité du souscripteur"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group">
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                    </div>
                    <select class="form-control" name="qualite_souscripteur" formControlName="qualite_souscripteur"
                      (change)="checkSouscripteur()">
                      <option value="" selected disabled>Qualité souscripteur </option>
                      <option value="proprietaire">Propriétaire</option>
                      <option value="locataire">Locataire</option>
                    </select>
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Qualité du souscripteur"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" 
                  formControlName="qualite_souscripteur" (change)="checkSouscripteur()">
                    <option value="" selected disabled>Qualité souscripteur </option>
                    <option value="proprietaire">Propriétaire</option>
                    <option value="proprietaire">Propriétaire non occupant</option>
                    <option value="locataire">Locataire</option>
                  </select>
                  <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>              
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('qualite_souscripteur').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <!-- nature du logement -->
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Qualité du souscripteur"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" name="nature_logement" (change)="checkNatureLogement($event)">
                    <!-- <option value="" selected disabled>Nature du logement</option> -->
                    <option value="maison" selected>Maison</option>
                    <option value="appartement">Appartement</option>
                  </select>
                  <label for="cars" class="form__label">Nature du logement <span class="text-red"> *</span></label>              
                </div>
                <!-- <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('qualite_souscripteur').invalid">
                  Ce champ est obligatoire
                </div> -->
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="natureLogement">
                <div class="form" placement="top" ngbPopover="Nombre d'étage de la maison"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="number" min="0" id="niveauImmeuble" class="form__input"
                  autocomplete="off" formControlName="niveauImmeuble" placeholder=" "/>     
                  <label for="niveauImmeuble" class="form__label">Nombre d'étage<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('niveauImmeuble').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!natureLogement">
                <div class="form" placement="top" ngbPopover="Le numéro d'étage de votre appartement"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="number" min="0" id="numeroEtage" class="form__input"
                  autocomplete="off" formControlName="numeroEtage" placeholder=" "/>
                  <label for="numeroEtage" class="form__label">Numéro d'étage<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('numeroEtage').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Nombre de pieces"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                    </div>
                    <select class="form-control" formControlName="pieces">
                      <option value="" disabled selected>Nombre de pièces</option>
                      <option value="2">F2</option>
                      <option value="3">F3</option>
                      <option value="4">F4</option>
                      <option value="5">F5</option>
                      <option value="6">F6</option>
                      <option value="7">F7</option>
                      <option value="8">F8</option>
                      <option value="9">F9</option>
                      <option value="10">F10</option>
                      <option value="11">F11</option>
                    </select>
                  </div>
                </div> -->
                <div class="form" placement="top" placement="top" ngbPopover="Nombre de pieces"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" formControlName="pieces">
                    <!-- <option value="" disabled selected>Nombre de pièces</option> -->
                      <option value="2">F2</option>
                      <option value="3">F3</option>
                      <option value="4">F4</option>
                      <option value="5">F5</option>
                      <option value="6">F6</option>
                      <option value="7">F7</option>
                      <option value="8">F8</option>
                      <option value="9">F9</option>
                      <option value="10">F10</option>
                      <option value="11">F11</option>
                  </select>
                  <label for="cars" class="form__label">Nombre de pièces<span class="text-red"> *</span></label>              
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('pieces').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"
                *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Valeur du batiement"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-building-o text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" mask="separator" class="form-control" formControlName="valeur_batiment"
                      placeholder="Valeur du batiment">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Valeur du batiement"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-building-o form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" mask="separator" id="valeur_batiment" class="form__input" autocomplete="off" placeholder=" " formControlName="valeur_batiment"/>
                  <label for="valeur_batiment" class="form__label">Valeur du batiment<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('valeur_batiment').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"
                *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='locataire'">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Loyer mensuel" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-building-o text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" mask="separator" class="form-control" formControlName="loyer_mensuel"
                      placeholder="Loyer mensuel">
                  </div>
                </div> -->
                <div class="form" ngbPopover="Loyer mensuel" triggers="mouseenter:mouseleave">
                  <i class="fa fa-building-o form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" mask="separator" id="loyer_mensuel" class="form__input" autocomplete="off" placeholder=" " formControlName="loyer_mensuel"/>
                  <label for="loyer_mensuel" class="form__label">Loyer mensuel<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('loyer_mensuel').invalid">
                  Ce champ est obligatoire
                </div>


              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Valeur du contenu"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-money text-white"></i>
                    </div>

                    <input type="text" class="form-control" mask="separator" formControlName="valeur_contenu"
                      placeholder="Valeur du contenu">
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Valeur du contenu"
                triggers="mouseenter:mouseleave">
                  <i class="fa fa-money form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" mask="separator" id="valeur_contenu" class="form__input" autocomplete="off" placeholder=" " mask="separator"
                  formControlName="valeur_contenu"/>
                  <label for="valeur_contenu" class="form__label">Valeur du contenu<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('valeur_contenu').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Surface développée"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                    </div>

                    <input type="text" class="form-control" mask="separator" formControlName="surface"
                      placeholder="Surface développée m2">
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Surface développée"
                triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" mask="separator" id="surface" class="form__input" autocomplete="off" placeholder=" "
                  formControlName="surface"/>
                  <label for="surface" class="form__label">Surface développée m2<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('surface').invalid">
                  Ce champ est obligatoire
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Début du contrat"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" class="form-control" placeholder="Date effet"
                      (dateSelect)="onDateSelect($event, 'packForm1','effet')"
                      [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="today" ngbDatepicker
                      #dateAss="ngbDatepicker" [value]="date" (click)="dateAss.toggle()" />

                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Début du contrat"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-clock-o form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <!-- | date: 'dd/MM/yyyy' -->
                  <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" "
                    (dateSelect)="onDateSelect($event, 'packForm1','effet')"
                      [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="today" ngbDatepicker
                      #dateAss="ngbDatepicker" [value]="mrhForm.get('packForm1').get('effet').value | date: 'dd/MM/yyyy'" (click)="dateAss.toggle()"/>
                  <label for="effet" class="form__label">Date effet<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('effet').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Adresse"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-map-marker text-white"> <span class="text-red">*</span></i>
                    </div>

                    <input type="text" class="form-control" formControlName="situation_risque"
                      placeholder="Adresse">
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Surface développée"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-map-marker form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="situation_risque" class="form__input" autocomplete="off" placeholder=" "
                  formControlName="situation_risque" />
                  <label for="situation_risque" class="form__label">Adresse<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('packForm1').get('situation_risque').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="choixinit()"><i
                class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                  class="fa fa-angle-double-right"></i></button>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Packs">
          <div class="container">
            <div class="row justify-content-center  p-2 pr-4">
              <h2 class="mb-5 p-2" id="title_base">Garanties de bases</h2>
              <div class="col-12"></div>
              <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Incendie
                et événements assimilés</div>
              <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Dégâts
                des eaux</div>
              <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Vol par
                agression et/ou effraction</div>
              <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Bris de
                glaces</div>
              <div class="col-6 text-left p-2 mrhgarantie"><i
                  class="fa fa-check-circle mr-2 text-success"></i>Responsabilité civile</div>
              <div class="col-6 text-left p-2 mrhgarantie mb-5"><i
                  class="fa fa-check-circle mr-2 text-success"></i>Défense et recours</div>
            </div>
          </div>
          <div class="container">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" style="background:hsl(218, 60%, 32%)">
                <!-- <a class="nav-link active" data-toggle="tab" href="{{ass?.assureur?.nom}}">{{ass?.assureur?.nom}}</a> -->
                <a class="nav-link active" data-toggle="tab" href="Default_packs">Garanties optionnels</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="Default_packs" class="container tab-pane active"><br>
                <!-- <h3>{{ass?.assureur?.nom}}</h3> -->
                <div class="row pack-row">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pack-col mt-4" *ngFor="let sp of packofpack">
                    <!-- <div class="box-part text-center" (click)="devispack(sp)" placement="top" ngbPopover="Cliquer pour choisir un pack" triggers="mouseenter:mouseleave" popoverTitle="" *ngIf="sp.assureur.code =='NSIA'"> -->
                    <div class="box-part text-center c-box-part" placement="top"
                      ngbPopover="Cliquer pour sur le cercle choisir un pack" triggers="mouseenter:mouseleave"
                      popoverTitle="">
                      <input type="checkbox" name="sub_package" value="sp.typeGaranti.id"
                        (change)="onChangeCategory($event, sp.id,sp)" class="regular-checkbox" /> <br>
                      <!-- <i class="fa fa-twitter fa-3x" aria-hidden="true"></i> -->
                      <img class="heart" [src]="'assets/images/garantsMrh/'+sp.id+'.jfif'" alt="">
                      <div class="title c-title">
                        <h4>{{sp?.libelle}}</h4>
                      </div>
                      <div class="mrh2-btn">
                        <a (click)="readmore(sp.id)" class="btn btn-large text-white"
                          id="more{{sp.id}}">Lire plus...</a>
                        <a (click)="lessmore(sp.id)" class="btn btn-large  tex-white less"
                          id="less{{sp.id}}">Moins...</a>
                      </div>

                      <div class="text" id="mrh{{sp.id}}" class="mrh2 mt-5">
                        <h6>Avantages:</h6>
                        <!-- <p>La couverture de la responsabilité civile.</p> -->
                        <p>{{sp?.description}}</p>
                        <!-- <span>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span> -->
                      </div>
                      <!-- <a href="#"><b>{{sp?.primeTtc}} </b></a> -->

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chargement" *ngIf="sendingRequest">
            <i class="text-center fa fa-spinner fa-pulse" ></i>
              Chargement des assureurs en cours ...
          </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest"
                (click)="devis()">Suivant <i class="fa fa-angle-double-right"></i></button>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur" (stepEnter)="enterStep($event)" *ngIf="false">
          <!-- <h2>Choisir votre assureur</h2> -->
          <div class="container">
            <h6 *ngIf="!sendingRequest && this.resultass.length !== 0">Veuillez choisir votre assureur pour obtenir votre devis</h6>
            <h6 *ngIf="!sendingRequest && this.resultass.length === 0" class="text-center">Désolé y'a pas d'assureur disponible pour votre choix !!!</h6>
            <div class="row justify-content-center" *ngIf="!sendingRequest">

              <!-- <div class="row"> -->
              <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12" *ngFor="let res of resultass">
                <div id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave"
                  popoverTitle="{{res.code}}">
                  <div class="card rounded " (click)="getprix(res.id,res)">
                    <div class="card-image">
                      <img class="img-fluid" [src]="res.img" alt="{{res.nom}}" height="177px" />
                    </div>
                    <div class="c-card-ass my-name text-center">
                      <h6 class="nom">{{res.nom}}</h6>
                    </div>
                    <div class="c-card-ass my-price text-center">
                      <h6 class="nom">Prix:&nbsp; <span *ngIf="res.prix"> {{res.prix |number:'1.0-0':'fr'}} &nbsp;
                          FCFA</span> <span *ngIf="!res.prix" class="fa fa-spinner fa-pulse"> </span></h6>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="text-center" *ngIf="devisend">
              <i class="fa fa-spinner fa-pulse display-2"></i><br>
              <p class="text-center">Chargement devis en cours...</p>
            </div>
            <div class="noAssureur" *ngIf="sendingRequest">
              <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
              <p class="text-center text-ass">Chargement des assureurs en cours...</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-5 col-xs-12 col-sm-12">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn ml-0" awPreviousStep (click)="retour()" *ngIf="!devisend"><i
                  class="fa fa-chevron-left"></i></button>
            </div>
          </div>
              
          <!-- <button type="button" class="btn btn-primary btn-phone pull-right c-roundbtn" (click)="mail()" *ngIf="!devisend">Me
            faire rappeler <i class="fa fa-phone" style="font-size: 1.5rem;"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right"   awNextStep>Suivant <i class="fa fa-angle-double-right"></i></button> -->

        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assuré">
          <div class="form-container" formGroupName="assure">
            <div class="row assurance-for justify-content-right">
              <!-- <h6 class="col-3">Je  souscris ?</h6> -->
              <div class="radio-toolbar col-12">
                <input type="radio" id="moi" name="radioFruit" value="1" checked>
                <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                <input type="radio" id="tiers" name="radioFruit" value="2">
                <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
              </div>
            </div>
            <div class="row no-margin">
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="prenomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                  <label for="prenomAssure" class="form__label">Prénom<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('prenom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="nomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                  <label for="nomAssure" class="form__label">Nom<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('nom').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                  <i class="fa fa-phone form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="numeroTelephone" prefix="+221 " [mask]="phoneMask" class="form__input" autocomplete="off" placeholder=" " formControlName="numeroTelephone"/>
                  <label for="numeroTelephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('assure').get('numeroTelephone').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                  <i class="fa fa-envelope form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="emailAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                  <label for="emailAssure" class="form__label">Email<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('email').invalid">
                  Ce champ est invalide
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="profession" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                  <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                  <label for="secteuractivite" class="form__label">Activité<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('assure').get('secteuractivite').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <div class="input-group" placement="top"
                    ngbPopover="Veuillez renseigner le numéro de carte d'identité" triggers="mouseenter:mouseleave"
                    popoverTitle="CNI">
                    <div class="input-group-append">
                      <i class="fa  fa-vcard text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" class="form-control" [mask]="cinMask" formControlName="numeroCni"
                      placeholder="Numéro CNI">
                  </div>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('numeroCni').invalid">
                  Ce champ est obligatoire ou invalide
                </div>
              </div>
            
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                    triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                    <div class="input-group-append">
                      <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" class="form-control" [maxDate]="today"
                      [minDate]="{year: 1900, month: 1, day: 1}"
                      (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                      #dateAsss="ngbDatepicker" (click)="dateAsss.toggle()" placeholder="Date de naissance" value=""
                      name="dateNaissance">

                  </div>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('assure').get('dateNaissance').invalid">
                  Ce champ est obligatoire
                </div>
              </div> -->
              <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Veuillez sélectionner la latéralité"
                    triggers="mouseenter:mouseleave" popoverTitle="Latéralité">
                    <div class="input-group-append">
                      <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                    </div>
                    <select class="form-control" name="lateralite" formControlName="lateralite">
                      <option value="" disabled selected>Latéralité</option>
                      <option value="droitier">Droitier</option>
                      <option value="gaucher">Gaucher</option>
                    </select>
                  </div>
                </div>
                <div class="error min-max text-red"
                  *ngIf="invalid && mrhForm.get('assure').get('lateralite').invalid">
                  Ce champ est obligatoire
                </div>
              </div> -->
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-intersex text-white"> <span class="text-red">*</span></i>
                    </div>
                    <select class="form-control" formControlName="genre">
                      <option value="" selected disabled>Sexe</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Sexe"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-intersex form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <select id="cars" placeholder=" " class="form__select" name="genre" 
                  formControlName="genre">
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <label for="cars" class="form__label">Sexe <span class="text-red"> *</span></label>              
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('genre').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <!-- <div class="form-group">
                  <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                    </div>
                    <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                  </div>
                </div> -->
                <div class="form" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                  <i class="fa fa-home form__icon"></i>
                  <div class="form__separator">
                  </div>
                  <input type="text" id="adresseAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                  <label for="adresseAssure" class="form__label">Adresse<span class="text-red"> *</span></label>
                </div>
                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('adresse').invalid">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest"
                (click)="assuree()">Suivant</button>
            </div>
          </div>

          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest || souscriptionForm.get('souscripteur').invalid" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right"  awNextStep>Suivant <i class="fa fa-chevron-right"></i></button> -->
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">

          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="mrhForm.get('livraison').value == 'Domicile'">
              <div class="badge">
                Domicile
              </div>
              <img width="135px" src="assets/images/domicile.jpg" alt="">
            </div>
            <div class="mt-4 badge-content" (click)="relais()" [class.selected]="mrhForm.get('livraison').value == 'Relais'">
              <div class="badge" >
                Point relais
              </div>
              <img width="135px" src="assets/images/relais.jfif" alt="">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <!-- <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                </div>
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div> -->
            <div class="form" placement="top" ngbPopover="Point de relais"
              triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <select placeholder=" " class="form__select" id="mySelect" 
                formControlName="pointRelaisControl" >
                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>              
            </div>
            <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="row mb-4 justify-content-center">

            <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
            <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="mrhForm.get('moyenDePaiement').value == pay.code">
              <div class="badge">
                {{pay?.nom}}
              </div>
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant</button>
            </div>
          </div>
          
        </aw-wizard-step>
        <!--  -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Paiement">
          <!-- <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="mrhForm.get('livraison').value == 'Domicile'">
              <div class="badge" >
                Domicile
              </div>
              <img width="135px" src="assets/images/domicile.jpg" alt="">
            </div>
            <div class="mt-4 badge-content" (click)="relais()" [class.selected]="mrhForm.get('livraison').value == 'Relais'">
              <div class="badge" >
                Point relais
              </div>
              <img width="135px" src="assets/images/relais.jpg" alt="">
            </div>
          </div> -->
          <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <div class="form-group">
              <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                   triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                </div>
                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div> -->
          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
            <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="mrhForm.get('livraison').value == 'Domicile'">
              <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
              <div class="badge" >
                Domicile
              </div>
              <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
            </div>
            <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="mrhForm.get('livraison').value == 'Relais'">
              <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
              <div class="badge" >
                Point relais
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
               *ngIf="pointRelais?.length > 0 ||  pointRelais">
            <div class="form mt-5" placement="top" ngbPopover="Point de relais"
              triggers="mouseenter:mouseleave">
              <i class="fas fa-map-marked-alt form__icon"></i>
              <div class="form__separator">
              </div>
              <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl" >
                <option value="" selected disabled>Sélectionner un point de relais</option>
                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
              <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>              
            </div>
            <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
            <div class="form" ngbPopover="AdresseLivraison"
                 triggers="mouseenter:mouseleave">
              <i class="fa fa-home form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" "/>
              <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
            </div>
            <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('adresseLivraison').invalid">
              Ce champ est obligatoire
            </div>
          </div>

          <div class="row mb-4 justify-content-center">
            <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
            <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="mrhForm.get('moyenDePaiement').value == pay.code">
              <!-- <input type="checkbox" name="sub_package" value="sp.typeGaranti.id"
                        (change)="onChangeCategory($event, sp.id,sp)" class="regular-checkbox" /> <br> -->
              <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
              <div class="badge">
                {{pay?.nom}}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
          <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

        </aw-wizard-step>
        <!--  -->

        <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif">
          <div class="recap-form-container">
            <div class="mesImage">
              <div class="imgLogo">
                <img [src]="currentAssureur.img" alt="">
              </div>
            </div>
            <div class="info-devis">
              <div class="proposition">
                <div class="pro-title"> Proposition MRH</div>
                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                </div>
              </div>
              <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    Références du souscripteur
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave"
                      (click)="openModal(modalAssure)"></span>
                      <!-- contentResultsign -->
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('prenom').value}}
                        {{mrhForm.get('assure').get('nom').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('email').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('numeroTelephone').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('adresse').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('profession').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('secteuractivite').value}} </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span>Références Proposition</span>

                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave"
                      (click)="goTo(3)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                      </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('prenom').value}} {{mrhForm.get('assure').get('nom').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                        {{mrhForm.get('assure').get('adresse').value}} </div>
                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="mrhForm.get('packForm1').get('effet').value!=''">
                        {{mrhForm.get('packForm1').get('effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à
                        Minuit
                      </div>

                      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 ans </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                  <div class="title-div">
                    <span> Identification de la maisson </span>
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave"
                      (click)="goTo(0)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Qualité souscripteur</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'?'Propriétaire':"Locataire"}}
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre de pièces</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            F {{mrhForm.get('packForm1').get('pieces').value}} </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                            *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">Valeur du
                            batiment</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                            *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
                            {{mrhForm.get('packForm1').get('valeur_batiment').value | number:'1.0-0':'fr'}} </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                            *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">Loyer
                            mensuel</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                            *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">
                            {{mrhForm.get('packForm1').get('loyer_mensuel').value | number:'1.0-0':'fr'}} </div>


                        </div>
                      </div>

                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Valeur du contenu</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{mrhForm.get('packForm1').get('valeur_contenu').value | number:'1.0-0':'fr'}} </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Surface à développée</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{mrhForm.get('packForm1').get('surface').value}} m²
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                            {{mrhForm.get('packForm1').get('situation_risque').value}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="proposition2">
                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
              </div>
              <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    <span> Nature des garanties </span>
                    <span class="fa fa-edit edit-pro" placement="top"
                      ngbPopover="Cliquer pour modifier les infos des garanties" triggers="mouseenter:mouseleave"
                      (click)="goTo(1)"></span>
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Responsabilité civile
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Incendie et événements assimilés
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Vol par agression et/ou effraction
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Dégâts des eaux
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Bris de glaces
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        Défense et recours
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"
                        *ngFor="let garantie of  granddevis?.garanties">
                        {{garantie.libelle}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                  <div class="title-div">
                    Capitaux garanties
                  </div>
                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                        Gratuit
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val"
                        *ngFor="let garantie of  granddevis?.garanties">
                        <span> {{garantie.primeTtc | number:'1.0-0':'fr'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
                de <b>
                  {{prix | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
              </div>
              <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                  <div class="contenu-div">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                        <span>Prime nette</span>
                        <div *ngFor="let item of granddevis?.garanties">
                          <span>{{item.libelle}}</span>

                        </div>
                        <span class="valPrime">Prime totale TTC</span>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <span>{{this.base | number:'1.0-0':'fr'}} FCFA</span>
                        <div class="val" *ngFor="let item of granddevis?.garanties">
                          <span>{{item.primeTtc | number:'1.0-0':'fr'}} FCFA</span>

                        </div>
                        <span class="valPrime val">{{prix | number:'1.0-0':'fr'}}
                          FCFA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="disposition">
                <div class="title-disp">
                  Dispositions complémentaires
                </div>
                <div class="desc-disp">
                  La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                  disposition de
                  l'article 13
                  <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                    générales
                    jointes, les conditions particulières ainsi que les closes en annexe.</div>
                  <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                    disposition des conditions générales (CG) qui leurs sont contraires.</div>
                  <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                    visas de
                    l'assureur et du souscripteur. </div>
                </div>
              </div>
            </div>
            <div class="footer-devis row">
              <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div class="fist-line">
                  <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                  conseil
                  d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                    number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                  <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                  code
                  des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                </div>
                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                  &nbsp;<b>{{currentAssureur.email}}</b> </div>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                <img class="footer-img" [src]="currentAssureur.img" alt="">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5">
              <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
            </div>
            <div class="col-5">
              <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2"
                [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </form>

  </div>
</div>
<div class="amsa-form-page">

</div>

<div *ngIf="!login">
  <!-- <img src="../../../../../assets/images/devis.png" alt="" class="img-1" (click)="choixmrh()" @slideInOut> -->
  <!-- <img src="../../../../../assets/images/mrh.png" alt="" class="img-2" @slideInOut> -->
  <!-- <img src="../../../../../assets/images/banner1-1.png" alt="" class="img-3" @slideInOut> -->
  <!-- <img src="../../../../../assets/images/counter-bg.png" alt="" class="img-4"> -->
  <section class="section1 ">
    <div class="row justify-content-center pres">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 photo-auto container">
        <h3 class="text-left title-ass" style="color: #204481;" @fade> Assurance MRH</h3>
        <p class="mt-5 description" style="color: black;" @fade>
          Une assurance habitation est une assurance destinée aux habitations de particulier et leurs annexes. Son objet
          principal est de couvrir les locaux, leur contenu et la responsabilité civile de ses occupants.
        </p>
        <!-- <a class="btn btn-primary btn-large Souscrire" (click)="souscrire()">Souscrire</a> -->
        <div class="Souscrire" (click)="souscrire()">
          <div class="simulation" @slideInOut>
            <div class="container">
              <div class="left-side">
                <div class="card">
                  <div class="card-line"></div>
                  <div class="buttons"></div>
                </div>
                <div class="post">
                  <div class="post-line"></div>
                  <div class="screen">
                    <div class="dollar">$</div>
                  </div>
                  <div class="numbers"></div>
                  <div class="numbers-line2"></div>
                </div>
              </div>
              <div class="right-side">
                <div class="new">Faire une simulation</div>

                <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="512" height="512"
                  viewBox="0 0 451.846 451.847">
                  <path
                    d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"
                    data-original="#000000" class="active-path" data-old_color="#000000" fill="#cfcfcf" />
                </svg>
                <!-- <i class="fa fa-credit-card bg-white" style="font-size: 2rem;"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <img src="../../../../../assets/images/mrh.jpg" alt="" class="img-4" @slideInOut>
      </div>
    </div>
  </section>
  <!-- HTML Setup -->
  <!-- <section class="section2">

    <div class="container">
      <div class="row">			
        <div class="mx-auto">
          <div class="col-lg-12">
            <div class="text-center my-3">
              <h2 class="text-center my-5">Garanties</h2>						
              <div class="card-deck no-gutters">

                <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-5" *ngFor="let garantie of garanties">
                  <div class="card card1 h-100 mb-4" >                                                 
                      <div class="box-part text-center" placement="top"
                            ngbPopover="{{garantie?.description}}" triggers="mouseenter:mouseleave" popoverTitle="{{garantie?.name}}"> -->

  <!-- <i class="fa fa-twitter fa-3x" aria-hidden="true"></i> -->
  <!-- <img class="heart" src="assets/images/mrh.png" alt="">   
                              <div class="title">
                                <h4 style="color: #2e8746 !important;">{{garantie?.nom}}</h4>
                              </div>
                                          
                              <div class="text">
                                <h6 >Avantages:</h6>
                                <p >{{garantie?.description}}</p> -->
  <!-- <p ng-bind-html="">{{garantie?.description}}</p> -->
  <!-- <p>Les frais de réparation des dommages que vous pourriez causer à autrui</p> -->
  <!-- <span>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span> -->
  <!-- </div>
                                          
                              <a href="#" class="mb-5"><b>Prix</b></a>
                              <div class="promotion-price">
                                <div class="promotion-price-desc"> À partir de  </div>
                                <div class="promotion-price-text" >{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA</div>                                    
                              </div> 
                                    
                            </div>
                            
                          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section> -->
</div>
<ng-template #contentResult let-modal>
  <div class="modal-header bg-white">
    <h4 class="modal-title" id="modal-basic-title">Devis</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-white">
    <div class="cotainer devis-container">
      <div class="row row-devis">
        <div class="col-12">
          <div class="row row-title-devis align-items-center">
            <div class="col-6 img-logo">
              <img src="../../../../assets/images/logo6.png" alt="">
            </div>
            <div class="col-6 ">
              <div class="row Informations">
                <div class="col-12">
                  <p>Tel: +221 33 860 60 67</p>
                </div>
                <div class="col-12">
                  <p>Email: info@optima.com</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-subtitle-devis ">
            <div class="col-3 justify-content-center bg-primary">
              <!-- <div class="row align-items-center">
                  <div class="col-12 devis-text">
                    Devis
                  </div>
                </div> -->
              <p class="devis-text">Devis</p>
            </div>
            <div class="col-9">
              <div class="row assure">
                <div class="col-12 assure-title">
                  MRH
                </div>
                <div class="col-12 assure-text">
                  <div class="row align-items-center ">
                    <div class="col-8">
                      <p>Prime Auto</p>
                    </div>
                    <div class="col-4">{{result?.prime_auto | number :'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>Prime Habitation</p>
                    </div>
                    <div class="col-4">{{result?.prime_habitation | number:'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>Taxe</p>
                    </div>
                    <div class="col-4">{{result?.taxe | number:'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>FGA</p>
                    </div>
                    <div class="col-4">{{result?.fga | number:'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>Cout police</p>
                    </div>
                    <div class="col-4">{{result?.cout_police | number:'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>Bonus</p>
                    </div>
                    <div class="col-4">{{result?.bonus | number:'1.0-0' : 'fr' }} FCFA</div>
                    <div class="col-8">
                      <p>Prime RC</p>
                    </div>
                    <div class="col-4">{{result?.rcrti | number:'1.0-0' : 'fr' }} FCFA</div>
                  </div>
                </div>
              </div>
              <div class="row assure">
                <div class="col-12 assure-title">
                  Totale
                </div>
                <div class="col-12 assure-text">
                  <div class="row align-items-center ">
                    <div class="col-8">
                      <p>Prime Totale</p>
                    </div>
                    <div class="col-4">{{result?.prime_total | number:'1.0-0' : 'fr' }} FCFA</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button>
    <button type="button" class="btn btn-success btn-next pull-right"  [disabled]="sendingRequest"  awNextStep>Suivant</button> -->

  </div>
  <div class="modal-footer bg-white">
    <button type="button" class="btn btn-outline-dark c-roundbtn" (click)="modal.close('close')">Fermer</button>
    <button type="button" class="btn btn-success c-roundbtn" (click)="modal.close('subscribe')">Souscrire</button>
  </div>

</ng-template>
<!-- <ng-template #contentResultass let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Devis</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="cotainer devis-container">
      <div class="row row-devis">
        <div class="col-12">
          <div class="row row-title-devis align-items-center">
            <div class="col-6 img-logo">
              <img src="../../../../assets/images/logo6.png" alt="">
            </div>
            <div class="col-6 ">
              <div class="row Informations">
                <div class="col-12"><p>Tel: +221 33 860 60 67</p> </div>
                <div class="col-12"><p>Email: info@optima.com</p> </div>
              </div>
            </div>
          </div>
          <div class="row row-subtitle-devis "> -->
<!-- <div class="col-3 justify-content-center bg-primary"> -->
<!-- <div class="row align-items-center">
                  <div class="col-12 devis-text">
                    Devis
                  </div>
                </div> -->
<!-- <p class="devis-text">Devis</p>
            </div> -->
<!-- <div class="col-9">
                <div class="row assure">
                    <div class="col-12 assure-title">
                      {{granddevis.code}}: MRH
                    </div>
                    <div class="col-12 assure-text">
                      <div class="row align-items-center ">
                        <div class="col-8"><p>Surface</p> </div><div class="col-4">{{this.mrhForm.get('packForm').get('surface').value}} m²</div>
                        <div class="col-8"><p>Nombre de pieces</p> </div><div class="col-4">{{this.mrhForm.get('packForm').get('pieces').value }} piece(s)</div>
                        <div class="col-8"><p>Valeur batiment</p> </div><div class="col-4">{{valeur | number:'1.0-0' : 'fr'   }} FCFA</div>
                        <div class="col-8"><p>Capital contenu</p> </div><div class="col-4">{{capital| number:'1.0-0' : 'fr'   }} FCFA</div>
                      </div>
                    </div>
                    <div class="col-12">
                        <div class="row   p-2 pr-4">
                          <h2 class="mb-5 p-2 m-auto mb" id="title_base" >Garanties</h2>
                          <div class="col-12"></div>
                          <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Incendie et événements assimilés</div>
                          <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Dégâts des eaux</div>
                          <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Vol par agression et/ou effraction</div>
                          <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Bris de glaces</div>
                          <div class="col-6 text-left p-2 mrhgarantie"><i class="fa fa-check-circle mr-2 text-success"></i>Responsabilité civile</div>
                          <div class="col-6 text-left p-2 mrhgarantie mb-5"><i class="fa fa-check-circle mr-2 text-success"></i>Défense et recours</div>
                          <div class="col-6 text-left p-2 mrhgarantie mb-5" *ngIf="packmrh"><i class="fa fa-check-circle mr-2 text-success"></i>{{packmrh}}</div> -->
<!-- </div>
                    </div>
                </div>
                <div class="row assure">
                  <div class="col-12 assure-title">
                     Decomposition devis
                  </div>
                  <div class="col-12 assure-text">
                    <div class="row align-items-center " *ngFor="let item of granddevis?.garanties">
                      <div class="col-8"><p>{{item.libelle}}</p> </div><div class="col-4">{{item.primeTtc | number:'1.0-0' : 'fr'   }} FCFA</div>
                    </div>
                    <div class="row align-items-center ">
                      <div class="col-8"><p style="font-size: 800;color: #28a745;">Prime Totale Ttc</p> </div><div class="col-4" style="font-size: 800;color: #28a745;">{{prix | number:'1.0-0' : 'fr'   }} FCFA</div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
<!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button>
    <button type="button" class="btn btn-success btn-next pull-right"  [disabled]="sendingRequest"  awNextStep>Suivant</button> -->

<!-- </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" >Fermer</button>
    <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
  </div>

</ng-template> -->
<ng-template #contentResultcontact let-modal>
  <div class="modal-header" id="modal-header">
    <h5 class="modal-title" id="modal-basic-title" id="modal-title">Se faire contacter</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body " id="modal-body">
    <form [formGroup]="personcontactForm">
      <div class="form-container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-append" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                </div>
                <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                <input type="text" class="form-control" formControlName="first_name" placeholder="Prénom">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('first_name').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                </div>
                <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                <input type="text" class="form-control" formControlName="last_name" placeholder="Nom">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('last_name').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                </div>
                <!-- <label for="telephone">Téléphone <span class="text-red">*</span></label> -->
                <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask" formControlName="telephone"
                  placeholder="Téléphone">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('telephone').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> </i>
                </div>
                <!-- <label for="adresse">Adresse</label> -->
                <input type="text" class="form-control" formControlName="email" placeholder="Email">
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('email').hasError('pattern')">
              Ce champ est invalide
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Jour disponible" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                </div>
                <ng-select multiple="true" class="select-rappel" placeholder="Jour à être rappeler"
                  formControlName="jour" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé"
                  [dropdownPosition]="'bottom'">
                  <ng-option *ngFor="let jour of listJours" [value]="jour.name"> {{jour.name}} </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('jour').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Heure disponible" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                </div>
                <ng-select multiple="true" class="select-rappel" placeholder="Heure à être rappeler"
                  formControlName="heure" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé"
                  [dropdownPosition]="'bottom'">
                  <ng-option *ngFor="let heure of listHeures" [value]="heure.name"> {{heure.name}} </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('heure').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
        </div>
      </div>
      <!-- <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep (click)="retour()"><i
            class="fa fa-chevron-left"></i></button>
        <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription">
          <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Souscrire <i
            class="fa fa-angle-double-right"></i></button> -->
      <button type="button" class="btn btn-danger" #closeBTN (click)="modal.close('close')">Fermer</button>
      <button type="button" class="btn btn-success" [disabled]="loadContact" (click)="saveContact()"> <span
          *ngIf="loadContact" class="fa fa-spinner fa-pulse"></span> Envoyer</button>
    </form>
  </div>
  <!-- <div class="modal-footer">

  </div> -->

</ng-template>
<button id="samabtn" hidden (click)="captureScreen()"></button>
<ng-template #contentResultass let-modal>
  <div #mrhDownload id="mrhDownload">
    <div class="modal-body p-5 bg-white">
      <div class="mesImage">
        <div class="imgLogo">
          <img [src]="currentAssureur.img" alt="">
        </div>
      </div>
      <div class="info-devis">
        <div class="proposition">
          <div class="pro-title"> Proposition MRH</div>
          <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
        </div>
        <div class="row ">
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
            <div class="title-div">
              Références du souscripteur
            </div>
            <div class="contenu-div">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                  {{user.infos.firstName}}
                  {{user.infos.lastName}} </div>
                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                  {{user.infos.email}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                  {{user.infos.telephone}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                  {{user.infos.adresse}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                  {{user.infos.profession}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                  {{user.infos.secteuractivite}} </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
            <div class="title-div">
              <span> Références Proposition</span>
            </div>
            <div class="contenu-div">
              <div class="row">


                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                </div>
                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                  {{user.infos.firstName}} {{user.infos.lastName}} </div>
                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                  {{user.infos.adresse}} </div>
                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                  {{mrhForm.get('packForm1').get('effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                </div>

                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 ans </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>

                <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Qualité souscripteur</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'?'Propriétaire':"Locataire"}} </div> -->
                <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Mouvement</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> Affaire nouvelle  </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Barème</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> Temporaire  </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type</div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> Mono  </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
            <div class="title-div">
              Identification de la maisson
            </div>
            <div class="contenu-div">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Qualité souscripteur</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                      {{mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'?'Propriétaire':"Locataire"}}
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre de pièces</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                      F{{mrhForm.get('packForm1').get('pieces').value}} </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                         *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">Valeur du
                      batiment</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                         *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
                      {{mrhForm.get('packForm1').get('valeur_batiment').value | number:'1.0-0':'fr'}} </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name"
                         *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">Loyer mensuel
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"
                         *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">
                      {{mrhForm.get('packForm1').get('loyer_mensuel').value | number:'1.0-0':'fr'}} </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Valeur du contenu</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                      {{mrhForm.get('packForm1').get('valeur_contenu').value | number:'1.0-0':'fr'}} </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Surface à développée</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                      {{mrhForm.get('packForm1').get('surface').value}} m²
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                      {{mrhForm.get('packForm1').get('situation_risque').value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="proposition2">
          <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
        </div>
        <div class="row c-row">
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
            <div class="title-div">
              Nature des garanties
            </div>
            <div class="contenu-div">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Responsabilité civile
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Incendie et événements assimilés
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Vol par agression et/ou effraction
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Dégâts des eaux
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Bris de glaces
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  Défense et recours
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of  granddevis?.garanties">
                  {{garantie.libelle}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
            <div class="title-div">
              Capitaux garanties
            </div>
            <div class="contenu-div">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                  Gratuit
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val" *ngFor="let garantie of  granddevis?.garanties">
                  <span> {{garantie.primeTtc | number:'1.0-0':'fr'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="description-ass">
          Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
          {{prix | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
        </div>
        <div class="row ">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

            <div class="contenu-div">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                  <div>
                    <span>Prime nette</span>
                  </div>
                  <div *ngFor="let item of granddevis?.garanties">
                    <span>{{item.libelle}}</span>

                  </div>
                  <span class="valPrime">Prime totale TTC</span>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                  <div class="val">
                    <div>
                      <span>{{this.base | number:'1.0-0':'fr'}} FCFA</span>
                    </div>
                    <div *ngFor="let item of granddevis?.garanties">
                      <span >{{item.primeTtc | number:'1.0-0':'fr'}} FCFA</span>
                    </div>
                    <span class="valPrime">{{prix | number:'1.0-0':'fr'}}
                      FCFA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="disposition">
          <div class="title-disp">
            Dispositions complémentaires
          </div>
          <div class="desc-disp">
            La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de
            l'article 13
            <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales
              jointes, les conditions particulières ainsi que les closes en annexe.</div>
            <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
              disposition des conditions générales (CG) qui leurs sont contraires.</div>
            <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de
              l'assureur et du souscripteur. </div>
          </div>
        </div>
      </div>
      <div class="footer-devis row">
        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
          <div class="fist-line">
            <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil
            d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                  number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
            <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code
            des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
          </div>
          <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
            &nbsp;<b>{{currentAssureur.email}}</b> </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
          <img class="footer-img" [src]="currentAssureur.img" alt="">
        </div>
      </div>
    </div>

  </div>
  <div>
    <div class="row">
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" (click)="modal.close('close')"><i
          class="fa fa-chevron-left"></i></button>
      </div>
      <div class="col-4 text-center">
        <button #btnDownload id="btnDownload" type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()" placement="left"
        ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave">Télécharger <i class="fa fa-download"></i></button>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')">
          Souscrire <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?"
  icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveDatamrh()">
</swal>
<swal #confirmSubscription title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question"
  [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>
<swal #confirmSubscription title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question"
  [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>
<ng-template #contentResultlogin let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Déclarer sinistre</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <div class="modal-body login">
    <form [formGroup]="loginform">
      <div class="container px-4 py-5 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3">
                    <!-- <img id="logo" src="../../../../../assets/images/logo.png"> </div> -->
                    <!-- <h6 class="msg-info">Veuillez renseigner votre email ou votre nom d'utilisateur et votre mot de passe!
                  </h6> -->
                    <h3 class="monCompte"> Mon compte!</h3>
                    <p class="compteText"> Connectez vous à votre compte SVP.</p>
                  </div>
                  <!-- <div class="form-group"> <label class="form-control-label text-muted">Nom d'utilisateur</label> <input
                      type="text" id="email" name="email" class="form-control" formControlName="username">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis</div>
                  </div> -->
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="username" class="form__input" autocomplete="off" placeholder=" " formControlName="username"/>
                    <label for="username" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                 </div>
                 <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis
                  </div>
                  <!-- <div class="form-group "> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="password">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis</div>
                  </div> -->
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fas fa-lock form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password"/>
                    <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                    <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>           
                    <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>           
                  </div>  
                  <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis
                  </div>
                  <div class="forgetMdp">
                    <div class="resterConnecter">
                      <input type="checkbox">
                      <span> Restez connecter</span>
                    </div>
                    <div class="updateMdp">Mot de passe oublié?</div>
                  </div>
                  <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                  <div> <button class="seConnecter"
                      (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Connexion en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#" sm-text><small class="text-danger"
                        [disabled]="loaderVisible">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>
              <div class="bottom text-center mb-3">
                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2"
                    (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
              </div>
            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="limiter">
      <div class="container-login100" style="background-image: url('images/bg-01.jpg');">
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form  [formGroup]="loginform" class="">
             <div class="row">
               <div class="col-8">

               </div>
               <div class="col-12">

               </div>
               <div class="col-12">

              </div>
             </div>
          </form>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <div class="modal-footer">

  </div> -->

</ng-template>
<ng-template #contentResultsign let-modal>
  <div class="modal-body login">
    <form [formGroup]="inscriptionForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-2">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                  <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6>

                  <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                      type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                      Le champs email est requis</div>
                    <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est
                      incorrect</div>
                  </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone <span
                        class="text-red">*</span> </label> <input type="text" id="tel" placeholder=""
                      class="form-control" formControlName="tel" prefix="+221 " [mask]="phoneMask">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                      Le champs téléphone est requis</div>
                  </div>
                  <!-- <div class="form-group"> <label class="form-control-label text-muted" >Mot de passe</label> <input type="password" id="psw"  placeholder="" class="form-control" formControlName="password" >
                          <div class="text-red ml-3" *ngIf="inscriptionForm.get('password')?.errors?.required && (inscriptionForm.get('password')?.touched || inscriptionForm.get('password')?.dirty)">Le champs mot de passe est requis</div>
                        </div> -->

                  <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Inscription en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>
              <div class="bottom text-center mb-1">
                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2"
                    (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
              </div>
            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="limiter">
      <div class="container-login100" style="background-image: url('images/bg-01.jpg');">
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form  [formGroup]="loginform" class="">
             <div class="row">
               <div class="col-8">

               </div>
               <div class="col-12">

               </div>
               <div class="col-12">

              </div>
             </div>
          </form>
        </div>
      </div>
    </div> -->
  </div>
</ng-template>
<ng-template #contentResultforgot let-modal>
  <div class="modal-body login">
    <form [formGroup]="forgotForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="Password"> </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="password"> </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="password"> </div>

                  <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Réinitialisation en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>

            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- Assuré -->
<ng-template #updateAssures let-modal>
  <form [formGroup]="mrhForm">
    <div class="container px-4 py-2 mx-auto">
      <div class="card card0 c-card">
        <div class="row beneficiary-section form-container " formGroupName="assure">
          <div class="row no-margin">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
              <div class="row no-margin">
                <div class="col-10 p-0">
                  <h6 class="text-left text-white c-cardTitle">Ajouter assurés</h6>
                </div>
                <div class="col-lg-2 text-right">
                  <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="prénom" triggers="mouseenter:mouseleave">
                <i class="fa fa-user form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('prenom').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                <i class="fa fa-user form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('nom').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                <i class="fa fa-phone form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="numeroTelephone" prefix="+221 " [mask]="phoneMask" class="form__input" autocomplete="off" placeholder=" " formControlName="numeroTelephone"/>
                <label for="numeroTelephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('assure').get('numeroTelephone').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                <i class="fa fa-envelope form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('email').invalid">
                Ce champ est invalide
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="profession" triggers="mouseenter:mouseleave">
                <i class="fa fa-user form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                <i class="fa fa-user form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                <label for="secteuractivite" class="form__label">Activité<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('secteuractivite').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Sexe"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-intersex form__icon"></i>
                <div class="form__separator">
                </div>
                <select id="cars" placeholder=" " class="form__select" name="genre" 
                formControlName="genre">
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
                <label for="cars" class="form__label">Sexe <span class="text-red"> *</span></label>              
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('genre').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                <i class="fa fa-home form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('assure').get('adresse').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)"><i class="fa fa-pencil"></i> Modifier</button>
          </div>
        </div>      
      </div>
    </div>
  </form>
</ng-template>
<!-- Maison -->
<ng-template #updateMaison let-modal>
  <form [formGroup]="mrhForm">
    <div class="container px-4 py-2 mx-auto">
      <div class="card card0 c-card">
        <div class="row beneficiary-section form-container " formGroupName="packForm1">
          <div class="row no-margin">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
              <div class="row no-margin">
                <div class="col-10 p-0">
                  <h6 class="text-left text-white c-cardTitle">Modifier les informations de la maison</h6>
                </div>
                <div class="col-lg-2 text-right">
                  <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Qualité du souscripteur"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-user form__icon"></i>
                <div class="form__separator">
                </div>
                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" 
                formControlName="qualite_souscripteur" (change)="checkSouscripteur()">
                  <option value="" selected disabled>Qualité souscripteur </option>
                  <option value="proprietaire">Propriétaire</option>
                  <option value="proprietaireN">Propriétaire Non occupant</option>
                  <option value="locataire">Locataire</option>
                </select>
                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>              
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('qualite_souscripteur').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Nombre de pieces"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                  </div>
                  <select class="form-control" formControlName="pieces">
                    <option value="" disabled selected>Nombre de pièces</option>
                    <option value="2">F2</option>
                    <option value="3">F3</option>
                    <option value="4">F4</option>
                    <option value="5">F5</option>
                    <option value="6">F6</option>
                    <option value="7">F7</option>
                    <option value="8">F8</option>
                    <option value="9">F9</option>
                    <option value="10">F10</option>
                    <option value="11">F11</option>
                  </select>
                </div>
              </div> -->
              <div class="form" placement="top" placement="top" ngbPopover="Nombre de pieces"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-home form__icon"></i>
                <div class="form__separator">
                </div>
                <select id="cars" placeholder=" " class="form__select" formControlName="pieces">
                  <!-- <option value="" disabled selected>Nombre de pièces</option> -->
                    <option value="2">F2</option>
                    <option value="3">F3</option>
                    <option value="4">F4</option>
                    <option value="5">F5</option>
                    <option value="6">F6</option>
                    <option value="7">F7</option>
                    <option value="8">F8</option>
                    <option value="9">F9</option>
                    <option value="10">F10</option>
                    <option value="11">F11</option>
                </select>
                <label for="cars" class="form__label">Nombre de pièces<span class="text-red"> *</span></label>              
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('pieces').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"
              *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Valeur du batiement"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-building-o text-white"> <span class="text-red">*</span></i>
                  </div>
                  <input type="text" mask="separator" class="form-control" formControlName="valeur_batiment"
                    placeholder="Valeur du batiment">
                </div>
              </div> -->
              <div class="form" ngbPopover="Valeur du batiement"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-building-o form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" mask="separator" id="valeur_batiment" class="form__input" autocomplete="off" placeholder=" " formControlName="valeur_batiment"/>
                <label for="valeur_batiment" class="form__label">Valeur du batiment<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('valeur_batiment').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"
              *ngIf="mrhForm.get('packForm1').get('qualite_souscripteur').value=='locataire'">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Loyer mensuel" triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-building-o text-white"> <span class="text-red">*</span></i>
                  </div>
                  <input type="text" mask="separator" class="form-control" formControlName="loyer_mensuel"
                    placeholder="Loyer mensuel">
                </div>
              </div> -->
              <div class="form" ngbPopover="Loyer mensuel" triggers="mouseenter:mouseleave">
                <i class="fa fa-building-o form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" mask="separator" id="loyer_mensuel" class="form__input" autocomplete="off" placeholder=" " formControlName="loyer_mensuel"/>
                <label for="loyer_mensuel" class="form__label">Loyer mensuel<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('loyer_mensuel').invalid">
                Ce champ est obligatoire
              </div>


            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Valeur du contenu"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-money text-white"></i>
                  </div>

                  <input type="text" class="form-control" mask="separator" formControlName="valeur_contenu"
                    placeholder="Valeur du contenu">
                </div>
              </div> -->
              <div class="form" placement="top" ngbPopover="Valeur du contenu"
              triggers="mouseenter:mouseleave">
                <i class="fa fa-money form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" mask="separator" id="valeur_contenu" class="form__input" autocomplete="off" placeholder=" " mask="separator"
                formControlName="valeur_contenu"/>
                <label for="valeur_contenu" class="form__label">Valeur du contenu<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('valeur_contenu').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Surface développée"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                  </div>

                  <input type="text" class="form-control" mask="separator" formControlName="surface"
                    placeholder="Surface développée m2">
                </div>
              </div> -->
              <div class="form" placement="top" ngbPopover="Surface développée"
              triggers="mouseenter:mouseleave">
                <i class="fa fa-home form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" mask="separator" id="surface" class="form__input" autocomplete="off" placeholder=" "
                formControlName="surface"/>
                <label for="surface" class="form__label">Surface développée m2<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('surface').invalid">
                Ce champ est obligatoire
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <!-- <div class="form-group">
                <div class="input-group" placement="top" ngbPopover="Début du contrat"
                  triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-clock-o text-white"> <span class="text-red">*</span></i>
                  </div>
                  <input type="text" class="form-control" placeholder="Date effet"
                    (dateSelect)="onDateSelect($event, 'packForm1','effet')"
                    [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="today" ngbDatepicker
                    #dateAss="ngbDatepicker" [value]="date" (click)="dateAss.toggle()" />

                </div>
              </div> -->
              <div class="form" placement="top" ngbPopover="Début du contrat"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-clock-o form__icon"></i>
                <div class="form__separator">
                </div>
                <!-- | date: 'dd/MM/yyyy' -->
                <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" "
                  (dateSelect)="onDateSelect($event, 'packForm1','effet')"
                    [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="today" ngbDatepicker
                    #dateAss="ngbDatepicker" [value]="mrhForm.get('packForm1').get('effet').value | date: 'dd/MM/yyyy'" (click)="dateAss.toggle()"/>
                <label for="effet" class="form__label">Date effet<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('packForm1').get('effet').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
              <div class="form" placement="top" ngbPopover="Surface développée"
                triggers="mouseenter:mouseleave">
                <i class="fa fa-map-marker form__icon"></i>
                <div class="form__separator">
                </div>
                <input type="text" id="situation_risque" class="form__input" autocomplete="off" placeholder=" "
                formControlName="situation_risque" />
                <label for="situation_risque" class="form__label">Adresse<span class="text-red"> *</span></label>
              </div>
              <div class="error min-max text-red"
                *ngIf="invalid && mrhForm.get('packForm1').get('situation_risque').invalid">
                Ce champ est obligatoire
              </div>
            </div>
            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)"><i class="fa fa-pencil"></i> Modifier</button>
          </div>
        </div>      
      </div>
    </div>
  </form>
</ng-template>
