<!-- screenshot section Start-->

<!-- <section class="app1 " id="footer2"> -->
  <!-- <div class="row no-margin footer-content">
    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-6 about">
      <h2>A Propos</h2>
      <p class="mt-3 pr-3 about-t">Notre plateforme de courtage en ligne  met en concurrence les différents assureurs du marché en toute impartialité. Elle donne la possibilité aux prospects ou  clients (particuliers ou entreprises ) de souscrire directement en ligne aux offres d’assurance Vie et Non Vie au juste prix , suivi d’une livraison rapide partout au SENEGAL.</p>
    </div>
    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-6 links">
      <h2>Informations Utiles</h2>
       <div class="row">
         <div class="col-12 pt-2">
           <i class="fa fa-map-marker"></i> Sacré coeur 3 Montagne Villa 7595
         </div>
          <div class="col-12 pt-2">
            <i class="fa fa-phone"></i> 33 800 00 00
          </div>
          <div class="col-12 pt-2">
            <i class="fa fa-envelope"></i> contact@optima-assurance.com
          </div>
          <div class="col-12 app-d">
            <img src="assets/images/ios.png" alt="">
            <img src="assets/images/android.png" alt="">
          </div>
       </div>
    </div>
    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-6 form">
      <h2>Contactez-nous</h2>
      <div class="form-container">
         <form class="form">
           <div class="row">
             <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <input type="text" class="custom-input" placeholder="Prénom et nom" name="">
             </div>
             <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <input type="text" class="custom-input" placeholder="Téléphone" name="">
             </div>
           </div>
           <div class="row">
             <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <textarea type="text" class="custom-textarea" placeholder="Message" name=""></textarea>
             </div>
           </div>
           <div class="row">
             <button type="button" class="btn btn-send" name="button">Envoyer</button>
           </div>
         </form>
      </div>
    </div>
  </div> -->
  <!-- <div class="container-fluid">
    <div class="row justify-content-left">
        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>OPTIMA</h3>
                <p>Notre réseau de proximité Optima Shop, pilier de notre développement, est là pour contribuer à délivrer une qualité de service de classe internationale qui répond aux besoins des populations urbaines et rurales.</p>
                <ul class="footer-social">
                    <li class="social">
                       
                          <a  href="#"><i _ngcontent-serverapp-c34="" aria-hidden="true" class="fa fa-facebook center-content"></i></a>
                     
                    </li>

                    <li>
                      <a  href="#">
                          <i  aria-hidden="true" class="fa fa-twitter center-content"></i>
                        </a>
                    </li> -->

                    <!-- <li>
                      <a  href="#">
                          <i aria-hidden="true" class="fa fa-instagram center-content"></i>
                        </a>
                    </li> -->
<!--                     
                    <li>
                        <a href="#">
                            <i aria-hidden="true" class="fab fa fa-pinterest center-content"></i>
                        </a>
                    </li>

                    <li>
                      <a href="#">
                          <i aria-hidden="true" class="fab fa fa-linkedin center-content"></i>
                      </a>
                  </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>Instagram</h3>

                <ul class="instagram">
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/1 (1).jpg" alt="image">
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/2.jpg" alt="image">
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/3.jpg" alt="image">
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/4.jpg" alt="image">
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/1.jpg" alt="image">
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="../../../../assets/images/2 (1).jpg" alt="image">
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget ">
                <h3>Lien utiles</h3>
                
                <ul class="footer-quick-links">
                    <li>
                        <a href="som.html">Accueil</a>
                    </li>
                    <li>
                        <a href="#">Présentation</a>
                    </li>
                    <li>
                        <a href="#">Transfert</a>
                    </li>
                    <li>
                        <a href="fidelite.html">Fidélité</a>
                    </li>
                    <li>
                        <a href="assurance.html">Assurances</a>
                    </li>
                    <li>
                        <a href="#">Contact</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>Contact</h3>

                <div class="footer-info-contact">
                    <i class="fa fa-phone"></i>
                    <h4>Telephone</h4>
                    <span><a href="#">Tel: +221 33 860 60 67 </a></span>
                </div>

                <div class="footer-info-contact">
                    <i class="fa fa-envelope"></i>
                    <h4>Email</h4>
                    <span><a href="#">info@optima.com</a></span>
                </div>

                <div class="footer-info-contact">
                    <i class=" fa fa-map-marker "></i>
                    <h4>Address</h4>
                    <span>Immeuble Focus One – 14 rue Birago DIOP  Point E - BP : 45626 Dakar Fann SENEGAL </span>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="lines">
  <div class="line"></div>
  <div class="line"></div>
  <div class="line"></div>
</div>
</section> -->
<!-- screenshot section end-->
<section class="footer-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>INSURTECH GLOBALE ASSURANCE</h3>
                    <p>Notre réseau de proximité Optima Shop, pilier de notre développement, est là pour contribuer à délivrer une qualité de service de classe internationale qui répond aux besoins des populations urbaines et rurales.</p>
                    <ul class="footer-social">
                        <li>
                            <a href="#">
                                <i class="fa fa-facebook-f"></i>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <i class="fa fa-pinterest-p"></i>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <i class="fa fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Instagram</h3>

                    <ul class="instagram">
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/1.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/2.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/3.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/4.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/assurance-auto.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/images/domicile.jfif" alt="image">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Lien utiles</h3>
                    
                    <ul class="footer-quick-links">
                        <li>
                            <a href="index.html">Accueil</a>
                        </li>
                        <li>
                            <a href="about.html">Présentation</a>
                        </li>
                        <li>
                            <a href="solution.html">Argent</a>
                        </li>
                        <li>
                            <a href="services.html">Fidélité</a>
                        </li>
                        <li>
                            <a href="blog.html">Assurances</a>
                        </li>
                        <li>
                            <a href="contact.html">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <div class="footer-info-contact">
                        <i class="fa fa-phone"></i>
                        <h3>Téléphone</h3>
                        <span><a href="#">Tél: +221 33 860 60 67 </a></span>
                    </div>

                    <div class="footer-info-contact">
                        <i class="fa fa-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="#">info@sun-telecoms.com</a></span>
                    </div>

                    <div class="footer-info-contact">
                        <i class="fa fa-map-marker"></i>
                        <h3>Address</h3>
                        <span>Sacré coeur3 rue 47 V n° 9395 Dakar Sénégal </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>