<div class="container-gauche bg-dark" *ngIf="login">
  <div class="mask">
  </div>
  <h2 class="text-center main-title"><i> Simulation assurance voyage</i></h2>
  <div class="decoration-droite bg-dark"></div>

</div>
<div class="row container-row justify-content-center" *ngIf="login">
  <div class="col-12 droite">
    <div>

      <form [formGroup]="simulationVoyageForm" class="auto">
        <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'"
          [navBarLayout]="'large-empty'" [disableNavigationBar]="false">

          <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Voyage">
            <div class="form-container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="input-group" placement="left" ngbPopover="Pays de départ"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-flag text-white"><span class="text-red">*</span></i>
                    </div>
                    <select disabled formControlName="paysDepart" class="form-control" name="pays">
                      <option value="" disabled selected>Pays de départ</option>
                      <option *ngFor="let pays of listPays" [value]="pays.id">{{pays?.nom}}</option>
                    </select>
                  </div> -->
                  <div class="form" placement="left" ngbPopover="Pays de départ"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-flag form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" placeholder=" " formControlName="paysDepart" 
                    class="form__select" (change)="setAssure($event)" name="pays">
                      <option value="" disabled selected>Pays de départ</option>
                      <option *ngFor="let pays of listPays" [value]="pays.id">{{pays?.nom}}</option>
                    </select>
                    <label for="cars" class="form__label">Pays de départ<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('paysDepart').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="input-group" placement="left" ngbPopover="Pays d'arrivée"
                    triggers="mouseenter:mouseleave">
                    <div class="input-group-append">
                      <i class="fa fa-flag text-white"><span class="text-red">*</span></i>
                    </div>
                    <select formControlName="paysArrive" class="form-control" name="pays">
                      <option value="" disabled selected>Pays d'arrivée</option>
                      <option *ngFor="let pays of listPays" [value]="pays.id">{{pays?.nom}}</option>
                    </select>
                  </div> -->
                  <div class="form" placement="left" ngbPopover="Pays d'arrivée"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-flag form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" formControlName="paysArrive" placeholder=" " class="form__select" 
                    (change)="setAssure($event)" >
                      <option value="" disabled selected>Pays d'arrivée</option>
                      <option *ngFor="let pays of listPays" [value]="pays.id">{{pays?.nom}}</option>
                    </select>
                    <label for="cars" class="form__label">Pays d'arrivée<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('paysArrive').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="left" ngbPopover="Date de départ"
                      triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" [minDate]="today"
                        (dateSelect)="onDateSelect($event,'dateDepart')" ngbDatepicker #dateDepart="ngbDatepicker"
                        (click)="dateDepart.toggle()" placeholder="Date de départ">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Date de départ" triggers="mouseenter:mouseleave">
                    <i class="fa fa-calendar form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" [minDate]="today" placeholder=" "
                    (dateSelect)="onDateSelect($event,'dateDepart')" ngbDatepicker #dateDepart="ngbDatepicker"
                    (click)="dateDepart.toggle()"/>
                    <label for="email" class="form__label">Date de départ<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('dateDepart').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="left" ngbPopover="Date de retour "
                      triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" [minDate]="minDate"
                        (dateSelect)="onDateSelect2($event,'dateRetour')" ngbDatepicker #dateRetour="ngbDatepicker"
                        (click)="dateRetour.toggle()" placeholder="Date de retour">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Date de retour" triggers="mouseenter:mouseleave">
                    <i class="fa fa-calendar form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" "
                    [minDate]="minDate" (dateSelect)="onDateSelect2($event,'dateRetour')" ngbDatepicker #dateRetour="ngbDatepicker"
                    (click)="dateRetour.toggle()"/>
                    <label for="email" class="form__label">Date de retour<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('dateRetour').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="left" ngbPopover="Date de naissance "
                      triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" [maxDate]="today"
                        (dateSelect)="onDateSelect($event,'dateNaissance')" ngbDatepicker #dateNaissance="ngbDatepicker"
                        (click)="dateNaissance.toggle()" placeholder="Date de naissance">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Date de naissance" triggers="mouseenter:mouseleave">
                    <i class="fa fa-calendar form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today"
                    (dateSelect)="onDateSelect($event,'dateNaissance')" ngbDatepicker #dateNaissance="ngbDatepicker"
                    (click)="dateNaissance.toggle()"/>
                    <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('dateNaissance').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i
                    class="fa fa-chevron-left"></i>Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i
                    class="fa fa-chevron-right"></i></button>
              </div>
            </div>
          </aw-wizard-step>

          <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties">
            <div class="form-container c-form-container">
            
              <div class="row">
                <div class="col-10 text-left p-2 mrhgarantie">
                  <i class="fa fa-check-circle mr-2 text-success"></i>
                  <b>Responsabilité des conducteurs (Ganrantie par défaut)</b>
                </div>
              </div>
              <div *ngIf="garanties?.length > 0 " class="row">
                <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                  <div class="value" *ngIf="g.sousGarantie.length==0">
                    <input class="inputCheck" [checked]="g.selected"  (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                    <label for="{{g.code}}"> {{g.nom}} </label>
                  </div>
                  <div class="value" *ngIf="g.sousGarantie.length>0">
                    <label for="" class="label-SG"> {{g.nom}}</label>
                    <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                    <input class="inputCheck" [checked]="sg.selected"  (change)="addGarantie(sg,sg,g.sousGarantie)" type="checkbox" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                    <label for="{{g.code}}"> {{sg.nom}} </label>
                  </div>
                  </div>
                </div>

              </div>
              <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                Chargemet en cours ...
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete">
                  <div class="form-group">
                    <div class="input-group" placement="left" ngbPopover="Renseigner la valeur à neuf du vehicule!"
                      triggers="mouseenter:mouseleave" popoverTitle="Valeurs à neuf">
                      <div class="input-group-append">
                        <i class="fa fa-money text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" mask="separator" class="form-control" (keyup)="onBlurEventneuf($event)"
                        formControlName="valeurNeuf" placeholder="Valeur à neuf ">
                    </div>
                    <div class="error min-max text-red"
                      *ngIf="invalid && simulationVoyageForm.get('valeurNeuf').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                  <div class="form-group">
                    <div class="input-group" placement="left" ngbPopover="Renseigner la valeur du marché du vehicule!"
                      triggers="mouseenter:mouseleave" popoverTitle="Valeurs vénal">
                      <div class="input-group-append">
                        <i class="fa fa-credit-card-alt text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" mask="separator" (keyup)="onBlurEvent($event)" class="form-control"
                        formControlName="valeurVenal" placeholder="Valeur vénale ">
                    </div>
                    <div class="error min-max text-red"
                      *ngIf="invalid && simulationVoyageForm.get('valeurVenal').invalid">
                      Ce champ est obligatoire
                    </div>
                    <div class="error min-max text-red"
                      *ngIf="simulationVoyageForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                      La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep><i
                class="fa fa-chevron-left"></i> Retour</button>
            <button type="button" class="btn btn-success btn-next pull-right" (click)="processSimulation()"><i
                class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Suivant <i
                class="fa fa-chevron-right"></i></button>
          </aw-wizard-step> -->


          <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assureur" *ngIf="false">
            <!-- <h2>Choisir votre assureur</h2> -->
            <div class="container justify-content-center">
              <h6 *ngIf="!sendingRequestAssureur">Veuillez choisir votre assureur pour obtenir votre devis</h6>
              <div class="justify-content-center" *ngIf="!sendingRequestAssureur">

                <div class="row" *ngIf="assureurs.length!=0">
                  <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12" *ngFor="let res of assureurs">
                    <div id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave"
                      popoverTitle="{{res.code}}">
                      <div class="card rounded" (click)="processSimulation2(res)">
                        <div class="card-image">
                          <img class="img-fluid" [src]="res.img" alt="{{res.nom}}" height="177px" />
                        </div>
                        <div class="c-card-ass my-name text-center">
                          <h6 class="nom">{{res.nom}}</h6>
                        </div>
                        <div class="c-card-ass my-price text-center">
                          <h6 class="nom">Prix:&nbsp; <span *ngIf="res.prix"> {{res.prix |number:'1.0-0':'fr'}} &nbsp;
                              FCFA</span> <span *ngIf="!res.prix" class="fa fa-spinner fa-pulse"> </span></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chargement" *ngIf="sendingRequest">
                  <i class="fa fa-spinner fa-pulse display-2"></i><br>
                  <p class="text-center">Chargement devis en cours...</p>
                </div>
              </div>
              <div class="noAssureur" *ngIf="assureurs.length==0">
                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                <p class="text-center text-ass">Chargement des assureurs en cours...</p>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left" (click)="retour()" awPreviousStep><i
                class="fa fa-chevron-left"></i></button> -->
            <div class="row justify-content-center">
              <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                    class="fa fa-chevron-left"></i></button>
                  </div>
            </div>
            <!-- <button type="button" class="btn btn-primary btn-phone pull-right" (click)="mail()">Me faire rappeler <i
                class="fa fa-phone c-phone"></i></button> -->


          </aw-wizard-step>
          <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assuré">
            <div class="form-container" formGroupName="assure">
              <div class="row assurance-for text-center justify-content-right">
                <!-- <h6 class="col-3">Je  souscris ?</h6> -->
                <div class="radio-toolbar col-12">
                  <input type="radio" id="moi" name="radioFruit" value="1" checked>
                  <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                  <input type="radio" id="tiers" name="radioFruit" value="2">
                  <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
                </div>
              </div>
              <div class="row no-margin">
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom"/>
                    <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('prenom').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom"/>
                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('nom').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                      triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask"
                        formControlName="numeroTelephone" placeholder="Numéro téléphone">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                    <i class="fa fa-phone form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask"
                    formControlName="numeroTelephone"/>
                    <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('numeroTelephone').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                    <i class="fa fa-envelope form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('email').invalid">
                    Ce champ est obligatoire ou invalide
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa  fa-vcard text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                    <i class="fa fa-vcard form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession"/>
                    <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('profession').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa  fa-vcard text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="secteuractivite" placeholder="Activité">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                    <i class="fa fa-vcard form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite"/>
                    <label for="email" class="form__label">Activité<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('secteuractivite').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <!-- 
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                      triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                      <div class="input-group-append">
                        <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" [maxDate]="today"
                        [minDate]="{year: 1900, month: 1, day: 1}"
                        (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                        #dateAsss="ngbDatepicker" (click)="dateAsss.toggle()" placeholder="Date de naissance" value=""
                        name="dateNaissance">

                    </div>
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('dateNaissance').invalid">
                    Ce champ est obligatoire
                  </div>
                </div> -->

                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-intersex text-white"> <span class="text-red">*</span></i>
                      </div>
                      <select class="form-control" formControlName="genre">
                        <option value="" selected disabled>Sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="form" placement="top" ngbPopover="Sexe"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-intersex form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <select id="cars" formControlName="genre" placeholder=" " class="form__select" >
                      <option value="" selected disabled>Sexe</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('genre').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                  <!-- <div class="form-group">
                    <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                      <div class="input-group-append">
                        <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                      </div>
                      <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                    </div>
                  </div> -->
                  <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                    <i class="fa fa-home form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse"/>
                    <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>              
                  </div>
                  <div class="error min-max text-red"
                    *ngIf="invalid && simulationVoyageForm.get('assure').get('adresse').invalid">
                    Ce champ est obligatoire
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()" awPreviousStep><i
                    class="fa fa-chevron-left"></i> Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest"
                  (click)="assuree()">Suivant</button>
              </div>
            </div>

          </aw-wizard-step>
          <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
            <div class="row mb-4 justify-content-center">
              <h6 class="col-12 text-center">Choisir le type de livraison</h6>
              <div class="mt-4 badge-content" (click)="domicile()">
                <div class="badge" [class.selected]="simulationVoyageForm.get('livraison').value == 'Domicile'">
                  Domicile
                </div>
                <img width="135px" src="assets/images/domicile.jpg" alt="">
              </div>
              <div class="mt-4 badge-content" (click)="relais()">
                <div class="badge" [class.selected]="simulationVoyageForm.get('livraison').value == 'Relais'">
                  Point relais
                </div>
                <img width="135px" src="assets/images/relais.jfif" alt="">
              </div>
            </div>

            <div class="row mb-4 justify-content-center">
              <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
              <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                <div class="badge" [class.selected]="simulationVoyageForm.get('moyenDePaiement').value == pay.code">
                  {{pay?.nom}}
                </div>
                <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right" (click)="retour()" awPreviousStep><i
                    class="fa fa-chevron-left"></i> Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-left" awNextStep>Suivant <i
                    class="fa fa-chevron-right"></i></button>
              </div>
            </div>

          </aw-wizard-step>
          <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Paiement">
            <!-- <div class="row mb-4 justify-content-center">
              <h6 class="col-12 text-center">Choisir le type de livraison</h6>
              <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVoyageForm.get('livraison').value == 'Domicile'">
                <div class="badge" >
                  Domicile
                </div>
                <img width="135px" src="assets/images/domicile.jpg" alt="">
              </div>
              <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVoyageForm.get('livraison').value == 'Relais'">
                <div class="badge" >
                  Point relais
                </div>
                <img width="135px" src="assets/images/relais.jpg" alt="">
              </div>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
                 *ngIf="pointRelais?.length > 0 ||  pointRelais">
              <div class="form-group">
                <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                     triggers="mouseenter:mouseleave">
                  <div class="input-group-append">
                    <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                  </div>
                  <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                    <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                    <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                  </select>
                </div>
              </div>
              <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
                Ce champ est obligatoire
              </div>
            </div> -->
            <div class="row mb-4 justify-content-center">
              <h6 class="col-12 text-center">Choisir le type de livraison</h6>
              <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVoyageForm.get('livraison').value == 'Domicile'">
                <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                <div class="badge" >
                  Domicile
                </div>
                <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
              </div>
              <div class="mt-5 badge-content" (click)="relais()" [class.selected]="simulationVoyageForm.get('livraison').value == 'Relais'">
                <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                <div class="badge" >
                  Point relais
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
                 *ngIf="pointRelais?.length > 0 ||  pointRelais">
              <div class="form mt-5" placement="top" ngbPopover="Point de relais"
                triggers="mouseenter:mouseleave">
                <i class="fas fa-map-marked-alt form__icon"></i>
                <div class="form__separator">
                </div>
                <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl" >
                  <option value="" selected disabled>Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>
                <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>              
              </div>
              <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('pointRelaisControl').invalid">
                Ce champ est obligatoire
              </div>
            </div>
  
            <div class="row mb-4 justify-content-center">
              <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
              <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVoyageForm.get('moyenDePaiement').value == pay.code">
                <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                <div class="badge">
                  {{pay?.nom}}
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                    class="fa fa-chevron-left"></i> Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                    class="fa fa-chevron-right"></i></button>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
            <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->
  
          </aw-wizard-step>

          <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif">
            <div class="recap-form-container">
              <div class="mesImage">
                <div class="imgLogo">
                  <img [src]="currentAssureur.img" alt="">
                </div>
              </div>
              <div class="info-devis">
                <div class="proposition">
                  <div class="pro-title"> Proposition Voyage</div>
                  <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      Références du souscripteur
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('prenom').value}}
                          {{simulationVoyageForm.get('assure').get('nom').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('email').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('numeroTelephone').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('adresse').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('profession').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('secteuractivite').value}} </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      <span>Références Proposition</span>

                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave"
                        (click)="goTo(3)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">

                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy
                          HH:mm:ss'}}
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('prenom').value}}
                          {{simulationVoyageForm.get('assure').get('nom').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('assure').get('adresse').value}} </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                          {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy'}} à
                          Minuit
                        </div>

                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 ans </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                      <span> Identification du voyage </span>
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave"
                        (click)="goTo(0)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays de départ</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              Sénégal
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de départ</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy':'fr'}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{age}} an(s)</div>

                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays d'arrivée</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{ getNamePaysById(simulationVoyageForm.get('paysArrive').value)}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de retour</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                              {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy':'fr'}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="proposition2">
                  <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                </div>
                <div class="row c-row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      <span> Nature des garanties </span>
                      <span class="fa fa-edit edit-pro" placement="top"
                        ngbPopover="Cliquer pour modifier les infos des garanties" triggers="mouseenter:mouseleave"
                        (click)="goTo(1)"></span>
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                          Frais médicaux et hospitalisation
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                          Assistance et rapatriement
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                          Responsabilité civile à l’étranger
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                      Capitaux garanties
                    </div>
                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                          Gratuit
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                          Gratuit
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                          Gratuit
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="description-ass">
                  Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la
                  somme
                  de <b>
                    {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                </div>
                <div class="row ">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                          <div *ngFor="let item of resultSimulation">
                            <span *ngIf="item.key!='Montant TTC'">{{item.key}}</span>
                            <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.key}}</span>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                          <div class="val" *ngFor="let item of resultSimulation">
                            <span *ngIf="item.key!='Montant TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                            <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.value |
                              number:'1.0-0':'fr'}} FCFA</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="disposition">
                  <div class="title-disp">
                    Dispositions complémentaires
                  </div>
                  <div class="desc-disp">
                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                    disposition de
                    l'article 13
                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                      générales
                      jointes, les conditions particulières ainsi que les closes en annexe.</div>
                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace
                      toute
                      disposition des conditions générales (CG) qui leurs sont contraires.</div>
                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                      visas de
                      l'assureur et du souscripteur. </div>
                  </div>
                </div>
              </div>
              <div class="footer-devis row">
                <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                  <div class="fist-line">
                    <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b>
                    avec
                    conseil
                    d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                      number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                    <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par
                    le
                    code
                    des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                  </div>
                  <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                    &nbsp;<b>{{currentAssureur.email}}</b> </div>
                </div>
                <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                  <img class="footer-img" [src]="currentAssureur.img" alt="">
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-5">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()" awPreviousStep><i
                    class="fa fa-chevron-left"></i> Retour</button>
              </div>
              <div class="col-5">
                <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscription2"
                  [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse"
                    *ngIf="sendingRequest"></i>Terminer</button>
              </div>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </form>

    </div>
  </div>

</div>
<div *ngIf="!login">
  <!-- <img src="../../../../../assets/images/assurance-auto.jpg" alt="" class="img-1" @slideInOut> -->
  <!-- <img src="../../../../../assets/images/assurance-voyage.jpg" alt=""
    [ngClass]="{'img-2': garanties.length!=0,'img-4':garanties.length==0}" @slideInOut> -->

  <section class="section1 ">
    <div class="row justify-content-center pres">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 photo-auto container">
        <h3 class=" text-left title-ass" @fade> Assurance voyage</h3>
        <p class="mt-5  description" @fade>
          L’assurance voyage est un contrat qui vous couvre en cas d’imprévu pendant votre séjour à l’étranger. Perte de
          vos bagages, accident, maladie, dommages causés à autrui… Autant d’événements qui pourraient gâcher votre
          séjour.
        </p>
        <div class="Souscrire" (click)="souscrire()">
          <div class="simulation">
            <div class="container" @slideInOut>
              <div class="left-side">
                <div class="card">
                  <div class="card-line"></div>
                  <div class="buttons"></div>
                </div>
                <div class="post">
                  <div class="post-line"></div>
                  <div class="screen">
                    <div class="dollar">$</div>
                  </div>
                  <div class="numbers"></div>
                  <div class="numbers-line2"></div>
                </div>
              </div>
              <div class="right-side">
                <div class="new">Faire une simulation</div>

                <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="512" height="512"
                  viewBox="0 0 451.846 451.847">
                  <path
                    d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"
                    data-original="#000000" class="active-path" data-old_color="#000000" fill="#cfcfcf" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <img src="../../../../../assets/images/voyage5.jpg" alt="" class="img-4" @slideInOut>
      </div>
    </div>
  </section>
  <!-- HTML Setup -->
  <!-- <section class="section2" *ngIf="garanties.length!=0">
    <div class="container">
      <div class="row">
        <div class="mx-auto">
          <div class="col-lg-12">
            <div class="text-center my-3">
              <h2 class="text-center my-5">Garanties</h2>
              <div class="card-deck no-gutters">

                <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-5" *ngFor="let garantie of garanties">
                  <div class="card card1 h-100 mb-4">
                    <div class="box-part text-center" placement="left" ngbPopover="{{garantie?.description}}"
                      triggers="mouseenter:mouseleave" popoverTitle="{{garantie?.name}}">

                      <img class="heart" src="assets/images/mrh.png" alt="">
                      <div class="title">
                        <h4>{{garantie?.nom}}</h4>
                      </div>

                      <div class="text">
                        <h6>Avantages:</h6>
                        <p class="pr-1 pl-1">{{garantie?.description}}</p>

                      </div>

                      <a class="mb-5"><b>Prix</b></a><br>
                      <p>{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA</p>

                      <div class="promotion-price">
                        <div class="promotion-price-desc"> À partir de </div>
                        <div class="promotion-price-text">{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section> -->
</div>
<ng-template #contentResult let-modal>
  <div class="modal-body border border-none">
    <div class="cotainer devis-container bg-white" id="htmlData">
      <div class="row row-devis">
        <div class="col-12">
          <div class="row row-title-devis align-items-center">
            <div class="col-6 img-logo">
              <img src="../../../../assets/images/logo6.png" alt="">
            </div>
            <div class="col-6 ">
              <div class="row Informations">
                <div class="col-12">
                  <p>Tel: +221 33 860 60 67</p>
                </div>
                <div class="col-12">
                  <p>Email: info@optima.com</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-subtitle-devis ">
            <div class="col-3 justify-content-center bg-primary">
              <p class="devis-text">Devis</p>
            </div>
            <div class="col-9">
              <div>
                <div class="row assure" *ngIf="res?.key != 'Prime Totale TTC'">
                  <div class="col-12 assure-title">
                    Voyage
                  </div>
                  <div class="col-12 assure-text" *ngFor="let res of resultSimulation">
                    <div class="row align-items-center ">
                      <div class="col-8">
                        <p>{{res?.key}}</p>
                      </div>
                      <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let res of resultSimulation">
                <div class="row assure" *ngIf="res?.key == 'Prime Totale TTC'">
                  <div class="col-12 assure-title">
                    Total
                  </div>
                  <div class="col-12 assure-text">
                    <div class="row align-items-center ">
                      <div class="col-8">
                        <p>{{res?.key}}</p>
                      </div>
                      <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
    <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
  </div>

</ng-template>
<ng-template #contentResultgrise let-modal>

  <div class="modal-body">
    <div class="cotainer p-2" id="htmlData">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
    <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
  </div>

</ng-template>
<ng-template #contentResult2 let-modal>

  <div class="modal-body">
    <div class="card mb-3 c-card-body">
      <div class="row g-0">
        <div class="col-md-12 p-4">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row  align-items-center">
                  <h5 class="text-center col-12 mb-5">Carte grise</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <button class="btn btn-danger  m-auto c-btn-close" (click)="modal.close('close')">Revenir</button>
  <button class="btn btn-success m-auto c-btn-close" (click)="modal.close('subscribe')">Continuer</button>

</ng-template>
<ng-template #contentResultcontact let-modal>
  <div class="c-modal-header" id="modal-header DKG">
    <h5 class="modal-title text-white" id="modal-basic-title" id="modal-title">Se faire contacter</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-white" id="modal-body">
    <form [formGroup]="personcontactForm">
      <div class="form-container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <!-- <div class="form-group">
              <div class="input-group">
                <div class="input-group-append" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                  <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                </div>
                <input type="text" class="form-control" formControlName="first_name" placeholder="Prénom">
              </div>
            </div> -->
            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
              <i class="fa fa-user-circle-o form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="first_name"/>
              <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>              
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('first_name').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <!-- <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                </div>
                <input type="text" class="form-control" formControlName="last_name" placeholder="Nom">
              </div>
            </div> -->
            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
              <i class="fa fa-user-circle-o form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="last_name"/>
              <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>              
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('last_name').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <!-- <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                </div>
                <input type="text" class="form-control" prefix="+221 " [mask]="phoneMask" formControlName="telephone"
                  placeholder="Téléphone">
              </div>
            </div> -->
            <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
              <i class="fa fa-phone form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask" formControlName="telephone"/>
              <label for="email" class="form__label">Téléphone<span class="text-red"> *</span></label>              
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('telephone').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <!-- <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-home text-white"> </i>
                </div>
                <input type="text" class="form-control" formControlName="email" placeholder="Email">
              </div>
            </div> -->
            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
              <i class="fa fa-envelope form__icon"></i>
              <div class="form__separator">
              </div>
              <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email"/>
              <label for="email" class="form__label">Email<span class="text-red"> *</span></label>              
            </div>      
            <div class="error min-max text-red" *ngIf="personcontactForm.get('email').hasError('pattern')">
              Ce champ est invalide
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Jour disponible" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                </div>
                <ng-select multiple="true" class="select-rappel" placeholder="Jour à être rappeler"
                  formControlName="jour" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé"
                  [dropdownPosition]="'bottom'">
                  <ng-option *ngFor="let jour of listJours" [value]="jour.name"> {{jour.name}} </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('jour').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="form-group">
              <div class="input-group" placement="top" ngbPopover="Heure disponible" triggers="mouseenter:mouseleave">
                <div class="input-group-append">
                  <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                </div>
                <ng-select multiple="true" class="select-rappel" placeholder="Heure à être rappeler"
                  formControlName="heure" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé"
                  [dropdownPosition]="'bottom'">
                  <ng-option *ngFor="let heure of listHeures" [value]="heure.name"> {{heure.name}} </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="error min-max text-red" *ngIf="personcontactForm.get('heure').hasError.required">
              Ce champ est obligatoire
            </div>
          </div>
        </div>
      </div>
      <!-- <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep><i
            class="fa fa-chevron-left"></i></button>
        <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription">
          <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Souscrire <i
            class="fa fa-angle-double-right"></i></button> -->
      <button type="button" class="btn btn-danger" #closeBTN (click)="modal.close('close')">Fermer</button>
      <button type="button" class="btn btn-success" [disabled]="loadContact" (click)="saveContact()"> <span
          *ngIf="loadContact" class="fa fa-spinner fa-pulse"></span> Envoyer</button>
    </form>
  </div>
  <!-- <div class="modal-footer">
    
  </div> -->

</ng-template>
<ng-template #contentResultass let-modal>
  <div class="modal-body p-5 bg-white  autodown" #autoDownload id="autoDownload">
    <div class="mesImage">
      <div class="imgLogo">
        <img [src]="currentAssureur.img" alt="">
      </div>
    </div>
    <div class="info-devis">
      <div class="proposition">
        <div class="pro-title"> Proposition Voyage</div>
        <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Références du souscripteur
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.firstName}}
                {{user.infos.lastName}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.email}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.telephone}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.profession}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.secteuractivte}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span>Références Proposition</span>
          </div>
          <div class="contenu-div">
            <div class="row">

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.firstName}}
                {{user.infos.lastName}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{user.infos.adresse}} </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{simulationVoyageForm.get('dateRetour').value |
                date:'dd/MM/yyyy'}} à
                Minuit
              </div>

              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 ans </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
          <div class="title-div">
            <span> Identification du voyage </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays de départ</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    Sénégal
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de départ</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy':'fr'}} </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{age}} an(s)</div>

                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays d'arrivée</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{getNamePaysById(simulationVoyageForm.get('paysArrive').value)}} </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de retour</div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                    {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy':'fr'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="proposition2">
        <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
      </div>
      <div class="row c-row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            <span> Nature des garanties </span>
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Frais médicaux et hospitalisation
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Assistance et rapatriement
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                Responsabilité civile à l’étranger
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
          <div class="title-div">
            Capitaux garanties
          </div>
          <div class="contenu-div">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                Gratuit
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="description-ass">
        Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme
        de <b>
          {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
      </div>
      <div class="row ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

          <div class="contenu-div">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                <div *ngFor="let item of resultSimulation">
                  <span *ngIf="item.key!='Montant TTC'">{{item.key}}</span>
                  <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.key}}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="val" *ngFor="let item of resultSimulation">
                  <span *ngIf="item.key!='Montant TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                  <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.value |
                    number:'1.0-0':'fr'}} FCFA</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="disposition">
        <div class="title-disp">
          Dispositions complémentaires
        </div>
        <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
          disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
            générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
            visas de
            l'assureur et du souscripteur. </div>
        </div>
      </div>
    </div>
    <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
          conseil
          d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
          <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
          code
          des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentAssureur.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentAssureur.img" alt="">
      </div>
    </div>
  </div>
  <div *ngIf="isSouscription">
    <div class="row">
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" (click)="modal.close('close')"><i
            class="fa fa-chevron-left"></i></button>
      </div>
      <div class="col-4 text-center">
        <!-- openPDF() -->
        <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger <i
            class="fa fa-download"></i></button>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" (click)="modal.close('subscribe')">
          Souscrire <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isSouscription" class="btnSous">

    <button type="button" class="btn btn-primary btn-tel pull-left" (click)="openPDF()"><i
        class="fa fa-download"></i></button>

  </div>
</ng-template>
<ng-template #contentResultlogin let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Déclarer sinistre</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <div class="modal-body login">
    <form [formGroup]="loginform">
      <div class="container px-4 py-5 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3">
                    <!-- <img id="logo" src="../../../../../assets/images/logo.png"> </div> -->
                    <!-- <h6 class="msg-info">Veuillez renseigner votre email ou votre nom d'utilisateur et votre mot de passe!
                  </h6> -->
                    <h3 class="monCompte"> Mon compte!</h3>
                    <p class="compteText"> Connectez vous à votre compte SVP.</p>
                  </div>
                  <!-- <div class="form-group"> <label class="form-control-label text-muted">Nom d'utilisateur</label> <input
                      type="text" id="email" name="email" class="form-control" formControlName="username">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis</div>
                  </div> -->
                  <!-- <div class="form-group "> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="password">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis</div>
                  </div> -->
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fa fa-user form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="username"/>
                    <label for="email" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>              
                </div>
                <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis
                  </div>
                  
                  <div class="form" ngbPopover="Nom d'utilisateur"
                    triggers="mouseenter:mouseleave">
                    <i class="fas fa-lock form__icon"></i>
                    <div class="form__separator">
                    </div>
                    <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password"/>
                    <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                    <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>           
                    <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>           
                  </div>  
                  <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis
                  </div>
                  <div class="forgetMdp">
                    <div class="resterConnecter"> 
                      <input type="checkbox">
                      <span> Restez connecter</span>
                    </div>
                    <div class="updateMdp">Mot de passe oublié?</div>
                  </div>
                  <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                  <div> <button class="seConnecter"
                      (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Connexion en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#" sm-text><small class="text-danger"
                        [disabled]="loaderVisible">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>
              <div class="bottom text-center mb-3">
                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2"
                    (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
              </div>
            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>

  </div>

</ng-template>
<ng-template #contentResultsign let-modal>
  <div class="modal-body login">
    <form [formGroup]="inscriptionForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-2">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                  <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6>
                  <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                      type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                      Le champs email est requis</div>
                    <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est
                      incorrect</div>
                  </div>
                  <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone <span
                        class="text-red">*</span></label> <input type="text" id="tel" placeholder=""
                      class="form-control" formControlName="tel" prefix="+221 " [mask]="phoneMask">
                    <div class="text-red ml-3"
                      *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                      Le champs téléphone est requis</div>
                  </div>
                  <!-- <div class="form-group"> <label class="form-control-label text-muted" >Mot de passe</label> <input type="password" id="psw"  placeholder="" class="form-control" formControlName="password" > 
                              <div class="text-red ml-3" *ngIf="inscriptionForm.get('password')?.errors?.required && (inscriptionForm.get('password')?.touched || inscriptionForm.get('password')?.dirty)">Le champs mot de passe est requis</div>
                            </div> -->

                  <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Inscription en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>
              <div class="bottom text-center mb-1">
                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2"
                    (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
              </div>
            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>

  </div>
</ng-template>
<ng-template #contentResultforgot let-modal>
  <div class="modal-body login">
    <form [formGroup]="forgotForm">
      <div class="container px-4 py-2 mx-auto">
        <div class="card card0">
          <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
          </a>
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="card card1">
              <div class="row justify-content-center my-auto">
                <div class="col-md-10 col-10 mt-5">
                  <div class="row justify-content-center px-3 mb-3"> <img id="logo"
                      src="../../../../../assets/images/insurtech/logo-01-011.png"> </div>
                  <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                  <!-- <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6> -->
                  <!-- <div class="form-group"> <label class="form-control-label text-muted" >Prénom et nom</label> <input type="text" id="nom"  placeholder="" class="form-control" formControlName="nom"> </div> -->
                  <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                      type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                  </div>
                  <!-- <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone</label> <input type="text" id="psw"  placeholder="" class="form-control" formControlName="tel"  prefix="+221 " [mask]="phoneMask"> </div> -->

                  <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                  <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color"
                      (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                  <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                    <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                    <p class="text-center ">
                      Réinitialisation en cours
                    </p>
                  </div>
                  <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                </div>
              </div>

            </div>
            <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?"
  icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
  [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>