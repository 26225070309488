<!-- about section Start-->

<section class="app1 about bg-theme mb-3" id="about-us" data-background="./assets/images/counter-bg.png" style="background-image: url('./assets/images/counter-bg.png');">
    <div class="container-full">
      <div class="row no-margin">
        <div class="col-lg-7 col-md-7 col-xs-12 col-sm-12 no-padding">
          <div class="row no-margin">
            <h1 class="title text-white">Pourquoi choisir {{name}} ?</h1>
            <p class="text-white description">Nous disposons d'un service garanti, fiable, securisé, simple d'utilisation et qui vous donne la possibilité de personnaliser votre expérience utilisateur</p>
          </div>
          <div class="row no-margin counter-area">
              <div class="counter--item col-6">
                  <div class="counter-thumb">
                      <img src="./assets/images/counter3.png" alt="icon">
                  </div>
                  <div class="counter-content">
                      <h2 class="title mb-0 mt-0"><span class="counter">17 501</span></h2>
                      <span>Clients</span>
                  </div>
              </div>
              <div class="counter--item col-6">
                  <div class="counter-thumb">
                      <img src="./assets/images/counter4.png" alt="icon">
                  </div>
                  <div class="counter-content">
                      <h2 class="title mb-0 mt-0"><span class="counter">11 987</span></h2>
                      <span>Assurances</span>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 no-padding">
          <div class="col-lg-5 d-none d-lg-block">
              <div class="counter-thumb-1">
                  <img src="./assets/images/phn1.png" alt="counter">
              </div>
          </div>
          <!-- <div class="img-section">
              <img alt="" src="assets/images/app_landing1/about/1-1.png" class="img-fluid mobile-screen">
          </div> -->
        </div>
        <!-- <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12 secondary-bg">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 text-choice pt-3">
              <h4 class="text-black mb-2"></h4>
              <p class="text-white">Nous disposons d'un service garanti, fiable, securisé, simple d'utilisation et aui vous donne la possibilité de personnalisez votre expérience utilisateur</p>
            </div>
            <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
              <div class="row no-margin">
                <div class="col-6 counters" *ngFor="let data of counter">
                    <div class="reason-choice text-center">
                        <div  >
                            <p class="no-margin"><img [src]="data.img" alt=""
                                 class="service-img img-fluid"></p>
                            <p class="text-white text-center">{{data.count}}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
</section>

<!-- about section end-->
