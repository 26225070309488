import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor() {}

  MENUITEMS: Menu[] = [
    { path: "/devis/automobile", title: "Assurance Automobile", type: "link" },
    { path: "/devis/mrh", title: "Assurance MRH", type: "link" },
    { path: "/devis/sante", title: "Assurance Sante", type: "link" },
    { path: "/devis/voyage", title: "Assurance Voyage", type: "link" },
    { path: "/devis/retraite", title: "Assurance Retraite", type: "link" },
    { path: "/devis/vie", title: "Assurance Vie", type: "link" },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
