<!-- subscribe section Start-->
<!-- [ngStyle]="{'background-image': 'url(assets/images/app_landing1/subscribe/subscribe.png)'}" -->
<footer class="app1 subscribe bg"  id="contact">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="title title1">
                    <div class="main-title">
                        <h2 class="title">Souscrire à notre newsletter</h2>
                    </div>
                    <div class="sub-title">
                        <p class="para">Pour être au courant de toutes nos nouvelles</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="d-flex">
                                <input type="email" class="form-control " maxlength="45"
                                       placeholder="Entrer votre email"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default btn-gradient text-white text-uppercase">Souscrire
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="socials-lists m-t-50">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="#"  ><i class="fa fa-facebook center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#"  ><i class="fa fa-twitter center-content"
                                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#"  ><i class="fa fa-google center-content"
                                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-instagram center-content" aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- subscribe section end-->
